import ProgressBar from "../components/Loaders/ProgressBar";
import services from "../store/services";
import Request from "./Request";
import VersioningApi from "./versioning-api";
import WorkflowApi from "./workflow-api";

const PATH = "/api/trapeze/extraction";

const saveDocDataToDb = async (batchName, docId, fields) => {
  const docType = fields.find((field) => field.name === "DocumentType");
  await WorkflowApi.modifyDocType(docId, docType);
  const versionField = fields.find((field) => field.name === "Version");
  if (versionField) {
    await VersioningApi.saveDoc(batchName, docType.name, docId, versionField);
  }
};

const ExtractionApi = {
  getBatch: async (batchName) => {
    return await WorkflowApi.getBatchFromStage(
      batchName,
      services.extraction.trapezeId
    );
  },
  getDocuments: async (batchName) => {
    return await Request.get(`${PATH}/batches/${batchName}/docs`);
  },
  getFields: async (batchName, docId) => {
    return await Request.get(
      `${PATH}/batches/${batchName}/docs/${docId}/fields`
    );
  },
  getTables: async (batchName, docId) => {
    return await Request.get(
      `${PATH}/batches/${batchName}/docs/${docId}/tables`
    );
  },
  reserveDoc: async (batchName, docId) => {
    return await Request.put(
      `${PATH}/batches/${batchName}/docs/${docId}/reserve`
    );
  },
  releaseDoc: async (batchName, docId) => {
    return await Request.put(
      `${PATH}/batches/${batchName}/docs/${docId}/release`
    );
  },
  getPages: async (batchName, docId) => {
    const pages = await Request.get(
      `${PATH}/batches/${batchName}/docs/${docId}/pages`
    );
    return pages;
  },
  saveDoc: async (batchName, docId, fields) => {
    await saveDocDataToDb(batchName, docId, fields);
    return await Request.put(
      `${PATH}/batches/${batchName}/docs/${docId}/fields?approve=false`,
      fields
    );
  },
  saveTables: async (batchName, docId, tables) => {
    return await Request.put(
      `${PATH}/batches/${batchName}/docs/${docId}/tables`,
      tables
    );
  },
  verifaiSaveDoc: async (loanId, doc) => {
    return await Request.put(`/api/loans/${loanId}/documents/${doc._id}`, doc);
  },
  approveDoc: async (batchName, docId, fields) => {
    await saveDocDataToDb(batchName, docId, fields);
    return await Request.put(
      `${PATH}/batches/${batchName}/docs/${docId}/fields`,
      fields
    );
  },
  getDocLayout: async (docTypeName) => {
    return await Request.get(`${PATH}/documentTypes/${docTypeName}`);
  },
  approveAll: async (batchName, loader) => {
    const docs = (await ExtractionApi.getDocuments(batchName)).filter((doc) => {
      return doc.status !== "approved" && doc.status !== "autoapproved";
    });
    for (let i = 0; i < docs.length; i++) {
      loader.open(<ProgressBar numerator={i} denominator={docs.length} />);
      try {
        await ExtractionApi.reserveDoc(batchName, docs[i].id);
        const fields = await ExtractionApi.getFields(batchName, docs[i].id);
        await ExtractionApi.approveDoc(batchName, docs[i].id, fields);
      } catch (error) {
        console.error(`${docs[i].docType} could not be approved.`);
        continue;
      }
    }
  },
  modifyDocType: async (batchName, docId, docType) => {
    return await Request.put(
      `${PATH}/batches/${batchName}/docs/${docId}/docType/${docType}`,
      {}
    );
  },
};

export default ExtractionApi;
