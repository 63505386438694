import { AppContext, ServiceContext } from "../../Contexts/Contexts";
import { useContext, useCallback, useEffect, useMemo } from "react";
import SaveIcon from "../../components/Icons/SaveIcon";
import VerifAiApi from "../../api/verifai-api";
import VersioningPanel from "./Panels/versioning.panel";
import ArrayUtils from "../../utils/array.utils";
import BrowserUtils from "../../utils/browser.utils";
import DomUtils from "../../utils/dom.utils";
import StringUtils from "../../utils/string.utils";

const VerifaiVersioningService = () => {
  const { alert, loader, showCheckmark } = useContext(AppContext);
  const { state, dispatch } = useContext(ServiceContext);
  const documentType = useMemo(() => state.item, [state.item]);
  const getSetDocTypes = useCallback(() => {
    const docTypes = ArrayUtils.groupedArray(
      state.loan.documents,
      "type",
      "documents"
    );
    docTypes.forEach((docType, idx) => {
      docType.explorer = {
        type: "folder",
        name: docType.name,
      };
      docType.index = idx;
      docType.documents.forEach((doc, idx) => {
        doc.explorer = {
          type: "document",
          hidden: false,
          name: `${StringUtils.indexToLetter(idx)} (${StringUtils.getShortHash(
            doc._id
          ).toUpperCase()})`,
          tag: DomUtils.getVersionTag(doc.version),
        };
        doc.pages.forEach(
          (page) =>
            (page.src = BrowserUtils.getPageUrl(state.loan.name, page.fileId))
        );
      });
    });
    return docTypes;
  }, [state.loan]);

  const onVersion = useCallback(
    (document, version) => {
      document.version = version;
      document.versionConfidence = 100;
      document.explorer.tag = DomUtils.getVersionTag(version);
      dispatch({ type: "setRepo", repo: [...state.repo] });
    },
    [dispatch, state.repo]
  );

  const onSave = useCallback(async () => {
    const documents = state.repo.flatMap((docType) => docType.documents);
    documents.forEach((doc) => {
      delete doc.explorer;
    });
    state.loan.documents = documents;
    try {
      loader.open();
      await VerifAiApi.putLoan(state.loan);
      const loan = await VerifAiApi.getLoans(state.loan._id);
      dispatch({ type: "setLoan", loan: loan });
    } catch (error) {
      alert.open(<div>{error.toString()}</div>);
    } finally {
      loader.close();
    }
    await showCheckmark();
  }, [state.repo, dispatch, state.loan, showCheckmark, alert, loader]);

  const hotkeys = useCallback(
    (e) => {
      if (!documentType || DomUtils.hasPopup()) return;
      if (e.ctrlKey) {
        switch (e.key) {
          case "s":
            onSave();
            break;
          default:
            break;
        }
      }
    },
    [onSave, documentType]
  );

  const Buttons = useMemo(
    () => [<SaveIcon size={50} className={"button pad-10"} onClick={onSave} />],
    [onSave]
  );

  useEffect(() => {
    const docType = getSetDocTypes();
    dispatch({ type: "setRepo", repo: docType });
  }, [getSetDocTypes, dispatch]);

  useEffect(() => {
    if (documentType) {
      dispatch({ type: "setButtons", buttons: Buttons });
    }
  }, [Buttons, dispatch, documentType]);

  useEffect(() => {
    document.addEventListener("keydown", hotkeys);
    return () => document.removeEventListener("keydown", hotkeys);
  }, [hotkeys]);

  return <VersioningPanel documentType={documentType} onVersion={onVersion} />;
};

export default VerifaiVersioningService;
