import { MenuItem, TextField } from "@mui/material";
import { useContext, useEffect, useMemo, useState, useCallback } from "react";
import { FileInput } from "../../../components/Inputs/FileInput";
import { AppContext } from "../../../Contexts/Contexts";
import UploadApi from "../../../api/upload-api";
import ErrorMessage from "../../../components/Alerts/ErrorMessage";
import TrapezeClient from "../../../api/trapeze.client";
import SubmitInput from "../../../components/Inputs/SubmitInput";
import { sleep } from "../../../utils/general.utils";

const GeneralInfo = ({
  batchName,
  setBatchName,
  priority,
  setPriority,
  errorMessage,
}) => {
  return (
    <div className="general-info-container">
      <label className="standard-label">General Information</label>
      <TextField
        size="small"
        label="Batch Name"
        value={batchName}
        onChange={(e) => setBatchName(e.target.value)}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        select
        size="small"
        label="Priority"
        value={priority}
        onChange={(e) => setPriority(e.target.value)}
      >
        <MenuItem value={"High"}>High</MenuItem>
        <MenuItem value={"Medium"}>Medium</MenuItem>
        <MenuItem value={"Low"}>Low</MenuItem>
      </TextField>
      <ErrorMessage message={errorMessage} sx={{ margin: "5px 0" }} />
    </div>
  );
};

const Metadata = ({ tags, setTags }) => {
  return (
    <>
      <div className="vertical-bar"></div>
      <div className="upload-right-container">
        <div className="standard-label" style={{ padding: "0" }}>
          Metadata
        </div>
        <div
          className="upload-metadata-container scrollbar"
          style={{
            overflowY: "scroll",
            height: "100%",
            padding: "5px 5px 0 0",
          }}
        >
          {tags.map((tag, idx) => {
            return (
              <TextField
                key={idx}
                size="small"
                label={tag.tagName}
                InputLabelProps={{ shrink: true }}
                value={tag.tagValue}
                onChange={(e) => {
                  tag.tagValue = e.target.value;
                  setTags([...tags]);
                }}
              ></TextField>
            );
          })}
        </div>
      </div>
    </>
  );
};

const Upload = ({ batches }) => {
  const { loader, showCheckmark, leftPopOut, alert } = useContext(AppContext);
  const [batchName, setBatchName] = useState("");
  const [priority, setPriority] = useState("Medium");
  const [files, setFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const [tags, setTags] = useState([]);

  // getSetters
  const getSetTags = useCallback(async () => {
    const tags = await TrapezeClient.getTags();
    tags.forEach((tag) => (tag.tagValue = ""));
    setTags(tags);
  }, []);

  // functions
  const addFiles = (newFiles) => {
    const fileList = [];
    for (const file of newFiles) {
      fileList.push(new File([file], file.name, { type: file.type }));
    }
    setFiles([...files, ...fileList]);
    document.getElementById("files").value = null;
  };

  const batchNames = useMemo(() => {
    const batchNames = new Set();
    for (const batch of batches) {
      batchNames.add(batch.name.toLowerCase());
    }
    return batchNames;
  }, [batches]);

  const removeFile = (oldFile) => {
    files.splice(files.indexOf(oldFile), 1);
    setFiles([...files]);
  };
  const upload = async (batchName, priority, files, tags) => {
    setErrorMessage(null);
    if (files.length === 0) {
      setErrorMessage("There are no files to upload.");
      return;
    }
    if (batchName === "") {
      setErrorMessage("Please enter a batch name.");
      return;
    }
    if (batchNames.has(batchName.toLowerCase())) {
      setErrorMessage("Batch Name already exists.");
      return;
    }
    leftPopOut.close();
    loader.open();
    try {
      await UploadApi.uploadBatch(batchName, priority, files, tags);
    } catch (error) {
      alert.open(<p>{`An error occurred: ${error.message}`}</p>);
      return;
    } finally {
      loader.close();
    }
    showCheckmark();
    await sleep(1);
  };

  // driver
  useEffect(() => {
    const driver = async () => {
      console.log("upload driver");
      await getSetTags();
    };
    driver();
  }, [getSetTags]);

  return (
    <div className="standard-content">
      <form
        method="post"
        encType="multipart/form-data"
        onSubmit={(e) => {
          e.preventDefault();
          upload(batchName, priority, files, tags);
        }}
        className="upload-form-container"
      >
        <div className="upload-main-container">
          <div className="upload-left-container">
            <GeneralInfo
              batchName={batchName}
              setBatchName={setBatchName}
              priority={priority}
              setPriority={setPriority}
              errorMessage={errorMessage}
            />
            <FileInput
              files={files}
              addFiles={addFiles}
              removeFile={removeFile}
            />
          </div>
          {tags.length > 0 && <Metadata tags={tags} setTags={setTags} />}
        </div>
        <div className="upload-file-button-container">
          <SubmitInput value="Upload Files" />
        </div>
      </form>
    </div>
  );
};

export default Upload;
