import TextField from "@mui/material/TextField";
import { useContext, useState } from "react";
import ClassificationConfigApi from "../../../../api/config-api/classification-config-api";
import Statuses from "../../../../api/statuses";
import ThresholdInput from "../../../../components/Inputs/ThresholdInput";
import { AppContext } from "../../../../Contexts/Contexts";

const ModifyClassificationRule = ({ currentRule, refresh, sx }) => {
  const { popup, alert } = useContext(AppContext);
  const [threshold, setThreshold] = useState(currentRule[0].threshold);
  const deleteDoc = false;
  const documentTypeName = currentRule[0].documentTypeName;

  //Sub-components
  const DocumentTypeField = () => {
    return (
      <div className="text-field" style={{ padding: "20px 10px 0px 10px" }}>
        <TextField
          sx={{
            width: "100%",
            WebkitTextFillColor: "black",
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "black",
              padding: "10px",
            },
          }}
          label="Document Type"
          value={documentTypeName}
          disabled={true}
          InputLabelProps={{ shrink: true }}
        />
      </div>
    );
  };

  const ThresholdInputField = () => {
    return (
      <ThresholdInput
        style={{ margin: "auto" }}
        threshold={threshold}
        setThreshold={setThreshold}
      />
    );
  };
  const SaveRuleButton = () => {
    return (
      <button
        className="pill-button"
        onClick={async (e) => {
          e.preventDefault();
          await ClassificationConfigApi.modifyClassificationRule(
            documentTypeName,
            {
              threshold: threshold,
              deleteDoc: deleteDoc,
            }
          );
          popup.close();
          refresh();
        }}
      >
        Save
      </button>
    );
  };

  const DeleteRuleButton = () => {
    return (
      <button
        className="pill-button"
        onClick={async (e) => {
          e.preventDefault();
          const res = await ClassificationConfigApi.deleteClassificationRule(
            documentTypeName
          );
          if (res.status === Statuses.methodNotAllowed) {
            popup.close();
            alert.open(
              <p>The Default Classification Rule cannot be deleted.</p>
            );
            return;
          }
          popup.close();
          refresh();
        }}
      >
        Delete Rule
      </button>
    );
  };

  return (
    <div className="popup-content" style={sx}>
      {DocumentTypeField()}
      {ThresholdInputField()}
      <div className="buttons-container">
        {SaveRuleButton()}
        {DeleteRuleButton()}
      </div>
    </div>
  );
};

export default ModifyClassificationRule;
