import {
  DataGridPro,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  gridClasses,
} from "@mui/x-data-grid-pro";
import { useEffect, useMemo, useState } from "react";

import BrowserUtils from "../../utils/browser.utils";
import SearchIcon from "../../components/Icons/SearchIcon";
import StringUtils from "../../utils/string.utils";
import DateTimeUtils from "../../utils/datetime.utils";
import CircleCloseIcon from "../Icons/CircleCloseIcon";

const initialPaginationModel = {
  pageSize: 50,
  page: 0,
};

const StandardDataGrid = ({
  apiRef,
  className,
  name,
  rows,
  columns: _columns,
  initialState,
  hideSearchToolbar,
  hideColumnsToolbar,
  onContextMenuOpen,
  processRowUpdate,
  setRows,
}) => {
  const gridSx = {
    ".MuiDataGrid-columnHeaders, .MuiDataGrid-pinnedColumnHeaders": {
      backgroundColor: "var(--true-black)",
      color: "white",
      fontFamily: "var(--font-family-sans-serif)",
      borderRadius: "0px",
    },
    ".MuiDataGrid-pinnedColumns": {
      boxShadow: "none",
    },
    [`.MuiDataGrid-sortIcon, .MuiDataGrid-menuIconButton, .${gridClasses.filterIcon}`]:
      {
        color: "var(--true-green)",
      },
    ".MuiDataGrid-cell": {
      fontFamily: "var(--font-family-serif)",
    },
    ".MuiDataGrid-row.Mui-selected.Mui-hovered": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    [`.${gridClasses.cell}:focus, .${gridClasses.cell}:focus-within, .${gridClasses.columnHeader}:focus, .${gridClasses.columnHeader}:focus-within`]:
      {
        outline: "none",
      },
  };
  const [columnVisibility, setColumnVisibility] = useState({});
  const [filters, setFilters] = useState({ items: [] });
  const [paginationModel, setPaginationModel] = useState(
    initialPaginationModel
  );
  const [sortModel, setSortModel] = useState(
    BrowserUtils.localStorage.get(`${name}-sortModel`) ??
      initialState?.sorting?.sortModel ??
      []
  );
  const [columnOrder, setColumnOrder] = useState(
    BrowserUtils.localStorage.get(`${name}-columnOrder`) ??
      _columns.map((col) => col.field)
  );

  const handleSortModelChange = (newSortModel) => {
    const _sortModel =
      sortModel.length === 1 && newSortModel.length === 0
        ? [
            {
              field: sortModel[0].field,
              sort: sortModel[0].sort === "asc" ? "desc" : "asc",
            },
          ]
        : newSortModel;
    setSortModel(_sortModel);
    BrowserUtils.localStorage.set(`${name}-sortModel`, _sortModel);
  };

  const handleRowOrderChange = (params) => {
    const { oldIndex, targetIndex } = params;
    const reorderedList = [...rows];
    const itemToMove = reorderedList.splice(oldIndex, 1)[0];
    reorderedList.splice(targetIndex, 0, itemToMove);
    setRows(reorderedList);
  };

  const columns = useMemo(() => {
    const columns = _columns.map((col, idx) => {
      return {
        id: idx,
        maxWidth: 500,
        align: "left",
        headerAlign: "left",
        // renderHeader: () => {
        //   if (!col.editable) return;
        //   return (
        //     <div>
        //       {`${col.headerName} `}
        //       <EditIcon
        //         className="margin-left-5"
        //         size={17}
        //         title="Editable Rows"
        //       />
        //     </div>
        //   );
        // },
        valueGetter: (params) => {
          const value = params.row[params.field];
          switch (col.type) {
            case "date":
              return DateTimeUtils.toUtc(value);
            case "number":
            case "usd":
              return Number(value);
            default:
              return value;
          }
        },
        valueFormatter: (cell) => {
          const { value } = cell;
          switch (col.type) {
            case "date":
              return DateTimeUtils.format(value);
            case "number":
              return Number(value);
            case "usd":
              return StringUtils.formatUsd(value);
            default:
              return value;
          }
        },
        ...col,
      };
    });
    const res =
      columnOrder?.map((field) => columns.find((col) => col.field === field)) ??
      columns;
    return res.filter((col) => !!col);
  }, [_columns, columnOrder]);

  // Clicks
  const onColumnOrderChange = ({ column, oldIndex, targetIndex }) => {
    const rowReorderSubtract = !!setRows ? 1 : 0;
    const newColumnOrder = [...columnOrder];
    newColumnOrder.splice(oldIndex - rowReorderSubtract, 1);
    newColumnOrder.splice(targetIndex - rowReorderSubtract, 0, column.field);
    setColumnOrder(newColumnOrder);
    BrowserUtils.localStorage.set(`${name}-columnOrder`, newColumnOrder);
  };

  const onColumnVisibilityModelChange = (newModel) => {
    setColumnVisibility(newModel);
    BrowserUtils.localStorage.set(`${name}-columnVisibility`, newModel);
  };

  const onFilterModelChange = (newFilterModel) => {
    setFilters(newFilterModel);
    BrowserUtils.localStorage.set(`${name}-filters`, newFilterModel);
  };

  const ClearFiltersToolbarButton = () => {
    const onClearFiltersClick = () => {
      // onColumnVisibilityModelChange({});
      onFilterModelChange({ items: [] });
    };
    return (
      <button className="toolbar-button" onClick={onClearFiltersClick}>
        <div className="flex align-center gap-5">
          <CircleCloseIcon size={18} />
          <div>Clear Filters</div>
        </div>
      </button>
    );
  };

  useEffect(() => {
    const driver = () => {
      const columnVisibility = BrowserUtils.localStorage.get(
        `${name}-columnVisibility`
      );
      const filters = BrowserUtils.localStorage.get(`${name}-filters`);
      if (columnVisibility) {
        setColumnVisibility(columnVisibility);
      } else {
        const columnVisibilityModel = _columns
          .filter((column) => column.hidden)
          .reduce((model, col) => {
            model[col.field] = false;
            return model;
          }, {});
        setColumnVisibility(columnVisibilityModel);
      }
      if (filters) setFilters(filters);
    };
    driver();
  }, [name, _columns]);

  const Toolbar = () => {
    const sx = {
      color: "var(--true-green)",
      fontFamily: "var(--font-family-sans-serif)",
    };
    return (
      <GridToolbarContainer sx={{ backgroundColor: "black" }}>
        {!hideSearchToolbar && (
          <GridToolbarFilterButton
            sx={sx}
            componentsProps={{
              button: {
                startIcon: <SearchIcon size={20} />,
              },
            }}
          />
        )}
        {!hideColumnsToolbar && <GridToolbarColumnsButton sx={sx} />}
        {!hideSearchToolbar && <ClearFiltersToolbarButton />}
      </GridToolbarContainer>
    );
  };

  return (
    <DataGridPro
      apiRef={apiRef}
      autosizeOnMount
      autosizeOptions={{
        columns: columns.map((col) => col.field),
        includeHeaders: true,
        expand: true,
      }}
      className={className}
      columns={columns}
      columnVisibilityModel={columnVisibility}
      density="compact"
      disableColumnMenu
      filterModel={filters}
      hideFooterSelectedRowCount
      initialState={initialState}
      onColumnOrderChange={onColumnOrderChange}
      onColumnVisibilityModelChange={onColumnVisibilityModelChange}
      onFilterModelChange={onFilterModelChange}
      onPaginationModelChange={setPaginationModel}
      onRowOrderChange={handleRowOrderChange}
      pagination
      paginationModel={paginationModel}
      processRowUpdate={processRowUpdate}
      rowReordering={!!setRows}
      rows={rows}
      sortModel={sortModel}
      sx={gridSx}
      onSortModelChange={(model) => {
        handleSortModelChange(model);
        setPaginationModel({ ...paginationModel, page: 0 });
      }}
      slots={{
        toolbar: Toolbar,
      }}
      slotProps={{
        row: {
          onContextMenu: onContextMenuOpen,
          style: { cursor: "context-menu" },
        },
      }}
      localeText={{
        toolbarFilters: "search",
      }}
    />
  );
};

StandardDataGrid.defaultProps = {
  className: "",
  name: "",
  rowData: [],
  hideSearchToolbar: false,
  hideColumnsToolbar: false,
};

export default StandardDataGrid;
