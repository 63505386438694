import TrueLogo from "../Icons/TrueLogo";

const VersionInfo = ({ version, manager, api }) => {
  return (
    <div className="flex-col align-center gap-20 pad-10">
      <TrueLogo size={200} color={"black"} />
      <div className="border-top-green pad-10 flex-col left gap-30">
        <div>
          <div className="bold font-24">TRUE Data Intelligence</div>
          <div className="bold center-text">
            Version {version.substring(0, version.lastIndexOf("."))}
          </div>
        </div>
        <div className="flex gap-30 serif">
          <div>
            <div>Frontend version:</div>
            <div>API version:</div>
            <div>Manager Service version:</div>
          </div>
          <div>
            <div>{version}</div>
            <div>{api}</div>
            <div>{manager}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VersionInfo;
