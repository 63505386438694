import { FiSettings } from "react-icons/fi";
import { CgProfile } from "react-icons/cg";
import { useState, useContext, useMemo } from "react";
import { AppContext } from "../../Contexts/Contexts";
import ProfilePopup from "./components/ProfilePopup";
import trueLogo from "../../img/true/True-Logo-White-Copyright.svg";
import { NavLink } from "react-router-dom";
import IqLogo from "../../img/mgic/IqLogo";
// import { AiOutlineTable } from "react-icons/ai";
import ReportIcon from "../Icons/ReportIcon";
import GenerateUsageReport from "../Reports/GenerateUsageReport";
import BrowserUtils from "../../utils/browser.utils";
import DomUtils from "../../utils/dom.utils";
import { useLocation } from "react-router-dom";

const ICON_SIZE = 22;

const NavBar = () => {
  const { user, appSettings, leftPopOut } = useContext(AppContext);
  const [showProfile, setShowProfile] = useState(false);
  const location = useLocation();
  const params = useMemo(
    () => BrowserUtils.getServiceParams(location.search),
    [location.search]
  );

  // onClicks

  const onReport = () => {
    leftPopOut.open(
      DomUtils.getOrangeBlackHeader("Generate", "Usage Report"),
      <GenerateUsageReport />
    );
  };

  // sub-components
  const Logo = () => {
    switch (appSettings.theme?.toLowerCase()) {
      case "mgic":
        return <IqLogo width="45" height="30.05" />;
      default:
        return <img src={trueLogo} alt="TRUE Logo" width="100px" />;
    }
  };

  return (
    <>
      <nav className="bg-black flex space-between pad-5-10 align-center">
        <NavLink
          className={params.isStandalone ? "disabled-link" : ""}
          to="/"
          style={{ width: "fit-content" }}
        >
          {Logo()}
        </NavLink>
        <div className="flex gap-10 font-14 align-center">
          <NavLink
            hidden={!user?.IsSystemAdministrator}
            to="/admin"
            style={{ height: `${ICON_SIZE}px` }}
          >
            <FiSettings
              size={ICON_SIZE}
              color="white"
              title="Admin Settings"
              className="button"
            />
          </NavLink>
          {user?.IsAdministrator && (
            <ReportIcon
              size={ICON_SIZE}
              onClick={onReport}
              title="Report Tools"
              className={"button"}
            />
          )}
          {/* {appSettings.useVerifai && (
            <NavLink style={{ height: `${ICON_SIZE}px` }} to="/loans">
              <AiOutlineTable
                size={ICON_SIZE}
                color="white"
                title="Loan Table"
              />
            </NavLink>
          )} */}
          <div
            onMouseEnter={() => setShowProfile(true)}
            onMouseLeave={() => setShowProfile(false)}
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <ProfilePopup hide={!showProfile} />
            <CgProfile
              id="profile-icon"
              size={ICON_SIZE}
              style={{ cursor: "pointer", zIndex: "2" }}
              color="white"
              title="Profile"
            />
          </div>
          <div className="nav-username">{user?.Username}</div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
