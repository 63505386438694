import blackIcon from "../../img/true/TRU-Icon-Black-Flat.svg";
import whiteIcon from "../../img/true/TRU-Icon-White-Flat.svg";

const TrueIcon = ({ color, size }) => {
  const src = color === "black" ? blackIcon : whiteIcon;
  return (
    <img src={src} alt="TRUE Icon" width={`${size}px`} title="TRUE Icon" />
  );
};

TrueIcon.defaultProps = {
  color: "black",
  size: 20,
};

export default TrueIcon;
