import trueBlackLogo from "../../img/true/True-Logo-Black-Flat-Copyright.svg";
import trueWhiteLogo from "../../img/true/True-Logo-White-Copyright.svg";

const TrueLogo = ({ size, color }) => {
  const logo = color === "white" ? trueWhiteLogo : trueBlackLogo;
  return <img src={logo} alt="TRUE Logo" width={`${size}px`} />;
};

TrueLogo.defaultProps = {
  size: 30,
  color: "white",
};

export default TrueLogo;
