import { AppContext, ServiceContext } from "../../Contexts/Contexts";
import ExtractionUtils from "../../utils/extraction.utils";
import {
  useContext,
  useMemo,
  useState,
  useRef,
  useCallback,
  useEffect,
} from "react";
import ExtractionApi from "../../api/extraction-api";
import ApproveIcon from "../../components/Icons/ApproveIcon";
import VersioningIcon from "../../components/Icons/VersioningIcon";
import { useNavigate, useLocation } from "react-router-dom";
import SaveIcon from "../../components/Icons/SaveIcon";
import XCloseIcon from "../../components/Icons/XCloseIcon";
import ApproveAllIcon from "../../components/Icons/ApproveAllIcon";
import ExtractionPanel from "./Panels/extraction.panel";
import BrowserUtils from "../../utils/browser.utils";
import DomUtils from "../../utils/dom.utils";
import ArrayUtils from "../../utils/array.utils";
import { copy, sleep } from "../../utils/general.utils";
import IdUtils from "../../utils/id.utils";
import { useQuery } from "@tanstack/react-query";

const defaultVersions = new Set(["final", "unversioned", "manual"]);
const approvedStatuses = new Set(["approved", "autoapproved"]);
const tempFieldProps = new Set(["config", "ref", "idx"]);

const ExtractionService = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useMemo(
    () => BrowserUtils.getServiceParams(location.search),
    [location.search]
  );
  const { showCheckmark, alert, confirm, loader, blocker, onUnload, user } =
    useContext(AppContext);
  const { state, dispatch } = useContext(ServiceContext);
  const [documents, setDocuments] = useState([]);
  const [currentDocument, setCurrentDocument] = useState(null);
  const [currentField, setCurrentField] = useState(null);
  const rowRef = useRef();
  const [showAllVersions, setShowAllVersions] = useState(false);
  const [showApproved, setShowApproved] = useState(false);
  const [preParsedTables, setPreParsedTables] = useState(null);

  // GetSetters
  const getSetDocuments = useCallback(async () => {
    try {
      loader.open(
        <div className="bg-white pad-5 br-5">Getting Documents...</div>
      );
      const documents = await ExtractionApi.getDocuments(state.loan.name);
      documents.sort((a, b) => a.docType.localeCompare(b.docType));
      documents.forEach((doc, idx) => {
        doc.index = idx;
        doc.type = doc.docType;
        doc.version = doc.versionStatus;
        doc.loanName = state.loan.name;
        doc.explorer = {
          type: "document",
          name: `${doc.type} (${doc.id})`,
          tag: (
            <div className="flex gap-5 align-center">
              {DomUtils.getLockTag(doc.assignedTo)}
              {DomUtils.getApprovedTag(doc.status)}
              {DomUtils.getVersionTag(doc.version)}
            </div>
          ),
        };
        delete doc.docType;
        delete doc.versionStatus;
      });
      setDocuments(documents);
      return documents;
    } catch (err) {
      console.error(err);
      alert.open(<div>{err.toString()}</div>);
    } finally {
      loader.close();
    }
  }, [state.loan, loader, alert]);

  // actions
  const close = useCallback(
    async (doc, options = { noRelease: false }) => {
      setCurrentDocument(null);
      setCurrentField(null);
      onUnload.clear();
      dispatch({ type: "setItem", item: null });
      if (options.noRelease) return;
      try {
        loader.open(
          <div className="pad-10 bg-white br-5">Releasing Document...</div>
        );
        await ExtractionApi.releaseDoc(doc.loanName, doc.id);
      } catch (err) {
        console.error(err);
        alert.open(<div>{err.toString()}</div>);
        return;
      } finally {
        loader.close();
      }
    },
    [dispatch, alert, loader, onUnload]
  );

  const approveField = useCallback(
    (value, originalField) =>
      setCurrentDocument((doc) => {
        doc.rows.forEach((row) => {
          if (row.type !== "fields") return;
          row.fields.forEach((field) => {
            if (field.name !== originalField.name) return;
            field.data = value;
            field.conf = 201;
          });
        });
        return { ...doc };
      }),
    []
  );

  const approveCell = useCallback(
    (tableName, updatedRow) =>
      setCurrentDocument((doc) => {
        doc.rows.forEach((row) => {
          if (row.type !== "tables" || row.tables[0].name !== tableName) return;
          row.tables[0].rows.forEach((row) => {
            if (row.id === currentField.rowId) {
              row[currentField.columnName].data =
                updatedRow[currentField.columnName];
              row[currentField.columnName].conf = 201;
              return copy(row);
            }
          });
        });
        return { ...doc };
      }),
    [currentField]
  );

  const modifyDocType = useCallback(
    async (documentType) => {
      try {
        loader.open(
          <div className="bg-white br-5 pad-10">
            Changing the Document Type...
          </div>
        );
        await ExtractionApi.modifyDocType(
          currentDocument.loanName,
          currentDocument.id,
          documentType
        );
      } catch (err) {
        console.error(err);
        alert.open(<div>{err.toString()}</div>);
      } finally {
        loader.close();
      }
    },
    [currentDocument, loader, alert]
  );

  const save = useCallback(
    async (doc) => {
      loader.open(<div className="pad-10 bg-white br-5">Saving...</div>);
      try {
        const fields = ExtractionUtils.getFlattenedFields(doc.rows).map(
          (_field) => {
            const field = {};
            Object.keys(_field).forEach((key) => {
              if (tempFieldProps.has(key)) return;
              field[key] = _field[key];
            });
            return field;
          }
        );
        const tables = ExtractionUtils.getTables(doc.rows);
        tables.forEach((table) => {
          const rows = table.rows.map((tableRow) => {
            const row = Object.keys(tableRow)
              .filter((column) => column !== "id" && column !== "position")
              .map((column) => tableRow[column]);
            return { position: tableRow.position, rowColumns: row };
          });
          const preParsedTable = preParsedTables.find(
            (t) => t.tableName === table.name
          );
          preParsedTable.rows = rows;
        });
        await ExtractionApi.saveTables(doc.loanName, doc.id, preParsedTables);
        await ExtractionApi.saveDoc(doc.loanName, doc.id, fields);
      } catch (err) {
        console.error(err);
        alert.open(<div>{err.toString()}</div>);
      } finally {
        loader.close();
      }
      await showCheckmark();
    },
    [showCheckmark, preParsedTables, alert, loader]
  );

  const approve = useCallback(
    async (doc) => {
      loader.open();
      try {
        const fields = ExtractionUtils.getFlattenedFields(doc.rows).map(
          (_field) => {
            const field = {};
            Object.keys(_field).forEach((key) => {
              if (tempFieldProps.has(key)) return;
              field[key] = _field[key];
            });
            return field;
          }
        );
        const tables = ExtractionUtils.getTables(doc.rows);
        tables.forEach((table) => {
          const rows = table.rows.map((tableRow) => {
            const row = Object.keys(tableRow)
              .filter((column) => column !== "id" && column !== "position")
              .map((column) => tableRow[column]);
            return { position: tableRow.position, rowColumns: row };
          });
          const preParsedTable = preParsedTables.find(
            (t) => t.tableName === table.name
          );
          preParsedTable.rows = rows;
        });
        await ExtractionApi.saveTables(doc.loanName, doc.id, preParsedTables);
        await ExtractionApi.approveDoc(doc.loanName, doc.id, fields);
        setCurrentField(null);
        setCurrentDocument(null);
        onUnload.clear();
        dispatch({ type: "setItem", item: null });
        await getSetDocuments();
        const docsRemaining =
          (await ExtractionApi.getDocuments(doc.loanName)).filter(
            (document) => !approvedStatuses.has(document.status)
          ).length > 0;
        if (!docsRemaining) {
          params.isStandalone
            ? blocker.open(
                <div>
                  This batch has been approved. You can now close this window.
                </div>
              )
            : alert.open(
                <div>No documents remaining to be reviewed.</div>,
                () => {
                  navigate(params.dashboardPath);
                }
              );
        }
      } catch (err) {
        console.error(err);
        alert.open(<div>{err.toString()}</div>);
        return;
      } finally {
        loader.close();
      }
      dispatch({ type: "setItem", item: null });
      await showCheckmark();
    },
    [
      blocker,
      alert,
      navigate,
      showCheckmark,
      preParsedTables,
      loader,
      params,
      dispatch,
      getSetDocuments,
      onUnload,
    ]
  );

  const approveAll = useCallback(async () => {
    await ExtractionApi.approveAll(state.loan.name, loader);
    loader.close();
    await showCheckmark();
    if (params.isStandalone) {
      blocker.open(
        <div>This batch has been approved. You can now close this window.</div>
      );
    } else {
      navigate(params.dashboardPath);
    }
  }, [state.loan, loader, showCheckmark, params, blocker, navigate]);

  const documentSelected = useCallback(
    async (document) => {
      document.rows = [];
      setCurrentDocument(document);
      const className = "bg-white pad-10 br-5";
      try {
        loader.open(<div className={className}>Reserving Document...</div>);
        await ExtractionApi.reserveDoc(document.loanName, document.id);
        onUnload.set(() => close(document), location.pathname);
        const documents = await getSetDocuments();
        const item = documents.find((doc) => doc.id === document.id);
        dispatch({ type: "setItem", item: item });
        loader.open(
          <div className={className}>Getting Document Layout...</div>
        );
        const layout = await ExtractionApi.getDocLayout(document.type);
        loader.open(<div className={className}>Getting Fields...</div>);
        const fields = await ExtractionApi.getFields(
          document.loanName,
          document.id
        );
        loader.open(<div className={className}>Getting Tables...</div>);
        const preParsedTables = await ExtractionApi.getTables(
          document.loanName,
          document.id
        );
        setPreParsedTables(copy(preParsedTables));
        const tables = preParsedTables.map((table) => {
          return {
            name: table.tableName,
            columnNames: table.columnNames,
            rows: table.rows.map((row) => {
              row.rowColumns.forEach(
                (cell) => (cell.threshold = layout.doc.lowThresh)
              );
              return {
                id: IdUtils.generateUuid(),
                position: row.position,
                ...ArrayUtils.createIndex(row.rowColumns, "columnName"),
              };
            }),
          };
        });
        loader.open(<div className={className}>Getting Pages...</div>);
        document.pages = await ExtractionApi.getPages(
          document.loanName,
          document.id
        );
        document.pages.forEach(
          (page) =>
            (page.src = BrowserUtils.getPageUrl(document.loanName, page.pageId))
        );
        document.rows = layout.doc.rows;
        const indexedFields = ArrayUtils.createIndex(fields, "name");
        document.rows.forEach((row, rowIdx) => {
          switch (row.type) {
            case "fields":
              const fields = row.fields.map((fc, colIdx) => {
                const field = indexedFields[fc.name];
                if (!field) {
                  console.warn(`Field: "${fc.name}" not found.`);
                  return null;
                }
                if (!fc.useThresh) fc.threshold = layout.doc.lowThresh;
                return {
                  ...field,
                  config: fc,
                  idx: {
                    layoutRow: rowIdx,
                    layoutCol: colIdx,
                  },
                };
              });
              row.fields = fields.filter((field) => field !== null);
              return;
            case "tables":
              const colIdx = 0;
              const tc = row.tables[colIdx]; //table config (tc)
              tc.threshold = layout.doc.lowThresh;

              const table = tables.find((table) => table.name === tc.name);

              const configColumns = tc.columns.map((col) => col.name);
              if (
                !ArrayUtils.haveSameValues(configColumns, table.columnNames)
              ) {
                console.error(
                  `Configuration Error: Missing Column in Document Layout for ${document.type} on Table: ${table.name}`
                );
              }

              row.tables[colIdx] = {
                ...table,
                config: tc,
                idx: {
                  layoutRow: rowIdx,
                  layoutCol: colIdx,
                },
              };
              return;
            default:
              return;
          }
        });
        setCurrentDocument(document);
      } catch (err) {
        console.error(err);
        alert.open(<div>{err.toString()}</div>);
      } finally {
        loader.close();
      }
    },
    [
      alert,
      loader,
      getSetDocuments,
      location.pathname,
      onUnload,
      close,
      dispatch,
    ]
  );

  const isHidden = useCallback(
    (doc) => {
      return (
        (!showApproved && approvedStatuses.has(doc.status)) ||
        (!showAllVersions && !defaultVersions.has(doc.version.toLowerCase()))
      );
    },
    [showApproved, showAllVersions]
  );

  // Clicks
  const onFocus = useCallback((field) => {
    setCurrentField(field);
  }, []);

  const onFieldChange = useCallback(
    async (value, originalField) => {
      if (originalField.name === "DocumentType") {
        if (!value) return;
        // On change fires before enter does. This ensures that the confirm popup will open before it is approved.
        await sleep(0.1);
        confirm.open(
          <div>
            Are you sure you would like to switch the document type? <br />
            This will clear all field information.
          </div>,
          async () => {
            await modifyDocType(value);
            await close(currentDocument);
            const documents = await getSetDocuments();
            const updatedDoc = documents.find(
              (doc) => doc.id === currentDocument.id
            );
            // dispatch({ type: "setItem", item: item });
            await documentSelected(updatedDoc);
          }
        );
      } else {
        approveField(value, originalField);
      }
    },
    [
      approveField,
      modifyDocType,
      confirm,
      close,
      currentDocument,
      documentSelected,
      getSetDocuments,
    ]
  );

  const onClose = useCallback(async () => {
    confirm.open(
      <p>
        Are you sure you want to close this document?
        <br /> Unsaved Changes will be lost.
      </p>,
      async () => {
        loader.open();
        try {
          await close(currentDocument);
          await getSetDocuments();
        } catch (error) {
          console.error(error);
          alert.open(<div>{error.toString()}</div>);
          return;
        } finally {
          loader.close();
        }
      }
    );
  }, [confirm, loader, close, alert, currentDocument, getSetDocuments]);

  const onSave = useCallback(async () => {
    await save(currentDocument);
  }, [save, currentDocument]);

  const onApprove = useCallback(async () => {
    confirm.open(
      <div>
        Are you sure you want to approve this document?
        <br /> This action cannot be undone.
      </div>,
      async () => {
        await approve(currentDocument);
      }
    );
  }, [confirm, approve, currentDocument]);

  const onApproveAll = useCallback(async () => {
    confirm.open(
      <div>
        Are you sure you want to approve all documents?
        <br /> This action cannot be undone.
      </div>,
      async () => await approveAll()
    );
  }, [approveAll, confirm]);

  const onDocumentSelect = useCallback(async () => {
    if (currentDocument) {
      await close(currentDocument);
    }
    await documentSelected(state.item);
  }, [state.item, currentDocument, documentSelected, close]);

  const onResize = useCallback(() => {
    if (rowRef.current) {
      rowRef.current.resetAfterIndex(0, true);
    }
  }, [rowRef]);

  const onTableRowUpdate = useCallback(() => {
    getSetDocuments();
    onResize();
  }, [onResize, getSetDocuments]);

  const explorerFilters = useMemo(() => {
    const approveBg = showApproved ? "bg-black" : "";
    const versionBg = showAllVersions ? "bg-black" : "";
    return {
      isHidden,
      buttons: [
        <ApproveIcon
          size={20}
          className={`button br-5 ${approveBg}`}
          title="Show Approved"
          onClick={() => setShowApproved((toggle) => !toggle)}
        />,
        <VersioningIcon
          size={20}
          className={`button br-5 ${versionBg}`}
          title="Show All Versions"
          onClick={() => setShowAllVersions((toggle) => !toggle)}
        />,
      ],
    };
  }, [showAllVersions, showApproved, isHidden]);

  const Buttons = useMemo(() => {
    const size = 50;
    if (!currentDocument)
      return [
        <ApproveAllIcon
          size={size}
          className={"button pad-10 bg-orange"}
          onClick={onApproveAll}
        />,
      ];
    return [
      <SaveIcon size={size} className={"button pad-10"} onClick={onSave} />,
      <ApproveIcon
        size={size}
        className={"button pad-10 bg-orange"}
        onClick={onApprove}
      />,
      <XCloseIcon size={size} className={"button pad-10"} onClick={onClose} />,
    ];
  }, [onSave, onApprove, onClose, currentDocument, onApproveAll]);

  // hotkeys
  const hotkeys = useCallback(
    async (e) => {
      if (!currentDocument || DomUtils.hasPopup()) return;
      if (e.ctrlKey) {
        switch (e.key) {
          case "s":
            onSave();
            break;
          case "Enter":
            onApprove();
            break;
          default:
            break;
        }
      } else {
        switch (e.key) {
          case "Enter":
          case "Tab":
            e.preventDefault();
            if (e.key === "Enter") {
              if (currentField.name === "DocumentType") return;
              approveField(currentField.data, currentField);
            }
            let lowConfSources = ExtractionUtils.getLowConfSources(
              currentDocument.rows
            ).filter(ExtractionUtils.getVisible);
            if (lowConfSources.length === 0) {
              alert.open(<div>No Fields to Review</div>);
              return;
            }

            const getSource = (direction) => {
              const currentLayoutRowIdx = currentField?.idx.layoutRow ?? -1;
              const currentLayoutColIdx = currentField?.idx.layoutCol ?? -1;
              const currentTableRowIdx = currentField?.idx.tableRow ?? -1;
              const currentTableColIdx = currentField?.idx.tableCol ?? -1;

              const isNextValid = (source) => {
                if (
                  source.idx.layoutRow === currentLayoutRowIdx &&
                  source.idx.layoutCol === currentLayoutColIdx &&
                  source.type === "table"
                ) {
                  return (
                    (source.idx.tableRow === currentTableRowIdx &&
                      source.idx.tableCol > currentTableColIdx) ||
                    source.idx.tableRow > currentTableRowIdx
                  );
                } else {
                  return (
                    (source.idx.layoutRow === currentLayoutRowIdx &&
                      source.idx.layoutCol > currentLayoutColIdx) ||
                    source.idx.layoutRow > currentLayoutRowIdx
                  );
                }
              };

              const isPrevValid = (source) => {
                if (
                  source.idx.layoutRow === currentLayoutRowIdx &&
                  source.idx.layoutCol === currentLayoutColIdx &&
                  source.type === "table"
                ) {
                  return (
                    (source.idx.tableRow === currentTableRowIdx &&
                      source.idx.tableCol < currentTableColIdx) ||
                    source.idx.tableRow < currentTableRowIdx
                  );
                } else {
                  return (
                    (source.idx.layoutRow === currentLayoutRowIdx &&
                      source.idx.layoutCol < currentLayoutColIdx) ||
                    source.idx.layoutRow < currentLayoutRowIdx
                  );
                }
              };

              if (direction === "next") {
                return lowConfSources.find(isNextValid) || lowConfSources[0];
              } else {
                // "prev"
                for (let i = lowConfSources.length - 1; i >= 0; i--) {
                  if (isPrevValid(lowConfSources[i])) return lowConfSources[i];
                }
                return lowConfSources[lowConfSources.length - 1];
              }
            };

            const direction = e.shiftKey ? "prev" : "next";
            let source = getSource(direction);
            rowRef.current.scrollToItem(source.idx.layoutRow, "center");
            await sleep(0.1); // time for scrolled items to render

            // Update lowConfSources and source to allow for useRefs to set
            const sourceIdx = lowConfSources.indexOf(source);
            lowConfSources = ExtractionUtils.getLowConfSources(
              currentDocument.rows
            ).filter(ExtractionUtils.getVisible);
            source = lowConfSources[sourceIdx];

            if (source.type === "field") {
              await source.ref.focus();
            } else {
              onFocus(source);
              await source.ref.current.startCellEditMode({
                id: source.rowId,
                field: source.columnName,
              });
            }
            break;
          default:
            break;
        }
      }
    },
    [
      currentDocument,
      onSave,
      onApprove,
      onFocus,
      alert,
      currentField,
      approveField,
      rowRef,
    ]
  );

  useQuery({
    queryKey: ["getDocuments"],
    queryFn: getSetDocuments,
    enabled: !!state.loan,
    refetchOnWindowFocus: false,
  });

  const { data: assignedTo } = useQuery({
    queryKey: ["getDocAssignedTo", state.loan?.name, currentDocument?.id],
    queryFn: async () => {
      await sleep(0.5); // if admin takes over, ensure release completes before this check
      const docs = await ExtractionApi.getDocuments(state.loan.name);
      return docs.find((d) => d.id === currentDocument.id).assignedTo;
    },
    refetchInterval: 60000,
    enabled: !!currentDocument,
  });

  useEffect(() => {
    if (!state.item || currentDocument?.id === state.item.id) return;
    const itemDriver = async () => {
      console.log("itemDriver");
      await onDocumentSelect();
    };
    itemDriver();
  }, [state.item, onDocumentSelect, currentDocument]);

  useEffect(() => {
    const asyncFunction = async () => {
      if (!currentDocument || !assignedTo || !user) return;
      if (assignedTo !== "unassigned" && assignedTo !== user.Username) {
        await close(currentDocument, { noRelease: true });
        await getSetDocuments();
        const msg = <div>The document has been reserved by another user.</div>;
        if (params.isStandalone) {
          blocker.open(msg);
        } else {
          alert.open(msg);
        }
      }
    };
    asyncFunction();
  }, [
    assignedTo,
    user,
    close,
    params,
    blocker,
    alert,
    currentDocument,
    getSetDocuments,
  ]);

  useEffect(() => {
    dispatch({ type: "setRepo", repo: documents });
  }, [documents, dispatch]);

  useEffect(() => {
    dispatch({
      type: "setExplorerFilters",
      explorerFilters,
    });
  }, [explorerFilters, dispatch]);

  useEffect(() => {
    dispatch({ type: "setButtons", buttons: Buttons });
  }, [Buttons, dispatch]);

  useEffect(() => {
    window.addEventListener("keydown", hotkeys, false);
    return () => window.removeEventListener("keydown", hotkeys, false);
  }, [hotkeys]);

  return (
    <ExtractionPanel
      document={currentDocument}
      field={currentField}
      onFieldChange={onFieldChange}
      onFocus={onFocus}
      approveCell={approveCell}
      onTableRowUpdate={onTableRowUpdate}
      rowRef={rowRef}
    />
  );
};

export default ExtractionService;
