import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import CompareUtils from "../../utils/compare.utils";

const DefaultRuleTable = ({
  tableConfig,
  rowData,
  onRowClick,
  highlightRow,
  rowButton,
}) => {
  const orderBy = tableConfig[0] ? tableConfig[0].id : null;
  const rowIndex = -1;

  const highlight = (row) => {
    return highlightRow === row[orderBy] ? "selected" : "";
  };
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper
          sx={{ width: "100%", mb: 2, padding: "0 10px", marginTop: "10px" }}
        >
          <TableContainer>
            <Table aria-labelledby="tableTitle" size={"small"} stickyHeader>
              <TableHead>
                <TableRow>
                  {tableConfig.map((header, idx) => {
                    return <TableCell key={idx}>{header.label}</TableCell>;
                  })}
                  {rowButton != null && <TableCell />}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData
                  .slice()
                  .sort(CompareUtils.getComparator("asc", orderBy))
                  .slice(0)
                  .map((row, index) => {
                    return (
                      <TableRow
                        className={highlight(row)}
                        style={{ cursor: "pointer" }}
                        hover
                        onClick={(e) => {
                          onRowClick(e);
                        }}
                        tabIndex={-1}
                        key={index}
                      >
                        {tableConfig.map((header, idx) => {
                          return (
                            <TableCell key={idx}>{row[header.id]}</TableCell>
                          );
                        })}
                        {rowButton != null && (
                          <TableCell className="edit-button">
                            {index === rowIndex
                              ? rowButton({ visibility: "visible" })
                              : rowButton({ visibility: "hidden" })}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
};

DefaultRuleTable.propTypes = {};

DefaultRuleTable.defaultProps = {
  highlightRow: null,
  rowButton: null,
  onRowClick: null,
};

export default DefaultRuleTable;
