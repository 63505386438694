import TestUtils from "../../utils/test.utils";

const ProgressBar = ({ numerator, denominator }) => {
  const getWidth = () => {
    return (numerator / denominator) * 100 + "%";
  };

  return (
    <div className="outer-progress-bar">
      <div className="inner-progress-bar" style={{ width: getWidth() }} />
    </div>
  );
};

ProgressBar.propTypes = {
  numerator: (props, propName, componentName) => {
    return TestUtils.assertPropType(
      props,
      propName,
      componentName,
      /^[0-9]\d*$/,
      "Must be non-negative integer."
    );
  },
  denominator: (props, propName, componentName) => {
    return TestUtils.assertPropType(
      props,
      propName,
      componentName,
      /^[1-9]\d*$/,
      "Must be positive integer."
    );
  },
};

export default ProgressBar;
