import Request from "./Request";

const PATH = "/api/trapeze/classification";

const ClassificationApi = {
  getBatch: async (batchName) => {
    return await Request.get(`${PATH}/batches/${batchName}`);
  },
  reserveBatch: async (batchName) => {
    return await Request.put(`${PATH}/batches/${batchName}/reserve`, {});
  },
  releaseBatch: async (batchName) => {
    return await Request.put(`${PATH}/batches/${batchName}/release`, {});
  },
  getDocs: async (batchName) => {
    return await Request.get(`${PATH}/batches/${batchName}/docs/true`); // true is enabled for current API server implementation that uses a temp file for saves
  },
  getPages: async (batchName) => {
    return await Request.get(`${PATH}/batches/${batchName}/pages`);
  },
  saveBatch: async (batchName, docs) => {
    return await Request.put(`${PATH}/batches/${batchName}/save`, docs);
  },
  approveBatch: async (batchName, pages) => {
    return await Request.put(`${PATH}/batches/${batchName}/pages`, pages);
  },
};

export default ClassificationApi;
