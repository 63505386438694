import { useCallback, useContext, useEffect, useState, useMemo } from "react";
import { AppContext, ServiceContext } from "../../Contexts/Contexts";
import VersioningApi from "../../api/versioning-api";
import { useLocation, useNavigate } from "react-router-dom";
import ProgressBar from "../../components/Loaders/ProgressBar";
import ApproveIcon from "../../components/Icons/ApproveIcon";
import ApproveAllIcon from "../../components/Icons/ApproveAllIcon";
import SaveIcon from "../../components/Icons/SaveIcon";
import XCloseIcon from "../../components/Icons/XCloseIcon";
import VersioningPanel from "./Panels/versioning.panel";
import BrowserUtils from "../../utils/browser.utils";
import DomUtils from "../../utils/dom.utils";
import StringUtils from "../../utils/string.utils";
import { useQuery } from "@tanstack/react-query";
import { sleep } from "../../utils/general.utils";

const approvedStatuses = new Set(["approved", "autoapproved"]);

const VersioningService = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useMemo(
    () => BrowserUtils.getServiceParams(location.search),
    [location.search]
  );
  const { alert, loader, showCheckmark, onUnload, blocker, confirm, user } =
    useContext(AppContext);
  const { state, dispatch } = useContext(ServiceContext);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [currentDocType, setCurrentDocType] = useState(null);
  const [showApproved, setShowApproved] = useState(false);

  // GetSetters
  const getSetDocTypes = useCallback(async () => {
    if (!state.loan) return;
    try {
      loader.open(
        <div className="pad-10 bg-white br-5">Getting Document Types...</div>
      );
      const docTypes = await VersioningApi.getDocTypes(state.loan.name);
      docTypes.sort((a, b) => a.name.localeCompare(b.name));
      if (
        docTypes.filter((docType) => !approvedStatuses.has(docType.status))
          .length === 0
      ) {
        if (params.isStandalone) {
          blocker.open(
            <div>
              There are no more document types to review.
              <br />
              You can safely close the window.
            </div>
          );
        } else {
          alert.open(
            <div>There are no more document types to review.</div>,
            () => navigate(params.dashboardPath)
          );
        }
        return;
      }
      docTypes.forEach((docType, idx) => {
        docType.explorer = {
          type: "folder",
          name: docType.name,
          tag: (
            <>
              {DomUtils.getLockTag(docType.assignedTo)}
              {DomUtils.getApprovedTag(docType.status)}
            </>
          ),
        };
        docType.index = idx;
        docType.documents = [];
      });
      setDocumentTypes(docTypes);
      return docTypes;
    } catch (error) {
      console.error(error);
      alert.open(<div>{error.toString()}</div>);
    } finally {
      loader.close();
    }
  }, [state.loan, alert, navigate, blocker, params, loader]);

  const getSetDocuments = useCallback(
    async (docType) => {
      const className = "bg-white pad-10 br-5";
      setCurrentDocType(docType);
      try {
        loader.open(
          <div className={className}>Reserving Document Type...</div>
        );
        await VersioningApi.reserveDocType(state.loan.name, docType.name);
        onUnload.set(
          () => VersioningApi.releaseDocType(state.loan.name, docType.name),
          location.pathname
        );
        const documentTypes = await getSetDocTypes();
        const currentDocType = documentTypes.find(
          (docType) => docType.name === state.item.name
        );
        loader.open(<div className={className}>Getting Documents...</div>);
        const documents = await VersioningApi.getDocs(
          state.loan.name,
          docType.name
        );
        documents.forEach((doc, idx) => {
          doc.explorer = {
            type: "document",
            name: `${StringUtils.indexToLetter(idx)} (${doc.id})`,
            hidden: false,
            tag: DomUtils.getVersionTag(doc.version),
          };
          doc.originalVersion = doc.version;
          doc.pages.forEach((page) => {
            page.src = BrowserUtils.getPageUrl(state.loan.name, page.id);
          });
        });
        currentDocType.documents = documents;
        setCurrentDocType(currentDocType);
        dispatch({ type: "setItem", item: currentDocType });
      } catch (error) {
        console.error(error);
        alert.open(<div>{error.toString()}</div>);
      } finally {
        loader.close();
      }
    },
    [
      dispatch,
      state.loan,
      state.item,
      alert,
      loader,
      onUnload,
      location.pathname,
      getSetDocTypes,
    ]
  );

  // actions
  const close = useCallback(
    async (options = { noRelease: false }) => {
      setCurrentDocType(null);
      onUnload.clear();
      dispatch({ type: "setItem", item: null });
      try {
        loader.open(
          <div className="pad-10 bg-white br-5">Releasing Document Type...</div>
        );
        if (!options.noRelease) {
          await VersioningApi.releaseDocType(
            state.loan.name,
            currentDocType.name
          );
        }
        await getSetDocTypes();
      } catch (err) {
        console.error(err);
        alert.open(<div>{err.toString()}</div>);
        return;
      } finally {
        loader.close();
      }
    },
    [
      onUnload,
      dispatch,
      state.loan,
      currentDocType,
      alert,
      loader,
      getSetDocTypes,
    ]
  );

  const save = useCallback(
    async (options = { hideCheckmark: false }) => {
      try {
        const changedDocs = currentDocType.documents.filter(
          (doc) => doc.version !== doc.originalVersion
        );
        changedDocs.forEach(async (doc, idx) => {
          loader.open(
            <ProgressBar numerator={idx} denominator={changedDocs.length} />
          );
          const field = {
            data: doc.version,
            dataOriginal: doc.originalVersion,
            conf: 100,
          };
          await VersioningApi.saveDoc(
            state.loan.name,
            currentDocType.name,
            doc.id,
            field
          );
        });
      } catch (error) {
        console.error(error);
        alert.open(<div>{error.toString()}</div>);
        return;
      } finally {
        loader.close();
      }
      if (!options.hideCheckmark) await showCheckmark();
    },
    [currentDocType, state.loan, alert, loader, showCheckmark]
  );

  const approve = useCallback(async () => {
    await save({ hideCheckmark: true });
    try {
      loader.open(
        <div className="pad-10 bg-white br-5">Approving Document Type...</div>
      );
      await VersioningApi.approveDocType(state.loan.name, currentDocType.name);
    } catch (error) {
      console.error(error);
      alert.open(<div>{error.toString()}</div>);
      return;
    } finally {
      loader.close();
    }
    await showCheckmark();
    await close({ noRelease: true });
    await getSetDocTypes();
  }, [
    save,
    close,
    state.loan,
    currentDocType,
    alert,
    loader,
    showCheckmark,
    getSetDocTypes,
  ]);

  const approveAll = useCallback(async () => {
    try {
      state.repo.forEach(async (docType, idx) => {
        loader.open(
          <ProgressBar numerator={idx} denominator={state.repo.length} />
        );
        await VersioningApi.approveDocType(state.loan.name, docType.name);
      });
    } catch (error) {
      console.error(error);
      alert.open(<div>{error.toString()}</div>);
      return;
    } finally {
      loader.close();
    }
    await showCheckmark();
    if (params.isStandalone) {
      blocker.open(
        <div>This batch has been approved. You can now close this window.</div>
      );
    } else {
      navigate(params.dashboardPath);
    }
  }, [
    state.repo,
    state.loan,
    alert,
    loader,
    showCheckmark,
    navigate,
    blocker,
    params,
  ]);

  const isHidden = useCallback(
    (docType) => {
      return !showApproved && approvedStatuses.has(docType.status);
    },
    [showApproved]
  );

  const onVersion = useCallback(
    (document, version) => {
      document.version = version;
      document.versionConfidence = 100;
      document.explorer.tag = DomUtils.getVersionTag(version);
      dispatch({ type: "setRepo", repo: [...state.repo] });
    },
    [dispatch, state.repo]
  );

  const onClose = useCallback(async () => {
    confirm.open(
      <div>
        Are you sure you want to close this document type?
        <br /> Unsaved Changes will be lost.
      </div>,
      async () => await close()
    );
  }, [confirm, close]);

  const onSave = useCallback(async () => {
    await save();
  }, [save]);

  const onApprove = useCallback(async () => {
    confirm.open(
      <div>
        Are you sure you want to approve this document type?
        <br /> This action cannot be undone.
      </div>,
      async () => await approve()
    );
  }, [confirm, approve]);

  const onApproveAll = useCallback(async () => {
    confirm.open(
      <div>
        Are you sure you want to approve all document types?
        <br /> This action cannot be undone.
      </div>,
      async () => await approveAll()
    );
  }, [confirm, approveAll]);

  const onDocTypeSelected = useCallback(async () => {
    if (currentDocType) {
      await close(currentDocType.name);
    }
    await getSetDocuments(state.item);
  }, [currentDocType, getSetDocuments, state.item, close]);

  const explorerFilters = useMemo(() => {
    const bgColor = showApproved ? "bg-black" : "";
    return {
      isHidden,
      buttons: [
        <ApproveIcon
          size={20}
          className={`button br-5 ${bgColor}`}
          title="Show Approved"
          onClick={() => setShowApproved((toggle) => !toggle)}
        />,
      ],
    };
  }, [showApproved, isHidden]);

  const Buttons = useMemo(() => {
    const size = 50;
    if (!currentDocType)
      return [
        <ApproveAllIcon
          size={size}
          className="button pad-10 bg-orange"
          onClick={onApproveAll}
        />,
      ];
    return [
      <SaveIcon size={size} className="button pad-10" onClick={onSave} />,
      <ApproveIcon
        size={size}
        className="button pad-10 bg-orange"
        onClick={onApprove}
      />,
      <XCloseIcon size={size} className="button pad-10" onClick={onClose} />,
    ];
  }, [currentDocType, onApproveAll, onSave, onApprove, onClose]);

  const hotkeys = useCallback(
    (e) => {
      if (DomUtils.hasPopup()) return;
      if (e.ctrlKey) {
        switch (e.key) {
          case "s":
            onSave();
            break;
          case "Enter":
            currentDocType ? onApprove() : onApproveAll();
            break;
          default:
            break;
        }
      }
    },
    [onSave, onApprove, currentDocType, onApproveAll]
  );

  const { data: assignedTo } = useQuery({
    queryKey: ["getDocTypeAssignedTo", state.loan?.name, currentDocType?.name],
    queryFn: async () => {
      await sleep(0.5); // if admin takes over, ensure release completes before this check
      const docTypes = await VersioningApi.getDocTypes(state.loan.name);
      return docTypes.find((docType) => docType.name === currentDocType.name)
        .assignedTo;
    },
    refetchInterval: 60000,
    enabled: !!currentDocType,
  });

  useQuery({
    queryKey: ["getDocTypes"],
    queryFn: getSetDocTypes,
    enabled: !!state.loan,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!state.item || currentDocType?.name === state.item.name) return;
    const itemDriver = async () => {
      console.log("itemDriver");
      await onDocTypeSelected();
    };
    itemDriver();
  }, [onDocTypeSelected, currentDocType, state.item]);

  useEffect(() => {
    if (!currentDocType || !assignedTo || !user) return;
    if (assignedTo !== "unassigned" && assignedTo !== user.Username) {
      close({ noRelease: true });
      const msg = (
        <div>The document type has been reserved by another user.</div>
      );
      if (params.isStandalone) {
        blocker.open(msg);
      } else {
        alert.open(msg);
      }
    }
  }, [assignedTo, user, close, params, blocker, alert, currentDocType]);

  useEffect(() => {
    dispatch({ type: "setRepo", repo: documentTypes });
  }, [documentTypes, dispatch]);

  useEffect(() => {
    dispatch({ type: "setExplorerFilters", explorerFilters });
  }, [explorerFilters, dispatch]);

  useEffect(() => {
    dispatch({ type: "setButtons", buttons: Buttons });
  }, [Buttons, dispatch]);

  useEffect(() => {
    document.addEventListener("keydown", hotkeys);
    return () => document.removeEventListener("keydown", hotkeys);
  }, [hotkeys]);

  return (
    <VersioningPanel documentType={currentDocType} onVersion={onVersion} />
  );
};

export default VersioningService;
