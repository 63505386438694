import { useCallback, useState, useEffect, useContext } from "react";
import {
  DataGrid,
  GridCellEditStopReasons,
  useGridApiRef,
} from "@mui/x-data-grid";
import { AppContext } from "../../../Contexts/Contexts";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { MenuList } from "@mui/material";
import DomUtils from "../../../utils/dom.utils";
import { copy } from "../../../utils/general.utils";
import IdUtils from "../../../utils/id.utils";

const ExtractionTable = ({ table, onChange, onFocus, onRowUpdate }) => {
  const { confirm } = useContext(AppContext);
  const apiRef = useGridApiRef();
  const [cellModified, setCellModified] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  //GetSetters
  const getSetApiRef = useCallback((apiRef) => (table.ref = apiRef), [table]);

  const getCellData = useCallback(() => {
    const cellData = copy(table.rows);
    cellData.map((row) => {
      for (const cell in row) {
        if (cell !== "id" && cell !== "position") {
          row[cell] = row[cell].data;
        }
      }
      return { ...row };
    });
    return cellData;
  }, [table]);

  const getColumnWidth = useCallback((columns) => {
    const TABLE_WIDTH = 798;
    return TABLE_WIDTH / columns.length;
  }, []);

  //Actions
  const createNewCell = (columnName, lowThresh) => {
    return {
      columnName: columnName,
      conf: lowThresh,
      threshold: lowThresh,
      confOriginal: 0,
      data: "",
      dataOriginal: "",
      position: {
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
      },
    };
  };

  const addRow = useCallback(
    (rowPlacement) => {
      const newRowData = {
        id: IdUtils.generateUuid(),
        position: {
          page: 0,
          inputFilePageNumber: 0,
          inputFileID: 0,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      };
      for (const column of table.config.columns) {
        newRowData[column.name] = createNewCell(
          column.name,
          table.config.threshold
        );
      }

      const rowIndex = table.rows.findIndex((row) => row.id === selectedRow);
      switch (rowPlacement) {
        case "above":
          table.rows.splice(rowIndex, 0, newRowData);
          break;
        case "below":
          table.rows.splice(rowIndex + 1, 0, newRowData);
          break;
        default:
          table.rows.push(newRowData);
          break;
      }
      onRowUpdate(table.name, "add");
    },
    [table, onRowUpdate, selectedRow]
  );

  const deleteSelectedRows = useCallback(() => {
    const rowIndex = table.rows.findIndex((row) => row.id === selectedRow);
    apiRef.current.updateRows([{ id: selectedRow, _action: "delete" }]);
    table.rows.splice(rowIndex, 1);
    onRowUpdate(table.name, "delete");
  }, [onRowUpdate, table, apiRef, selectedRow]);

  //Clicks
  const onMenuOpen = useCallback(
    (event) => {
      event.preventDefault();
      const dataId = event.currentTarget.getAttribute("data-id");
      apiRef.current.selectRow(dataId);
      setSelectedRow(dataId);
      setContextMenu(
        contextMenu === null
          ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
          : null
      );
    },
    [contextMenu, apiRef]
  );

  const onMenuClose = useCallback(() => {
    apiRef.current.selectRow(selectedRow, false);
    setSelectedRow(null);
    setContextMenu(null);
  }, [apiRef, selectedRow]);

  const onAddRow = useCallback(
    (rowPlacement) => {
      onMenuClose();
      addRow(rowPlacement);
    },
    [addRow, onMenuClose]
  );

  const onRowDelete = useCallback(() => {
    onMenuClose();
    confirm.open(
      <div>Are you sure you want to delete the selected row?</div>,
      () => deleteSelectedRows()
    );
  }, [confirm, deleteSelectedRows, onMenuClose]);

  //useEffects
  useEffect(() => {
    getSetApiRef(apiRef);
  }, [apiRef, getSetApiRef]);

  //Sub-components
  const NoRowsOverlay = () => {
    return (
      <div
        className="no-row-overlay"
        onContextMenu={onMenuOpen}
        style={{ cursor: "context-menu" }}
      >
        <div>No Rows</div>
        {RowUpdateMenu(table.rows)}
      </div>
    );
  };

  const Columns = () => {
    const columnConfig = table.config.columns
      .filter((column) => column.visibility !== "collapsed")
      .map((column) => {
        const columnWidth = getColumnWidth(table.config.columns);
        return {
          field: column.name,
          headerName: column.label,
          width: columnWidth,
          type: column.inputType === "autocomplete" ? "singleSelect" : "string",
          valueOptions: column?.options,
          editable: true,
          preProcessEditCellProps: (params) => {
            setCellModified(params.hasChanged);
          },
          renderCell: (params) => {
            const field = table.rows.find((row) => row.id === params.id);
            return (
              <div
                style={{
                  position: "relative",
                  width: columnWidth - 30,
                }}
              >
                <div
                  style={{
                    width: "85%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {params.value}
                </div>
                {DomUtils.getValidatedIcon(field[params.field])}
              </div>
            );
          },
        };
      });
    return columnConfig;
  };

  const RowUpdateMenu = (rows) => {
    return (
      <Menu
        open={contextMenu !== null}
        onClose={onMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        slotProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              onMenuClose();
            },
          },
        }}
      >
        {rows.length > 0 ? (
          <MenuList>
            <MenuItem onClick={() => onAddRow("above")}>Add Row Above</MenuItem>
            <MenuItem onClick={() => onAddRow("below")}>Add Row Below</MenuItem>
            <MenuItem onClick={onRowDelete}>Delete Row</MenuItem>
          </MenuList>
        ) : (
          <MenuItem onClick={() => onAddRow()}>Add Row</MenuItem>
        )}
      </Menu>
    );
  };
  return (
    <div style={{ position: "relative", margin: "6.5px", minWidth: "100px" }}>
      <DataGrid
        apiRef={apiRef}
        getRowHeight={() => 52}
        columns={Columns()}
        hideFooter
        rows={getCellData()}
        slots={{
          noRowsOverlay: NoRowsOverlay,
        }}
        slotProps={{
          row: {
            onContextMenu: onMenuOpen,
            style: { cursor: "context-menu" },
          },
        }}
        onCellEditStart={async (params, e) => {
          if (
            params.reason === GridCellEditStopReasons.enterKeyDown ||
            params.reason === GridCellEditStopReasons.tabKeyDown
          ) {
            e.defaultMuiPrevented = true;
            return;
          }
          const row = table.rows.find((row) => row.id === params.id);
          const rowIdx = table.rows.findIndex((row) => row.id === params.id);
          const colIdx = table.columnNames.findIndex(
            (column) => column === params.field
          );
          const fieldData = {
            tableName: table.name,
            rowId: params.id,
            idx: { ...table.idx, tableRow: rowIdx, tableCol: colIdx },
            ...row[params.field],
          };
          await onFocus(fieldData);
        }}
        onCellEditStop={(params, e) => {
          e.defaultMuiPrevented = true;
          apiRef.current.stopCellEditMode({
            id: params.id,
            field: params.field,
          });
          if (
            !cellModified &&
            params.reason === GridCellEditStopReasons.enterKeyDown
          ) {
            setCellModified(!cellModified);
          }
        }}
        processRowUpdate={(updatedRow) => {
          if (cellModified) {
            onChange(table.name, updatedRow);
          }
          setCellModified(false);
          return updatedRow;
        }}
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "var(--true-blue)",
            color: "white",
            fontSize: 16,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: "white",
            minHeight: "52px",
          },
        }}
      />
      {RowUpdateMenu(table.rows)}
    </div>
  );
};

export default ExtractionTable;
