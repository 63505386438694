import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../../Contexts/Contexts";
import VerifAiApi from "../../../api/verifai-api";
import Profile from "../../../pages/Profile/Profile";
import DomUtils from "../../../utils/dom.utils";
import StringUtils from "../../../utils/string.utils";
import TrapezeClient from "../../../api/trapeze.client";

const ProfilePopup = ({ hide, className }) => {
  const { setUser, loader, leftPopOut, user, appSettings, onUnload } =
    useContext(AppContext);
  const navigate = useNavigate();
  const logout = async () => {
    loader.open();
    await onUnload.run();
    await TrapezeClient.logout();
    if (appSettings.useVerifai) await VerifAiApi.logout();
    setUser(null);
    navigate("/login");
    loader.close();
  };

  // onClicks
  const onProfile = () => {
    leftPopOut.open(
      DomUtils.getOrangeBlackHeader(
        "Profile",
        StringUtils.getLocalPartOfEmail(user.Username)
      ),
      <Profile />
    );
  };

  return (
    <nav hidden={hide} className={`profile-popup ${className}`}>
      <button className="green-hover" onClick={onProfile}>
        Profile
      </button>
      <button className="green-hover" onClick={logout}>
        Log Out
      </button>
    </nav>
  );
};

ProfilePopup.defaultProps = {
  className: "",
};

export default ProfilePopup;
