import { MenuItem, TextField } from "@mui/material";
import { useContext, useMemo, useState } from "react";
import { FileInput } from "../../../components/Inputs/FileInput";
import { AppContext } from "../../../Contexts/Contexts";
import UploadApi from "../../../api/upload-api";
import ErrorMessage from "../../../components/Alerts/ErrorMessage";
import { sleep } from "../../../utils/general.utils";

const AddBatch = ({ loan, batches }) => {
  const { loader, showCheckmark, leftPopOut, alert } = useContext(AppContext);
  const [batchName, setBatchName] = useState("");
  const [priority, setPriority] = useState("Medium");
  const [files, setFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState();

  const batchNames = useMemo(() => {
    const batchNames = new Set();
    for (const batch of batches) {
      batchNames.add(batch.name.toLowerCase());
    }
    return batchNames;
  }, [batches]);

  const addFiles = (newFiles) => {
    setFiles((files) => {
      for (const file of newFiles) {
        files.push(new File([file], file.name, { type: file.type }));
      }
      return files.map((file) => file);
    });
    document.getElementById("files").value = null;
  };

  const removeFile = (oldFile) => {
    setFiles((files) => {
      files.splice(files.indexOf(oldFile), 1);
      return files.map((file) => file);
    });
  };

  const upload = async (batchName, priority, files) => {
    setErrorMessage(null);
    if (files.length === 0) {
      setErrorMessage("There are no files to upload.");
      return;
    }
    if (batchName === "") {
      setErrorMessage("Please enter a batch name.");
      return;
    }
    if (batchNames.has(batchName.toLowerCase())) {
      setErrorMessage("Batch Name already exists.");
      return;
    }
    leftPopOut.close();
    loader.open();
    try {
      await UploadApi.uploadBatchThroughVerifai(
        loan._id,
        batchName,
        priority,
        files
      );
    } catch (error) {
      alert.open(<p>{`An error occurred: ${error.message}`}</p>);
      return;
    } finally {
      loader.close();
    }
    showCheckmark();
    await sleep(1);
  };

  return (
    <div className="standard-content">
      <form
        method="post"
        encType="multipart/form-data"
        onSubmit={(e) => {
          e.preventDefault();
          upload(batchName, priority, files);
        }}
        className="upload-container"
      >
        <TextField
          size="small"
          label="Batch Name"
          value={batchName}
          onChange={(e) => setBatchName(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          select
          size="small"
          label="Priority"
          value={priority}
          onChange={(e) => setPriority(e.target.value)}
        >
          <MenuItem value={"High"}>High</MenuItem>
          <MenuItem value={"Medium"}>Medium</MenuItem>
          <MenuItem value={"Low"}>Low</MenuItem>
        </TextField>
        <ErrorMessage message={errorMessage} sx={{ margin: "5px 0" }} />
        <FileInput files={files} addFiles={addFiles} removeFile={removeFile} />
      </form>
    </div>
  );
};

export default AddBatch;
