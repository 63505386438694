import { Switch } from "@mui/material";
import { useEffect, useState } from "react";
import ExtractionConfigAPI from "../../../../api/config-api/extraction-config-api";

const ModifyExtractionDocs = ({ currentDocType, refresh }) => {
  const currentDocument = currentDocType[0];
  const documentTypeName = currentDocument.documentTypeName;
  const [extractDoc, setExtractDoc] = useState([]);

  useEffect(() => {
    setExtractDoc(currentDocument.extract);
  }, [currentDocument]);

  return (
    <span>
      <label>Extract from Document Type</label>
      <Switch
        checked={extractDoc}
        onChange={async (e) => {
          setExtractDoc(e.target.checked);
          await ExtractionConfigAPI.modifyExtractionSupportedDocs(
            documentTypeName,
            {
              extract: e.target.checked,
            }
          );
          refresh();
        }}
      />
    </span>
  );
};

export default ModifyExtractionDocs;
