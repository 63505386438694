import { useContext } from "react";

import { AppContext } from "../../../Contexts/Contexts";
import BoxInfo from "../../../components/Info/BoxInfo";
import HardhatIcon from "../../../components/Icons/HardhatIcon";
import ServerIcon from "../../../components/Icons/ServerIcon";
import TrapezeClient from "../../../api/trapeze.client";
import Vars from "../../../store/vars";
import { useQuery } from "@tanstack/react-query";
import StandardDivider from "../../../components/Dividers/StandardDivider";
import BoxRow from "../../../components/Info/BoxRow";

const machineConfig = [
  { field: "alias", headerName: "Alias" },
  { field: "machineName", headerName: "Name" },
  { field: "publicUrl", headerName: "Public URL" },
];

const apiConfig = [
  {
    field: "status",
    headerName: "Status",
  },
  {
    field: "version",
    headerName: "Version",
  },
  {
    field: "database.name",
    headerName: "Database Name",
  },
  {
    field: "database.connected",
    headerName: "Database Status",
  },
];

const controllerConfig = [
  {
    field: "status",
    headerName: "Status",
  },
  {
    field: "version",
    headerName: "Version",
  },
  {
    field: "database.name",
    headerName: "Database Name",
  },
  {
    field: "database.connected",
    headerName: "Database Status",
  },
];

const projectConfig = [
  {
    field: "name",
    headerName: "Name",
  },
  {
    field: "version",
    headerName: "Version",
  },
];

const workflowConfig = [
  {
    field: "version",
    headerName: "Version",
  },
  {
    field: "mode",
    headerName: "Mode",
  },
];

const SystemInfo = () => {
  const { appSettings } = useContext(AppContext);
  const { isPending, data: health } = useQuery({
    queryKey: ["getServers"],
    queryFn: TrapezeClient.getServerInfo,
    refreshInterval:
      appSettings.refreshRateInSeconds * 1000 ?? Vars.refreshInterval,
  });

  if (isPending) return <div className="loader" />;

  return (
    health && (
      <div className="pad-5 flex-col gap-20 s-full scrollbar overflow">
        <BoxInfo
          name="Manager Servers"
          className="bg-blue"
          icon={<ServerIcon title="Manager" />}
        >
          {health.managers.map((manager, idx) => (
            <div key={idx}>
              <div className="bg-lg serif bold flex align-center justify-center">
                {`${idx + 1} - ${manager.alias}`}
              </div>
              <div className="pad-5 grid-col-2 gap-5 overflow-hidden">
                <BoxRow name="Machine" config={machineConfig} data={manager} />
                <BoxRow
                  name="Project"
                  config={projectConfig}
                  data={manager.project}
                />
                <BoxRow name="API" config={apiConfig} data={manager.api} />
                <BoxRow
                  name="Service"
                  config={controllerConfig}
                  data={manager.controller}
                />
              </div>
              <StandardDivider className="bg-darkgray" />
            </div>
          ))}
        </BoxInfo>
        <BoxInfo
          name="Worker Servers"
          className="bg-black"
          icon={<HardhatIcon />}
        >
          {health.workers.map((worker, idx) => (
            <div key={idx}>
              <div className="bg-lg serif bold flex align-center justify-center">
                {`${idx + 1} - ${worker.alias}`}
              </div>
              <div className="pad-5 grid-col-2 gap-5 overflow-hidden">
                <BoxRow name="Machine" config={machineConfig} data={worker} />
                <BoxRow
                  name="Project"
                  config={projectConfig}
                  data={worker.project}
                />
                <BoxRow name="API" config={apiConfig} data={worker.api} />
                <BoxRow
                  name="Workflow"
                  config={workflowConfig}
                  data={worker.workflow}
                />
              </div>
              <StandardDivider className="bg-darkgray" />
            </div>
          ))}
        </BoxInfo>
      </div>
    )
  );
};

export default SystemInfo;
