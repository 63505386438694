import { AppContext, ServiceContext } from "../../Contexts/Contexts";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useContext,
  useEffect,
  useReducer,
  cloneElement,
  Fragment,
  useMemo,
  useCallback,
} from "react";
import WorkflowApi from "../../api/workflow-api";
import Services from "../../store/services";
import VerifAiApi from "../../api/verifai-api";
import serviceReducer from "../../reducers/service.reducer";
import VerticalIconBar from "../../components/Bars/VerticalIconBar";
import BackToPageIcon from "../../components/Icons/BackToPageIcon";
import ServiceBanner from "../../components/Banners/ServiceBanner";
import Split from "react-split";
import Explorer from "../../components/Explorers/Explorer";
import SelectService from "./SelectService";
import DomUtils from "../../utils/dom.utils";
import { sleep } from "../../utils/general.utils";
import BrowserUtils from "../../utils/browser.utils";

const ServiceContainer = () => {
  const { alert, blocker, confirm, user } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const params = useMemo(
    () => BrowserUtils.getServiceParams(location.search),
    [location.search]
  );
  const [state, dispatch] = useReducer(serviceReducer, {
    service: params.service ? Services[params.service] : null,
    loan: null,
    item: null,
    repo: null,
    buttons: null,
    explorerFilters: {
      isHidden: () => false,
      buttons: [],
    },
  });

  const canReserve = async (item) => {
    const selectItem = async () => {
      if (
        state.item &&
        (state.service.id === "extraction" ||
          (state.service.id === "versioning" && state.item.name !== item.name))
      ) {
        await sleep(0.1); // the confirm within the confirm needs a delay to render
        confirm.open(
          <div>
            {`Are you sure you want to change ${state.service.itemType}s?`}
            <br /> Unsaved Changes will be lost.
          </div>,
          () => {
            dispatch({ type: "setItem", item: item });
          }
        );
        return;
      }
      dispatch({ type: "setItem", item: item });
    };
    if (item.assignedTo === "unassigned" || item.assignedTo === user.Username) {
      selectItem();
      return;
    } else {
      if (!user.IsAdministrator) {
        alert.open(
          <div>
            The document is assigned to a different user ({item.assignedTo}).
          </div>
        );
      } else {
        confirm.open(
          <div>
            The document is assigned to a different user ({item.assignedTo}).{" "}
            <br /> Would you like to override?
          </div>,
          async () => await selectItem()
        );
      }
    }
  };

  const onServiceSelected = useCallback(
    (service) => {
      if (state.service === service) return;
      dispatch({ type: "setService", service: service });
    },
    [state.service]
  );

  const onItemSelected = (item) => {
    if (state.item === item) return;
    switch (state.service.id) {
      case "versioning":
      case "extraction":
        canReserve(item);
        break;
      default:
        dispatch({ type: "setItem", item: item });
    }
  };

  const getIcons = (currentService) => {
    const icons = [];
    const addIcon = (service, className) => {
      const bg = currentService?.id === service?.id ? "bg-green" : "";
      icons.push(
        <div className={`pad-10 flex align-center ${bg}`}>
          {cloneElement(service?.icon, {
            size: 30,
            className: className,
            onClick: () => onServiceSelected(service),
          })}
        </div>
      );
    };
    if (params.verifai === "multi-service") {
      for (const serviceId in Services) {
        if (!Services[serviceId].trapezeId)
          addIcon(Services[serviceId], "button");
      }
    } else if (params.service) {
      addIcon(Services[params.service], "");
    } else {
      for (const serviceId in Services) {
        addIcon(Services[serviceId], "button");
      }
    }
    return icons;
  };

  const onLeave = () => {
    navigate(params.dashboardPath);
  };

  // driver
  useEffect(() => {
    const driver = async () => {
      const loanId = params.id;
      try {
        if (params.verifai) {
          const loan = await VerifAiApi.getLoans(loanId);
          dispatch({ type: "setLoan", loan: loan });
        } else {
          const loan = await WorkflowApi.getBatch(loanId);
          dispatch({ type: "setLoan", loan: loan });
        }
      } catch (error) {
        if (params.isStandalone) {
          blocker.open(<div>{error.toString()}</div>);
        } else {
          alert.open(<div>{error.toString()}</div>, () =>
            navigate(params.dashboardPath)
          );
        }
      }
    };

    if (state.loan) return;
    console.log("ServiceContainer Driver");
    driver();
  }, [alert, navigate, state.loan, params, blocker]);

  useEffect(() => {
    const blockTab = (e) => e.key === "Tab" && e.preventDefault();
    window.addEventListener("keydown", blockTab);
    return () => window.removeEventListener("keydown", blockTab);
  }, []);

  return (
    <ServiceContext.Provider value={{ state, dispatch }}>
      <div className="pad-10 h-full w-full">
        <div
          id="ServiceContainer"
          className="h-full w-full flex-col border-top-orange bg-white box-shadow gap-5 pad-5"
        >
          <div className="flex gap-5">
            <BackToPageIcon
              pageName="Dashboard"
              className="button pad-5"
              onClick={onLeave}
              disabled={params.isStandalone}
            />
            <ServiceBanner />
            {state.buttons && (
              <div className="flex gap-5">
                {state.buttons.map((button, idx) => (
                  <Fragment key={idx}>{button}</Fragment>
                ))}
              </div>
            )}
          </div>
          <div className="flex w-full h-full gap-5 overflow-hidden">
            <VerticalIconBar icons={getIcons(state.service)} />
            <Split
              className="flex w-full h-full overflow-hidden"
              sizes={[15, 85]}
              minSize={0}
              gutterSize={15}
              gutter={DomUtils.getGutter}
            >
              <Explorer
                repo={state.repo}
                name={state.service?.tableName}
                onClick={onItemSelected}
                index={state.item?.index}
                filters={state.explorerFilters}
              />
              <div className="overflow-hidden w-full h-full flex-col">
                {state.service?.component || (
                  <SelectService icons={getIcons(state.service)} />
                )}
              </div>
            </Split>
          </div>
        </div>
      </div>
    </ServiceContext.Provider>
  );
};

export default ServiceContainer;
