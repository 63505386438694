import { useCallback, useEffect, useRef, useState } from "react";
import VerifAiApi from "../../api/verifai-api";
import LoanTable from "./LoanTable";
import CandyButton from "../../components/Buttons/CandyButton";
import AddLoan from "./components/AddLoan";
import { useContext } from "react";
import { AppContext } from "../../Contexts/Contexts";
import DomUtils from "../../utils/dom.utils";
import Vars from "../../store/vars";

const LoanDashboard = () => {
  const { leftPopOut, appSettings } = useContext(AppContext);
  const [loans, setLoans] = useState([]);
  const fetching = useRef(false);

  //get-setters
  const getSetLoans = useCallback(async () => {
    if (fetching.current) return;
    try {
      fetching.current = true;
      const loans = await VerifAiApi.getLoans();
      setLoans(loans);
      return loans;
    } finally {
      fetching.current = false;
    }
  }, []);

  // useEffects
  useEffect(() => {
    getSetLoans();
    const id = setInterval(
      getSetLoans,
      appSettings.refreshRateInSeconds * 1000 ?? Vars.refreshInterval
    );
    return () => clearInterval(id);
  }, [getSetLoans, appSettings]);

  return (
    <div className="dashboard-container">
      <div className="dashboard-header">
        <h1>Loan Table</h1>
        <div className="right-header-buttons-container">
          <CandyButton
            element={<div>Add Loan</div>}
            onClick={() =>
              leftPopOut.open(
                DomUtils.getOrangeBlackHeader("Add", "Loan"),
                <AddLoan loans={loans} />
              )
            }
          />
        </div>
      </div>
      <div className="standard-content">
        <LoanTable loans={loans} />
      </div>
    </div>
  );
};

export default LoanDashboard;
