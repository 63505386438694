import { FiSave } from "react-icons/fi";
import PropTypes from "prop-types";

const SaveIcon = ({ onClick, size, sx, className }) => {
  return (
    <FiSave
      className={`no-shrink save-icon ${className}`}
      size={size}
      onClick={onClick}
      style={sx}
      title="Save"
    />
  );
};

SaveIcon.defaultProps = {
  size: 30,
  onClick: () => {},
  sx: {},
  className: "",
};

SaveIcon.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
};

export default SaveIcon;
