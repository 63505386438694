import { useState, useContext, useCallback, useEffect, useRef } from "react";
import CandyButton from "../../components/Buttons/CandyButton";
import trueLogo from "../../img/true/True-Logo-Black-Flat+Tag-Copyright.svg";
import { AppContext } from "../../Contexts/Contexts";
import Request from "../../api/Request";
import { useNavigate } from "react-router-dom";
import IqLogo from "../../img/mgic/IqLogo";
import Statuses from "../../api/statuses";
import AdminApi from "../../api/admin-api";
import VerifAiApi from "../../api/verifai-api";
import { UserManager } from "oidc-client-ts";
import BrowserUtils from "../../utils/browser.utils";

const Login = () => {
  const navigate = useNavigate();
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const { appSettings, user, setUser, alert } = useContext(AppContext);
  const [border, setBorder] = useState("1px solid #545454");
  const [toastText, setToastText] = useState("");
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);
    const loginPayload = {
      Username: username,
      Password: password,
    };
    try {
      const res = await Request.post(
        `/api/trapeze/auth/login/token`,
        loginPayload,
        { isLogin: true }
      );
      switch (res.status) {
        case Statuses.ok:
          if (appSettings.useVerifai) {
            try {
              await VerifAiApi.login(
                loginPayload.Username,
                loginPayload.Password
              );
            } catch (err) {
              alert.open(<div>Login To VerifAI Failed</div>);
              return;
            }
          }
          setUser(await AdminApi.fetchUser());
          const path = BrowserUtils.localStorage.get("redirect") || "/";
          BrowserUtils.localStorage.delete("redirect");
          navigate(path);
          break;
        case Statuses.unauthorized:
          setBorder("2px solid #BC4C4A");
          setToastText("Incorrect Username or Password");
          break;
        default:
          const data = await res.json();
          setToastText(data.ErrorMessage);
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  const redirectToProvider = () => {
    const oidcSettings = {
      authority: appSettings.authority,
      client_id: appSettings.clientId,
      redirect_uri: document.location.origin + "/login-sso",
      scope: "openid groups email",
      disablePKCE: true,
      response_mode: "query",
    };

    const userManager = new UserManager(oidcSettings);
    userManager.signinRedirect();
  };

  // hotkeys
  const hotkeys = useCallback((e) => {
    if (e.key === "Tab") {
      if (e.shiftKey) {
        usernameRef.current.focus();
      } else {
        passwordRef.current.focus();
      }
    }
  }, []);

  // useEffects
  useEffect(() => {
    window.addEventListener("keyup", hotkeys, false);
    return () => window.removeEventListener("keyup", hotkeys, false);
  }, [hotkeys]);

  useEffect(() => {
    if (user) navigate("/");
  }, [user, navigate]);

  // sub-components
  const Logo = () => {
    switch (appSettings.theme?.toLowerCase()) {
      case "mgic":
        return (
          <IqLogo className="center-block" invert style={{ width: "200px" }} />
        );
      default:
        return (
          <img
            className="center-block"
            src={trueLogo}
            alt="TRUE Logo"
            width="300px"
            style={{ marginBottom: "30px" }}
          />
        );
    }
  };

  return (
    <div className="login-container">
      <Logo />
      <div hidden={!loading} className="loader"></div>
      <div hidden={loading}>
        <form onSubmit={login}>
          <label hidden={true} htmlFor="username">
            {"username"}
          </label>
          <input
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            type="text"
            placeholder="Username"
            className="input center-block"
            name="username"
            id="username"
            autoFocus={true}
            style={{ border: border, fontSize: "16px" }}
            ref={usernameRef}
          />
          <label hidden={true} htmlFor="password">
            {"password"}
          </label>
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            type="password"
            minLength="1"
            placeholder="Password"
            className={"input center-block"}
            name="password"
            id="password"
            style={{ border: border, fontSize: "16px" }}
            ref={passwordRef}
          />
          <div className="toast-alert">{toastText}</div>
          <CandyButton
            className="secondary-color-scheme center-block"
            sx={{ width: "calc(100% - 30px)" }}
            element={<input type="submit" value="Log In" onClick={login} />}
          />
        </form>
        <div hidden={!appSettings.oidc}>
          <div className="separator"> or </div>
          <CandyButton
            className="secondary-color-scheme center-block"
            element={<div>Sign On With Identity Provider</div>}
            onClick={redirectToProvider}
            sx={{ width: "calc(100% - 30px)" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
