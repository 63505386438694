import { useContext } from "react";
import AdvancedSearchTable from "../../../components/Tables/AdvancedSearchTable";
import { AppContext } from "../../../Contexts/Contexts";
import DocTable from "../../Dashboard/components/DocTable";
import BatchTableConfig from "../config/batchTable.config";
import DomUtils from "../../../utils/dom.utils";

const BatchTable = ({ loan, batches }) => {
  const { leftPopOut } = useContext(AppContext);
  // clicks
  const onBatchSelected = async (batch) => {
    leftPopOut.open(
      DomUtils.getOrangeBlackHeader("Batch:", batch.label),
      <DocTable batch={batch} loan={loan} />
    );
  };
  return (
    <AdvancedSearchTable
      tableConfig={BatchTableConfig}
      data={batches}
      onIconClicks={{ view: onBatchSelected }}
      dateSearch
      type="batchTable"
    />
  );
};
export default BatchTable;
