import { MdOutlineAdminPanelSettings } from "react-icons/md";

const AdminIcon = ({ size, className, onClick }) => {
  return (
    <MdOutlineAdminPanelSettings
      size={size}
      className={`no-shrink ${className}`}
      onClick={onClick}
    />
  );
};

AdminIcon.defaultProps = {
  size: 30,
  className: "",
  onClick: () => {},
};

export default AdminIcon;
