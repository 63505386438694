import { renderToString } from "react-dom/server";
import ApproveIcon from "../components/Icons/ApproveIcon";
import DragIcon from "../components/Icons/DragIcon";
import LockIcon from "../components/Icons/LockIcon";
import AlertIcon from "../components/Icons/AlertIcon";
import ModifiedIcon from "../components/Icons/ModifiedIcon";
import CrossValIcon from "../components/Icons/CrossValIcon";
import DateTimeUtils from "./datetime.utils";
import StringUtils from "./string.utils";

class DomUtils {
  static isJsxElement(value) {
    return (
      typeof value === "object" &&
      value !== null &&
      value.hasOwnProperty("$$typeof")
    );
  }
  static filterOutJsx(obj) {
    if (Array.isArray(obj)) {
      return obj
        .filter((item) => !DomUtils.isJsxElement(item))
        .map((item) => DomUtils.filterOutJsx(item));
    } else if (typeof obj === "object" && obj !== null) {
      let newObj = {};
      for (let key in obj) {
        if (!DomUtils.isJsxElement(obj[key])) {
          newObj[key] = DomUtils.filterOutJsx(obj[key]);
        }
      }
      return newObj;
    }
    return obj;
  }
  static removeJsx(obj) {
    if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        if (DomUtils.isJsxElement(obj[i])) {
          obj.splice(i, 1);
          i--; // Decrement the index since we removed an item
        } else {
          DomUtils.removeJsx(obj[i]);
        }
      }
    } else if (typeof obj === "object" && obj !== null) {
      for (let key in obj) {
        if (DomUtils.isJsxElement(obj[key])) {
          delete obj[key];
        } else {
          DomUtils.removeJsx(obj[key]);
        }
      }
    }
  }
  static getVersionBgColor(version) {
    switch (version.toLowerCase()) {
      case "initial":
        return "bg-blue";
      case "intermediate":
        return "bg-red";
      case "final":
        return "bg-green";
      case "manual":
        return "bg-orange";
      case "unversioned":
        return "bg-gray";
      case "duplicate":
        return "bg-orange";
      default:
        return "bg-orange";
    }
  }
  static getVersionTag(version) {
    return (
      <div
        className={`font-12 bold pad-0-5 ellipses br-5 max-w-fit serif ${DomUtils.getVersionBgColor(
          version
        )}`}
      >
        {`${version.toUpperCase()}`}
      </div>
    );
  }
  static getApprovedTag(status) {
    if (status === "approved" || status === "autoapproved") {
      return <ApproveIcon className={"br-5"} size={15} title="Approved" />;
    }
  }
  static getLockTag = (assignedTo) => {
    if (assignedTo === "unassigned") {
      return null;
    }
    return <LockIcon size={12} title={`Reserved by ${assignedTo}`} />;
  };
  static getDateTag = (date) => {
    return (
      <div className="font-14 serif ellipses max-w-fit">
        {DateTimeUtils.format(date)}
      </div>
    );
  };
  static getOrangeBlackHeader(orangeText, blackText) {
    return (
      <h1
        className="flex gap-5 font-24 ellipses"
        title={`${orangeText} ${blackText}`}
      >
        <div className="orange">{orangeText}</div>
        {StringUtils.truncate(blackText, 64)}
      </h1>
    );
  }
  static getGutter(index, direction) {
    const gutter = document.createElement("div");
    gutter.className = `gutter gutter-${direction}`;
    gutter.innerHTML = renderToString(<DragIcon sx={{ margin: "auto" }} />);
    return gutter;
  }
  static hasPopup() {
    const viewport = document.getElementById("main");
    return viewport && viewport.classList.contains("dim");
  }
  static getValidatedIcon(field) {
    const threshold = field.config?.threshold ?? field.threshold;
    const confidence = field.confidence ?? field.conf;
    const iconSx = {
      position: "absolute",
      top: "1px",
      right: "1px",
    };
    const size = 18;
    if (field.data !== field.dataOriginal) {
      iconSx.backgroundColor = "#C8FD77";
      iconSx.padding = "2px";
      return <ModifiedIcon sx={iconSx} size={size} />;
    }
    if (confidence >= 200) {
      iconSx.backgroundColor = "#21C774";
      iconSx.padding = "2px";
      return <CrossValIcon sx={iconSx} size={size} />;
    }
    if (confidence < threshold) {
      return (
        <AlertIcon
          size={20}
          sx={iconSx}
          title={`Low Confidence (${confidence}%)`}
        />
      );
    }

    // placeholder for sizing
    return <div style={iconSx} />;
  }
}

export default DomUtils;
