import { LuHardHat } from "react-icons/lu";

const HardhatIcon = ({ size, className, title, onClick }) => {
  return (
    <LuHardHat
      className={className}
      size={size}
      title={title}
      onClick={onClick}
    />
  );
};

HardhatIcon.defaultProps = {
  className: "",
  title: "Worker",
  onClick: () => {},
};

export default HardhatIcon;
