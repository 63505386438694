import { MdEdit } from "react-icons/md";
import PropTypes from "prop-types";

const EditIcon = ({ onClick, size, sx, className, title }) => {
  return (
    <MdEdit
      className={`icon ${className}`}
      size={size}
      onClick={onClick}
      style={sx}
      title={title}
    />
  );
};

EditIcon.defaultProps = {
  size: 30,
  sx: {},
  title: "Edit",
  className: "",
  onClick: () => {},
};

EditIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  size: PropTypes.number,
  sx: PropTypes.object,
  title: PropTypes.string,
  className: PropTypes.string,
};

export default EditIcon;
