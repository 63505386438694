import { useContext, useEffect, useState } from "react";

import { AppContext } from "../../Contexts/Contexts";
import DateTimeUtils from "../../utils/datetime.utils";
import TrapezeClient from "../../api/trapeze.client";
import TrueIcon from "../Icons/TrueIcon";
import VersionInfo from "../Info/VersionInfo";

const version = document
  .querySelector('meta[name="version"]')
  .getAttribute("content");

const Footer = () => {
  const { appSettings, alert } = useContext(AppContext);
  const [managerVersion, setManagerVersion] = useState("x.x.x");
  const year = DateTimeUtils.getCurrentYear();

  useEffect(() => {
    const getManagerVersion = async () => {
      const health = await TrapezeClient.managerHealth();
      setManagerVersion(health.version);
    };
    getManagerVersion();
  }, []);

  return (
    <footer className="bg-black pad-0-5 font-12 flex space-between">
      <div className="flex gap-5">
        <TrueIcon size={15} color="white" />
        <div>{`Copyright ${year} TRUE`}</div>
      </div>
      <div className="flex align-center gap-5">
        <div
          className="button underline"
          onClick={() =>
            alert.open(
              <VersionInfo
                manager={managerVersion}
                version={version}
                api={appSettings.version}
              />
            )
          }
        >
          Version {version.substring(0, version.lastIndexOf("."))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
