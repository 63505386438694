import CandyButton from "../../components/Buttons/CandyButton";
import VerifAiApi from "../../api/verifai-api";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../Contexts/Contexts";
import BackToPageIcon from "../../components/Icons/BackToPageIcon";
import { useNavigate } from "react-router";
import BatchTable from "./tables/BatchTable";
import AddBatch from "./components/AddBatch";
import UploadLos from "../../components/Upload/UploadLos";
import StandardAttribute from "../../components/Attributes/StandardAttribute";
import BrowserUtils from "../../utils/browser.utils";
import DomUtils from "../../utils/dom.utils";
import DateTimeUtils from "../../utils/datetime.utils";
import Vars from "../../store/vars";

const LoanDetails = () => {
  const navigate = useNavigate();
  const { loader, showCheckmark, leftPopOut, appSettings } =
    useContext(AppContext);
  const [loan, setLoan] = useState(null);
  const [batches, setBatches] = useState([]);
  const fetching = useRef(false);

  // getSetters
  const getSetLoan = useCallback(async (loanId) => {
    const loan = await VerifAiApi.getLoans(loanId);
    setLoan(loan);
    return loan;
  }, []);

  const getSetBatches = useCallback(async (loanId) => {
    if (fetching.current) return;
    try {
      fetching.current = true;
      const batches = await VerifAiApi.getBatches(loanId);
      setBatches(batches);
      return batches;
    } finally {
      fetching.current = false;
    }
  }, []);

  // actions
  const runAudit = async (loan) => {
    loader.open();
    await VerifAiApi.runAudit(loan._id);
    loader.close();
    showCheckmark();
  };

  const runIncome = async (loan) => {
    loader.open();
    await VerifAiApi.runIncome(loan._id);
    loader.close();
    showCheckmark();
  };

  // onClicks
  const onRunAudit = async () => {
    await runAudit(loan);
  };

  const onRunIncome = async () => {
    await runIncome(loan);
  };

  const onLeave = () => {
    navigate("/loans");
  };

  // useEffects
  // driver
  useEffect(() => {
    const driver = async () => {
      console.log("loan details driver");
      const params = BrowserUtils.getSearchParams();
      if (!params.has("id")) return;
      const loan = await getSetLoan(params.get("id"));
      await getSetBatches(loan._id);
    };
    driver();
  }, [getSetLoan, getSetBatches]);

  useEffect(() => {
    const params = BrowserUtils.getSearchParams();
    if (!params.has("id")) return;
    const id = setInterval(
      () => getSetBatches(params.get("id")),
      appSettings.refreshRateInSeconds * 1000 ?? Vars.refreshInterval
    );
    return () => clearInterval(id);
  }, [getSetBatches, appSettings]);

  return (
    <div className="dashboard-container">
      <div className="dashboard-header">
        <BackToPageIcon
          onClick={async () => await onLeave("/")}
          pageName="Loans"
        />
        <StandardAttribute label="Loan Name" data={loan?.name} />
        <StandardAttribute
          label="Date Added"
          data={DateTimeUtils.format(loan?.dateAdded)}
        />
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "2fr 1fr 1fr",
          height: "100%",
          gap: "10px",
        }}
      >
        <div className="standard-content">
          <BatchTable loan={loan} batches={batches} />
        </div>
        <div>
          <div>{`Total Documents: ${loan?.documents.length}`}</div>
          <div>{`Total Income Reports: ${loan?.incomeReports.length}`}</div>
          <div>{`Total Audit Reports: ${loan?.auditReports.length}`}</div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div className="table-header">Controls</div>
          <CandyButton
            element={<div>Add Batch</div>}
            onClick={() =>
              leftPopOut.open(
                DomUtils.getOrangeBlackHeader("Add", "Batch"),
                <AddBatch loan={loan} batches={batches} />
              )
            }
          />
          <CandyButton
            className="tertiary-color-scheme-3"
            element={<div>Add LOS</div>}
            onClick={() =>
              leftPopOut.open(
                DomUtils.getOrangeBlackHeader("Add", "LOS"),
                <UploadLos loan={loan} runAudit={false} />
              )
            }
          />
          <CandyButton
            className="tertiary-color-scheme-1"
            element={<div>Services</div>}
            onClick={() =>
              navigate(
                `/services?id=${loan._id}&service=audit&verifai=multi-service`
              )
            }
          />
          <CandyButton
            className="tertiary-color-scheme-2"
            element={<div>Run Audit</div>}
            onClick={onRunAudit}
            disabled={!loan?.losData[0]}
          />
          <CandyButton
            className="tertiary-color-scheme-3"
            element={<div>Run Income</div>}
            onClick={onRunIncome}
          />
        </div>
      </div>
    </div>
  );
};

export default LoanDetails;
