import { useNavigate } from "react-router-dom";
import BackToPageIcon from "../../components/Icons/BackToPageIcon";
import AdminIcon from "../../components/Icons/AdminIcon";
import Explorer from "../../components/Explorers/Explorer";
import VerticalIconBar from "../../components/Bars/VerticalIconBar";
import AdminSettings from "../../store/admin-settings";
import Split from "react-split";
import { Fragment, useContext, useEffect, useReducer } from "react";
import adminSettingsReducer from "../../reducers/admin-settings.reducer";
import DomUtils from "../../utils/dom.utils";
import { AdminContext, AppContext } from "../../Contexts/Contexts";
import GearIcon from "../../components/Icons/GearIcon";
import StandardAttribute from "../../components/Attributes/StandardAttribute";

const AdminContainer = () => {
  const navigate = useNavigate();
  const { user, alert } = useContext(AppContext);
  const [state, dispatch] = useReducer(adminSettingsReducer, {
    setting: AdminSettings.systemInfo,
    buttons: null,
  });

  const onSettingSelected = (setting) => {
    dispatch({ type: "setSetting", setting: setting });
  };

  useEffect(() => {
    if (user && !user.IsSystemAdministrator) {
      alert.open("Only system administrators can access this page.", () =>
        navigate("/")
      );
    }
  }, [user, alert, navigate]);

  return (
    <AdminContext.Provider value={{ state, dispatch }}>
      <div className="pad-10 h-full w-full">
        <div
          id="admin-container"
          className="h-full w-full flex-col border-top-maroon bg-white box-shadow gap-5 pad-5"
        >
          <div className="flex gap-5">
            <BackToPageIcon
              pageName="Dashboard"
              className="button pad-5"
              onClick={() => navigate("/")}
            />
            <div className="flex w-full bg-green align-center pad-5-10 h-50 gap-20">
              <div className="flex gap-5 align-center">
                <AdminIcon size={30} />
                <h1 className="font-24">Admin</h1>
              </div>
              <div className="flex gap-5 align-center">
                <GearIcon size={30} />
                <StandardAttribute
                  label="Setting"
                  data={state.setting?.label ?? "None Selected"}
                />
              </div>
            </div>
            {state.buttons && (
              <div className="flex gap-5">
                {state.buttons.map((button, idx) => (
                  <Fragment key={idx}>{button}</Fragment>
                ))}
              </div>
            )}
          </div>
          <div className="flex w-full h-full gap-5 overflow-hidden">
            <VerticalIconBar icons={[]} className={"min-w-50"} />
            <Split
              className="flex w-full h-full overflow-hidden"
              sizes={[15, 85]}
              minSize={0}
              gutterSize={15}
              gutter={DomUtils.getGutter}
            >
              <Explorer
                repo={Object.values(AdminSettings)}
                name={"Settings"}
                onClick={onSettingSelected}
                index={0}
              />
              <div className="overflow-hidden s-full flex-col bg-lg">
                {state.setting?.component ?? (
                  <div className="bg-lg h-full flex align-center justify-center serif font-36">
                    Select a Setting
                  </div>
                )}
              </div>
            </Split>
          </div>
        </div>
      </div>
    </AdminContext.Provider>
  );
};

export default AdminContainer;
