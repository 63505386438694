import EditIcon from "../Icons/EditIcon";

export default function Edit({ children }) {
  return (
    <div className="flex gap-10 align-center" title="Double-click to edit">
      <EditIcon size={20} title="Double-click to edit" />
      {children}
    </div>
  );
}
