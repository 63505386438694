import { useContext } from "react";
import { AppContext } from "../../Contexts/Contexts";
import TrapezeClient from "../../api/trapeze.client";
import WorkflowApi from "../../api/workflow-api";
import Vars from "../../store/vars";
import Dashboard from "../../components/DataGrids/Dashboard";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../components/Loaders/Loader";
import Center from "../../components/Center";
import Toaster from "../../components/Toaster";

const BatchDashboard = () => {
  const { appSettings } = useContext(AppContext);
  const {
    data: batches,
    isPending: batchesPending,
    error: batchesError,
  } = useQuery({
    queryKey: ["getBatches"],
    queryFn: async () => {
      const batches = await WorkflowApi.getBatches();
      return batches.filter((batch) => !batch.inactive);
    },
    refetchInterval: (query) =>
      query.state.data
        ? appSettings.refreshRateInSeconds * 1000 ?? Vars.refreshInterval
        : false,
  });
  const {
    data: tableConfig,
    isPending: tableConfigPending,
    error: tableConfigError,
  } = useQuery({
    queryKey: ["getBatchTableColumns"],
    queryFn: TrapezeClient.getBatchTableColumns,
  });

  const error = batchesError ?? tableConfigError;
  if (error) {
    console.error(error);
    return (
      <Center>
        <Toaster>{error.toString()}</Toaster>
      </Center>
    );
  }

  if (batchesPending || tableConfigPending) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (!batches || !tableConfig) {
    return null;
  }

  return <Dashboard batches={batches} tableConfig={tableConfig} />;
};

export default BatchDashboard;
