import ObjectUtils from "./object.utils";

const compare = (query, value, operator) => {
  if (!value) return false;
  switch (operator) {
    case "=":
      return query === value;
    case "~":
      return value.includes(query);
    default:
      throw new Error(`Invalid operator: ${operator}`);
  }
};

const numberSearch = (query, data, filter) => {
  const filteredData = new Set();
  const numbers = query.value.split(",");
  for (let num of numbers) {
    num = num.trim();
    if (/^(\d+)-(\d+)$/.test(num)) {
      const range = num.split("-");
      if (!/^\d+$/.test(range[0]) || !/^\d+$/.test(range[1])) {
        console.log(`"${num}" is an invalid range`);
        return [];
      }
      range[0] = parseInt(range[0]);
      range[1] = parseInt(range[1]);
      if (range[0] > range[1]) {
        console.log(`"${num}" is an invalid range`);
        return [];
      }
      for (const row of data) {
        const value = getValue(row, filter);
        if (!value) continue;
        if (parseInt(value) >= range[0] && parseInt(value) <= range[1]) {
          filteredData.add(row);
        }
      }
    } else if (/^\d+$/.test(num)) {
      for (const row of data) {
        const value = getValue(row, filter);
        if (!value) continue;
        if (parseInt(value) === parseInt(num)) {
          filteredData.add(row);
        }
      }
    } else {
      console.log(`"${num}" is an invalid input`);
      return [];
    }
  }
  return [...filteredData];
};

const getValue = (row, filter) => {
  const value = ObjectUtils.getNestedValue(row, filter);
  return value?.toLowerCase();
};

const stringSearch = (query, data, filter) => {
  if (query.value.substring(0, 2) === "/!") {
    query = query.value.substring(2);
    return data.filter((row) => {
      const value = getValue(row, filter);
      return !compare(query.value, value, query.operator);
    });
  }
  return data.filter((row) => {
    const value = getValue(row, filter);
    return compare(query.value, value, query.operator);
  });
};

// special case when filter is "all"
const searchAllStrings = (query, data, tableConfig) => {
  return data.filter((row) => {
    for (const cell of tableConfig) {
      const value = getValue(row, cell.id);
      if (!value) continue;
      if (cell.type !== "date" && compare(query.value, value, query.operator)) {
        return true;
      }
    }
    return false;
  });
};

const dateSearch = (start, end, data, filter) => {
  return data.filter((row) => {
    const value = getValue(row, filter);
    if (!value) return false;
    const date = new Date(value);
    return date >= start && date <= end;
  });
};

const Search = {
  string: (query, data, tableConfig, filter) => {
    if (filter === "all") {
      return searchAllStrings(query, data, tableConfig);
    }
    return stringSearch(query, data, filter);
  },
  number: (query, data, tableConfig, filter) => {
    if (filter === "all") {
      return searchAllStrings(query, data, tableConfig);
    }
    return numberSearch(query, data, filter);
  },
  date: (start, end, data, _tableConfig, filter) => {
    end.setHours(23, 59, 59, 999);
    if (filter === "none") return data;
    return dateSearch(start, end, data, filter);
  },
};

export default Search;
