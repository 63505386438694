import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useState, useRef, useEffect, useCallback } from "react";
import BoundingBox from "../Annotations/BoundingBox";
import PlusIcon from "../Icons/PlusIcon";
import MinusIcon from "../Icons/MinusIcon";
import ArrowLeftIcon from "../Icons/ArrowLeftIcon";
import ArrowRightIcon from "../Icons/ArrowRightIcon";
import RotateLeftIcon from "../Icons/RotateLeftIcon";
import RotateRightIcon from "../Icons/RotateRightIcon";
import ExpandIcon from "../Icons/ExpandIcon";
import TrueIcon from "../Icons/TrueIcon";
import BrowserUtils from "../../utils/browser.utils";
import { isEmpty, sleep } from "../../utils/general.utils";

const Viewer = ({
  images,
  index: _index,
  coordinates: initialCoordinates,
  dimensions,
  className,
  sx,
  isFullScreen,
}) => {
  const [index, setIndex] = useState(0);
  const [coordinates, setCoordinates] = useState(initialCoordinates);
  const [rotate, setRotate] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const viewerRef = useRef(null);
  const imageRef = useRef(null);
  const alt = `image-${index + 1}`;

  const waitForImageLoad = useCallback(async () => {
    let imageRect = imageRef?.current?.getBoundingClientRect();

    // This is a hack to get the imageRect to update after the image loads.
    while (imageRect && imageRect.width === 0) {
      await sleep(0.1);
      imageRect = imageRef.current?.getBoundingClientRect();
    }
    setImageLoaded(true);
  }, []);

  const onExpand = () => {
    BrowserUtils.localStorage.set("focus", {
      images,
      index,
      coordinates,
      dimensions,
    });

    window.open(`/focus`, "_blank");
  };

  useEffect(() => setCoordinates(initialCoordinates), [initialCoordinates]);
  useEffect(() => setIndex(0), [images]);

  useEffect(() => {
    const driver = async () => {
      waitForImageLoad();
    };
    driver();
  }, [waitForImageLoad]);

  // coordinates changed
  useEffect(() => {
    if (_index >= 0 && !isEmpty(coordinates)) {
      setIndex(_index);
    }
  }, [_index, coordinates]);

  // sub-components
  const ViewerControls = (zoomIn, zoomOut) => {
    const iconSize = 20;
    return (
      <div className="w-full flex justify-center">
        <div className="flex-center gap-20 pad-5 w-fit br-5 border-white overflow-hidden">
          {!isFullScreen && <TrueIcon size={iconSize} color={"black"} />}
          <div className="flex gap-5">
            <PlusIcon
              size={iconSize}
              className={"button blue"}
              onClick={() => zoomIn()}
              title={"Zoom In"}
            />
            <MinusIcon
              size={iconSize}
              className={"button blue"}
              onClick={() => zoomOut()}
              title={"Zoom Out"}
            />
          </div>
          <div className="flex gap-5">
            <ArrowLeftIcon
              title={"Previous"}
              className={"button orange"}
              size={iconSize}
              onClick={() => {
                setCoordinates({});
                setRotate(0);
                setIndex((index) => index - 1);
              }}
              disabled={index <= 0}
            />
            <div className="nowrap bg-white pad-0-5 font-14 serif br-5 bold">{`${
              index + 1
            } / ${images.length}`}</div>
            <ArrowRightIcon
              title={"Next"}
              className={"button orange"}
              size={iconSize}
              onClick={() => {
                setCoordinates({});
                setRotate(0);
                setIndex((index) => index + 1);
              }}
              disabled={index >= images.length - 1}
            />
          </div>
          <div className="flex gap-5">
            <RotateLeftIcon
              size={iconSize}
              className={"button blue"}
              onClick={() => setRotate((rotate) => (rotate + 270) % 360)}
            />
            <RotateRightIcon
              size={iconSize}
              className={"button blue"}
              onClick={() => setRotate((rotate) => (rotate + 90) % 360)}
            />
          </div>
          {!isFullScreen && (
            <ExpandIcon
              className={"button blue"}
              size={iconSize}
              onClick={onExpand}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <TransformWrapper initialScale={1} ref={viewerRef}>
      {({ zoomIn, zoomOut, ...rest }) => {
        return (
          <div
            className={`overflow-hidden box-shadow w-full h-full flex-col bg-white border-top-green pad-5 ${className}`}
            style={sx}
          >
            <div className="bg-lg s-full flex-col pad-5 gap-5">
              {ViewerControls(zoomIn, zoomOut)}
              <TransformComponent
                wrapperStyle={{ height: "100%", width: "100%" }}
                contentClass="grab"
                contentStyle={{ height: "100%", width: "100%" }}
              >
                <div className="ms-full flex-center">
                  <div className="relative h-full">
                    {images.length > 0 ? (
                      <img
                        ref={imageRef}
                        src={images[index]?.src}
                        alt={alt}
                        className={`mh-full r-${rotate}`}
                      />
                    ) : null}
                    {dimensions && dimensions.width + dimensions.height > 0 && (
                      <BoundingBox
                        viewerWrapperRect={viewerRef.current?.instance.wrapperComponent.getBoundingClientRect()}
                        viewerContentRect={viewerRef.current?.instance.contentComponent.getBoundingClientRect()}
                        imageRect={
                          imageLoaded
                            ? imageRef?.current?.getBoundingClientRect()
                            : null
                        }
                        coordinates={coordinates}
                        currentZoom={rest.instance.transformState.scale}
                        dimensions={dimensions}
                        setTransform={rest.setTransform}
                      />
                    )}
                  </div>
                </div>
              </TransformComponent>
            </div>
          </div>
        );
      }}
    </TransformWrapper>
  );
};

Viewer.defaultProps = {
  images: [],
  coordinates: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  dimensions: {
    width: 0,
    height: 0,
  },
  index: 0,
  className: "",
  sx: {},
  isFullScreen: false,
};

export default Viewer;
