import PropTypes from "prop-types";
import { BsInputCursorText } from "react-icons/bs";

const ExtractionIcon = ({ onClick, size, sx, className }) => {
  return (
    <BsInputCursorText
      className={`icon ${className}`}
      size={size}
      onClick={onClick}
      style={sx}
      title="Extract"
    />
  );
};

ExtractionIcon.defaultProps = {
  size: 30,
  sx: {},
  onClick: () => {},
  className: "",
};

ExtractionIcon.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
  className: PropTypes.string,
  sx: PropTypes.object,
};

export default ExtractionIcon;
