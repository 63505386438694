const StandardAttribute = ({ label, data, children, color, className, sx }) => {
  return (
    <div className={`flex-col ${className}`} style={sx}>
      <div className="bold font-14">{`${label}`}</div>
      <div className="flex gap-5 serif align-center">
        {data && (
          <div className="nowrap" style={{ color: color }}>{`${data}`}</div>
        )}
        {children}
      </div>
    </div>
  );
};

StandardAttribute.defaultProps = {
  label: "",
  data: "",
  children: <></>,
  color: "black",
  className: "",
  sx: {},
};

export default StandardAttribute;
