import { MenuItem, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import VerifAiApi from "../../api/verifai-api";
import { AppContext } from "../../Contexts/Contexts";
import ErrorMessage from "../Alerts/ErrorMessage";
import { FileInput } from "../Inputs/FileInput";
import SubmitInput from "../Inputs/SubmitInput";

const UploadLos = ({ loan, runAudit }) => {
  const { leftPopOut, loader, alert, showCheckmark } = useContext(AppContext);
  const [files, setFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const [eFileType, setEFileType] = useState("");

  const addFiles = (newFiles) => {
    setFiles((files) => {
      for (const file of newFiles) {
        files.push(new File([file], file.name, { type: file.type }));
      }
      return files.map((file) => file);
    });
    document.getElementById("files").value = null;
  };

  const removeFile = (oldFile) => {
    setFiles((files) => {
      files.splice(files.indexOf(oldFile), 1);
      return files.map((file) => file);
    });
  };

  const upload = async () => {
    loader.open();
    try {
      const reader = new FileReader();
      reader.readAsText(files[0]);
      reader.onload = async () => {
        await VerifAiApi.postLos(loan._id, eFileType, reader.result);
        if (runAudit) await VerifAiApi.runAudit(loan._id);
      };
    } catch (error) {
      alert.open(<p>{`An error occurred: ${error.message}`}</p>);
      return;
    } finally {
      loader.close();
    }
    showCheckmark();
  };

  const onUpload = () => {
    setErrorMessage(null);
    if (files.length === 0) {
      setErrorMessage("Select file(s) to upload.");
      return;
    }
    if (eFileType === "") {
      setErrorMessage("No e-File Type selected.");
      return;
    }
    leftPopOut.close();
    upload();
  };

  return (
    <div className="standard-content">
      <form
        method="post"
        encType="multipart/form-data"
        onSubmit={(e) => {
          e.preventDefault();
          onUpload();
        }}
        className="upload-form-container"
      >
        <TextField
          select
          size="small"
          label="e-File Type"
          value={eFileType}
          onChange={(e) => setEFileType(e.target.value)}
        >
          <MenuItem value={"DU3.2"}>DU 3.2</MenuItem>
          <MenuItem value={"MISMO3.4"}>MISMO 3.4</MenuItem>
        </TextField>
        <ErrorMessage message={errorMessage} sx={{ margin: "5px 0" }} />
        <FileInput files={files} addFiles={addFiles} removeFile={removeFile} />
        <div className="upload-file-button-container">
          <SubmitInput value="Upload Files" />
        </div>
      </form>
    </div>
  );
};

UploadLos.defaultProps = {
  runAudit: true,
};

export default UploadLos;
