import { Autocomplete, TextField } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import ExtractionConfigApi from "../../../api/config-api/extraction-config-api";
import SquareAddButton from "../../../components/Buttons/SquareAddButton";
import { HiOutlineRefresh } from "react-icons/hi";
import SearchTable from "../../../components/Tables/SearchTable";
import { AppContext } from "../../../Contexts/Contexts";
import AddExtractionFieldRule from "./components/AddExtractionFieldRule";
import DefaultRuleTable from "../../../components/Tables/DefaultRuleTable";
import { FiEdit } from "react-icons/fi";
import ModifyExtractionFieldRule from "./components/ModifyExtractionFieldRule";
import ModifyExtractionDocs from "./components/ModifyExtractionDocs";
import TableConfig from "../config/TableConfig";

const ExtractionConfig = () => {
  const { popup } = useContext(AppContext);
  const [extractionDocumentTypes, setExtractionDocumentTypes] = useState([]);
  const [currentDocType, setCurrentDocType] = useState(null);
  const [fieldRules, setFieldRules] = useState([]);
  const [defaultFieldRule, setDefaultFieldRule] = useState([]);
  const [curFields, setCurFields] = useState([]);

  //Helper functions
  const getFieldName = (e) => {
    return e.target.parentNode.firstChild.innerText;
  };

  const filteredFields = (data, fieldRules) => {
    return data.Fields.filter((row) => {
      return !fieldRules
        .map((row) => row.fieldName.toLowerCase())
        .includes(row.fieldName.toLowerCase());
    }).map((field) => {
      return field.fieldName;
    });
  };

  //Get-setters
  const getSetExtractionDocumentTypes = useCallback(async () => {
    const data = await ExtractionConfigApi.getExtractionSupportedDocs();
    setExtractionDocumentTypes(data.documentTypes);
    return data.documentTypes;
  }, []);

  const getSetDocumentExtractionFields = useCallback(async () => {
    const data = await ExtractionConfigApi.getDocumentExtractionFields(
      currentDocType
    );
    const fields = filteredFields(data, fieldRules);
    setCurFields(fields);
    return fields;
  }, [currentDocType, fieldRules]);

  const getSetDocumentExtractionFieldRules = useCallback(async () => {
    const data = await ExtractionConfigApi.getDocumentExtractionFieldRules(
      currentDocType
    );
    setFieldRules(
      data.Fields.filter((row) => {
        return row.fieldName.toLowerCase() !== "default";
      })
    );
    setDefaultFieldRule(
      data.Fields.filter((row) => {
        return row.fieldName.toLowerCase() === "default";
      })
    );
  }, [currentDocType]);

  //Actions
  const openModify = (curField) => {
    console.log(fieldRules);
    popup.open(
      `Edit Rule`,
      <ModifyExtractionFieldRule
        sx={{
          width: "400px",
          height: "300px",
        }}
        currentField={curField}
        documentTypeName={currentDocType}
        refresh={() => getSetDocumentExtractionFieldRules()}
        defaultRule={defaultFieldRule}
      />
    );
  };

  const openAdd = () => {
    popup.open(
      "Add Field Rule",
      <AddExtractionFieldRule
        sx={{
          width: "400px",
          height: "300px",
        }}
        documentTypeName={currentDocType}
        fieldRules={curFields}
        defaultRule={defaultFieldRule}
        refresh={() => getSetDocumentExtractionFieldRules()}
      />
    );
  };

  // const closeDoc = useCallback(() => {
  //   setFieldRules([]);
  //   setDefaultFieldRule([]);
  //   setCurFields([]);
  // }, []);

  // const openDoc = useCallback(
  //   async (documentType) => {
  //     if (currentDocType) {
  //       await getSetDocumentExtractionFieldRules();
  //       await getSetDocumentExtractionFields();
  //     }
  //   },
  //   [
  //     getSetDocumentExtractionFields,
  //     getSetDocumentExtractionFieldRules,
  //     currentDocType,
  //   ]
  // );

  //Use-effects
  //driver
  useEffect(() => {
    getSetExtractionDocumentTypes();
  }, [getSetExtractionDocumentTypes]);

  useEffect(() => {
    if (!currentDocType) {
      setFieldRules([]);
      setDefaultFieldRule([]);
      return;
    }
    getSetDocumentExtractionFieldRules();
  }, [getSetDocumentExtractionFieldRules, currentDocType]);

  useEffect(() => {
    if (!currentDocType) {
      setCurFields([]);
      return;
    }
    getSetDocumentExtractionFields();
  }, [getSetDocumentExtractionFields, currentDocType]);

  useEffect(() => {
    console.log(currentDocType);
  }, [currentDocType]);

  //Sub-components
  const DocumentSearchBar = () => {
    return (
      <Autocomplete
        sx={{
          "& .css-2iz2x6": {
            top: "calc(50% - 20px)",
          },
          "&  .MuiAutocomplete-endAdornment": {
            marginTop: "-2px",
          },
        }}
        options={extractionDocumentTypes.map((row) => {
          return row.documentTypeName;
        })}
        autoComplete
        autoHighlight
        renderInput={(params) => (
          <TextField
            {...params}
            label="Document Types"
            placeholder="Search Document Types..."
            InputLabelProps={{ shrink: true }}
          />
        )}
        // onClose={() => {
        //   setCurrentDocType(null);
        //   closeDoc();
        // }}
        onChange={(e) => {
          setCurrentDocType(e.target.innerText);
          // openDoc(e.target.innerText);
        }}
      />
    );
  };

  const ExtractionToggle = () => {
    return (
      <ModifyExtractionDocs
        currentDocType={extractionDocumentTypes.filter((row) => {
          return row.documentTypeName === currentDocType;
        })}
        refresh={() => getSetExtractionDocumentTypes()}
      />
    );
  };

  const DefaultTable = () => {
    return (
      <div style={{ marginTop: "10px" }}>
        <h1 className="table-header">Default Versioning Rule</h1>
        <DefaultRuleTable
          tableConfig={TableConfig.extractionConfig.filter((row) => {
            return row.id !== "hideField";
          })}
          rowData={defaultFieldRule}
          onRowClick={(e) => openModify(defaultFieldRule)}
          rowButton={RowButton}
        />
      </div>
    );
  };

  const RulesTable = () => {
    return (
      <>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "10fr 2fr 1fr",
          }}
        >
          <h1 className="table-header">Field Rules</h1>
          {currentDocType && AddRuleButton()}
          {RefreshButton()}
        </div>

        <SearchTable
          placeholder="Search Fields..."
          tableConfig={TableConfig.extractionConfig}
          rowData={fieldRules}
          onRowClick={(e) =>
            openModify(
              fieldRules.filter((row) => row.fieldName === getFieldName(e))
            )
          }
          rowButton={RowButton}
        />
      </>
    );
  };

  const AddRuleButton = () => {
    return <SquareAddButton text="Add Rule" onClick={() => openAdd()} />;
  };

  const RefreshButton = () => {
    return (
      <div
        className="refresh-icon"
        style={{ marginRight: "0", marginBottom: "0", gridColumn: "3" }}
      >
        <HiOutlineRefresh
          size={20}
          onClick={() => getSetDocumentExtractionFieldRules()}
        />
      </div>
    );
  };

  const RowButton = (style) => {
    return (
      <FiEdit
        className="edit-button"
        style={style}
        onClick={(e) => openModify(e)}
      />
    );
  };

  return (
    <div
      className="config-content"
      style={{ width: "600px", marginTop: "50px" }}
    >
      <h1 className="config-header" style={{ marginBottom: "20px" }}>
        Extraction Configuration Rules
      </h1>
      {DocumentSearchBar(extractionDocumentTypes)}
      {currentDocType && ExtractionToggle()}
      {DefaultTable()}
      {RulesTable()}
    </div>
  );
};

export default ExtractionConfig;
