import TreeStructureIcon from "../Icons/TreeStructureIcon";
import { useState, useEffect, useCallback, useMemo } from "react";
import Folder from "./Folder";
import Document from "./Document";
import { useRef, Fragment } from "react";
import SearchBar from "../SearchBars/SearchBar";
import { sleep } from "../../utils/general.utils";

const Explorer = ({ repo, name, onClick: __onClick, index, filters }) => {
  // console.log("Explorer");
  const itemRefs = useRef([]);
  const [currentIndex, setCurrentIndex] = useState({ outer: -1, inner: -1 });
  const [search, setSearch] = useState("");
  const visibleRows = useMemo(() => {
    return repo?.filter((item) => {
      return (
        !filters?.isHidden(item) &&
        item.explorer.name.toLowerCase().includes(search.toLowerCase())
      );
    });
  }, [repo, search, filters]);

  const onClick = (item, index) => {
    setCurrentIndex(index);
    return __onClick(item, index);
  };

  const onIndexChange = useCallback(async (index) => {
    setCurrentIndex({ outer: index, inner: -1 });
    await sleep(0.1); // allow time for the DOM to update
    if (itemRefs.current[index]) {
      itemRefs.current[index].scrollIntoView({
        behavior: "auto",
        block: "nearest",
        inline: "start",
      });
    }
  }, []);

  useEffect(() => {
    onIndexChange(index);
  }, [index, onIndexChange]);

  return (
    <div className="flex-col gap-5 overflow-hidden">
      <div className="bg-orange pad-10-5 flex gap-5 align-center">
        <TreeStructureIcon size={30} />
        <div className="bold ellipses" title={name}>
          {name}
        </div>
      </div>
      {
        <div className="bg-lg pad-5 flex gap-5 align-center space-between">
          <div className="bold font-14 nowrap">{`${
            visibleRows?.length ?? 0
          } of ${repo?.length ?? 0} shown`}</div>
          {filters && (
            <div className="flex gap-5">
              {filters.buttons.map((button, idx) => (
                <Fragment key={idx}>{button}</Fragment>
              ))}
            </div>
          )}
        </div>
      }
      <div className="bg-lg pad-5">
        <SearchBar
          value={search}
          onChange={setSearch}
          inputProps={{ style: { padding: "5px 10px" } }}
          className={"bg-white"}
        />
      </div>
      <div className="border-2 bg-white h-full overflow scrollbar">
        {!repo ? (
          <div className="flex h-full serif justify-center align-center bg-lg">
            No Files
          </div>
        ) : (
          repo.map((item, outerIdx) => {
            if (item.explorer.type === "folder") {
              return (
                <Folder
                  // ref={(el) => (itemRefs.current[outerIdx] = el)}
                  key={outerIdx}
                  _this={item}
                  index={{ outer: outerIdx, inner: -1 }}
                  selectedIndex={currentIndex}
                  onClick={onClick}
                  hidden={
                    !item.explorer.name
                      .toLowerCase()
                      .includes(search.toLowerCase()) || filters.isHidden(item)
                  }
                />
              );
            }
            return (
              <Document
                ref={(el) => (itemRefs.current[outerIdx] = el)}
                key={outerIdx}
                _this={item}
                index={{ outer: outerIdx, inner: -1 }}
                selectedIndex={currentIndex}
                onClick={onClick}
                hidden={
                  !item.explorer.name
                    .toLowerCase()
                    .includes(search.toLowerCase()) || filters?.isHidden(item)
                }
              />
            );
          })
        )}
      </div>
    </div>
  );
};

Explorer.defaultProps = {
  name: "Explorer",
  onClick: () => {},
  index: -1,
  controls: null,
};

export default Explorer;
