import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import StandardDataGrid from "../../../components/DataGrids/StandardDataGrid";
import TrapezeClient from "../../../api/trapeze.client";
import { GridEditInputCell, useGridApiRef } from "@mui/x-data-grid-pro";
import { AdminContext, AppContext } from "../../../Contexts/Contexts";
import SaveIcon from "../../../components/Icons/SaveIcon";
import AlertIcon from "../../../components/Icons/AlertIcon";
import IdUtils from "../../../utils/id.utils";
import DeleteIcon from "../../../components/Icons/DeleteIcon";
import AddIcon from "../../../components/Icons/AddIcon";
import Edit from "../../../components/Edit/Edit";
import WorkflowApi from "../../../api/workflow-api";
import { useQuery } from "@tanstack/react-query";
import StringUtils from "../../../utils/string.utils";

const tagIsUsedMsg =
  "The tag you are attempting to delete has an assigned value in existing batches.";

const MetadataTags = () => {
  const [tags, setTags] = useState([]);
  const apiRef = useGridApiRef();
  const { confirm, showCheckmark } = useContext(AppContext);
  const { dispatch } = useContext(AdminContext);
  const { data: usedTagIds } = useQuery({
    queryKey: ["getIndexedBatches"],
    queryFn: async () => {
      const batches = await WorkflowApi.getBatches();
      const usedTagIds = new Set();
      batches.forEach((batch) => {
        batch.batchTags.forEach((tag) => {
          usedTagIds.add(tag.tagID);
        });
      });
      return usedTagIds;
    },
  });

  // Get-Setters
  const getSetTags = useCallback(async () => {
    const _tags = await TrapezeClient.getTags();
    const tags = _tags.map((tag) => {
      return {
        id: tag.tagID,
        name: tag.tagName,
        editPermissions: tag.editPermission,
      };
    });
    setTags(tags);
    return tags;
  }, []);

  const renderEditCell = useCallback(
    (props) => {
      const { error } = props;
      const iconSx = {
        margin: "5px",
      };

      const handleInvalidTagName = (e) => {
        if (error) {
          apiRef.current.stopCellEditMode({
            id: props.id,
            field: props.field,
            ignoreModifications: true,
          });
        }
      };
      return (
        <div className="flex">
          {error && !props.isProcessingProps && (
            <AlertIcon size={25} title={"Invalid Tag Name"} sx={iconSx} />
          )}
          <GridEditInputCell onBlur={handleInvalidTagName} {...props} />
        </div>
      );
    },
    [apiRef]
  );

  // Actions
  const deleteTag = useCallback(
    async (selectedTag) => {
      const rowIndex = tags.findIndex((row) => row.id === selectedTag.id);
      apiRef.current.updateRows([{ id: selectedTag.id, _action: "delete" }]);
      if (!IdUtils.isValidUuid(tags[rowIndex].id)) {
        await TrapezeClient.deleteTag(tags[rowIndex].id);
      }
      tags.splice(rowIndex, 1);
      setTags([...tags]);
    },
    [tags, apiRef]
  );

  const addTag = useCallback(() => {
    const newRow = {
      id: IdUtils.generateUuid(),
      name: "",
      editPermissions: "Admin",
    };
    tags.push(newRow);
    setTags([...tags]);
  }, [tags]);

  const processRowUpdate = (updatedRow, originalRow) => {
    updatedRow.name = updatedRow.name.trim();
    if (updatedRow.name === "") {
      updatedRow.name = originalRow.name;
    }
    const tag = tags.find((tag) => tag.id === updatedRow.id);
    tag.name = updatedRow.name;
    tag.editPermissions = updatedRow.editPermissions;
    return updatedRow;
  };

  // Clicks
  const onDeleteTag = useCallback(
    async (selectedTag) => {
      const confirmMsg = `Are you sure you want to delete the tag: ${selectedTag.name}`;
      const msg = usedTagIds.has(selectedTag.id) ? (
        <div>
          {tagIsUsedMsg}
          <br />
          {confirmMsg}
        </div>
      ) : (
        <div>{confirmMsg}</div>
      );
      confirm.open(msg, () => deleteTag(selectedTag));
    },
    [confirm, deleteTag, usedTagIds]
  );

  const onAddTag = useCallback(() => {
    addTag();
  }, [addTag]);

  const onSave = useCallback(
    async (tags) => {
      tags.forEach(async (tag) => {
        if (tag.name === "") return;
        if (IdUtils.isValidUuid(tag.id)) {
          await TrapezeClient.createTag({
            tagName: tag.name,
            editPermission: tag.editPermissions,
          });
        } else {
          await TrapezeClient.modifyTag({
            tagID: tag.id,
            tagName: tag.name,
            editPermission: tag.editPermissions,
          });
        }
      });
      await showCheckmark();
      await getSetTags();
    },
    [getSetTags, showCheckmark]
  );

  const columns = useMemo(() => {
    return [
      {
        field: "id",
        flex: 1,
        headerName: "Tag ID",
        valueFormatter: (params) =>
          IdUtils.isValidUuid(params.value) ? "N/A" : params.value,
      },
      {
        field: "name",
        flex: 1,
        headerName: "Tag Name",
        editable: true,
        preProcessEditCellProps: async (params) => {
          const error =
            !!tags.find(
              (tag) => tag.name === params.props.value && tag.id !== params.id
            ) || !StringUtils.hasValidChars(params.props.value);
          return { ...params.props, error };
        },
        renderCell: (params) => (
          <Edit>
            <div>{params.value}</div>
          </Edit>
        ),
        renderEditCell: renderEditCell,
      },

      {
        field: "editPermissions",
        flex: 1,
        headerName: "Edit Permissions",
        editable: true,
        type: "singleSelect",
        valueOptions: ["Sysadmin", "Admin", "User"],
        renderCell: (params) => (
          <Edit>
            <div>{params.value}</div>
          </Edit>
        ),
      },
      {
        field: "actions",
        flex: 0.25,
        type: "actions",
        getActions: (params) => [
          <DeleteIcon
            className="red button"
            size={20}
            onClick={() => {
              onDeleteTag(params.row);
            }}
          />,
        ],
      },
    ];
  }, [renderEditCell, onDeleteTag, tags]);

  const getButtons = useCallback(() => {
    return [
      <AddIcon
        className="bg-orange pad-7 button"
        size={50}
        title="Add Tag"
        onClick={() => onAddTag()}
      />,
      <SaveIcon
        className="blue pad-7 button"
        size={50}
        onClick={() => {
          onSave(tags);
        }}
      />,
    ];
  }, [onSave, onAddTag, tags]);

  useEffect(() => {
    const driver = async () => {
      await getSetTags();
    };
    driver();
  }, [getSetTags]);

  useEffect(() => {
    dispatch({ type: "setButtons", buttons: getButtons() });
  }, [getButtons, dispatch]);

  return (
    <div className="pad-5 min-h-175">
      <StandardDataGrid
        className="bg-white box-shadow"
        name="sysadmin-metadata-tags"
        apiRef={apiRef}
        columns={columns}
        rows={[...tags]}
        processRowUpdate={processRowUpdate}
        hideColumnsToolbar
      />
    </div>
  );
};

export default MetadataTags;
