import PropTypes from "prop-types";

const IqLogo = ({ width, height, className, style, invert }) => {
  return (
    <div className={className} style={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 58.327 38.946"
      >
        <g id="Logo1_copy" transform="translate(-60.67 -37.2)">
          <path
            id="Path_34273"
            data-name="Path 34273"
            d="M64.19,156.44h7.574v28.674H64.19Z"
            transform="translate(-3.235 -109.601)"
            fill={invert ? "#000" : "#fff"}
          />
          <path
            id="Path_34274"
            data-name="Path 34274"
            d="M60.67,41.323a4.071,4.071,0,1,1,8.142,0,4.071,4.071,0,1,1-8.142,0Z"
            fill={invert ? "#000" : "#fff"}
          />
          <path
            id="Path_34275"
            data-name="Path 34275"
            d="M168.362,76.476,162.4,69.94a19.5,19.5,0,0,0,4.138-12.326c0-11.2-8.23-19.434-19.89-19.434s-19.89,8.231-19.89,19.434,8.231,19.434,19.891,19.434a20.909,20.909,0,0,0,11.235-3.08l2.3,2.508ZM146.65,70.3c-7.431,0-12.46-5.373-12.46-12.689s5.03-12.632,12.46-12.632,12.46,5.316,12.46,12.632a13.548,13.548,0,0,1-1.734,6.814l-3.91-4.288h-8.285l7.872,8.569A12.965,12.965,0,0,1,146.65,70.3Z"
            transform="translate(-60.747 -0.901)"
            fill="#3ec6ed"
          />
          <path
            id="Path_34276"
            data-name="Path 34276"
            d="M582.5,180.835h-6.7V173.97h-4.048v6.865H564.94v4.048h6.814v7.144H575.8v-7.144h6.7Z"
            transform="translate(-463.505 -125.714)"
            fill={invert ? "#000" : "#fff"}
          />
        </g>
      </svg>
    </div>
  );
};

IqLogo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

IqLogo.defaultProps = {
  invert: false,
};

export default IqLogo;
