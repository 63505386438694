import Request from "./Request";

class TrapezeClient {
  static PATH = "/api/trapeze";
  static async logout() {
    return await Request.post(`${TrapezeClient.PATH}/auth/logout`, {});
  }
  static async getTags() {
    return await Request.get(`${TrapezeClient.PATH}/tags`);
  }
  static async createTag(tag) {
    return await Request.post(`${TrapezeClient.PATH}/tags`, tag);
  }
  static async modifyTag(tag) {
    return await Request.put(`${TrapezeClient.PATH}/tags/${tag.tagID}`, tag);
  }
  static async deleteTag(tagId) {
    return await Request.delete(
      `${TrapezeClient.PATH}/tags/${tagId}?purge=true`
    );
  }
  static async getBatchTags(batchname) {
    return await Request.get(
      `${TrapezeClient.PATH}/workflow/batches/${batchname}/tags`
    );
  }
  static async modifyBatchTag(batchname, tag) {
    return await Request.put(
      `${TrapezeClient.PATH}/workflow/batches/${batchname}/tags/${tag.tagID}`,
      tag
    );
  }
  static async getBatchTableColumns() {
    return (await Request.get(`${TrapezeClient.PATH}/config/batchTable`))
      .columns;
  }
  static async modifyBatchTableColumns(tableConfig) {
    return await Request.put(
      `${TrapezeClient.PATH}/admin/config/batchTable`,
      tableConfig
    );
  }
  static async changePassword(passwords) {
    return await Request.put(`${TrapezeClient.PATH}/auth/password`, passwords, {
      isLogin: true,
    });
  }
  static async getLoanUsageReport(monthsAgo) {
    return await Request.get(
      `${TrapezeClient.PATH}/admin/loanUsage?monthsAgo=${monthsAgo}`
    );
  }
  static async getIthVersion() {
    const ith = await Request.get(
      `/service-html/rest/v1.0/info?mode=TRANSACTIONAL`
    );
    return ith.supportedApiVersions[0];
  }
  static async managerHealth() {
    return await Request.get(`/manager/health`);
  }
  static async unlockServers() {
    return await Request.put(`/manager/servers/unlock`, {});
  }
  static async getServerInfo() {
    return await Request.get(`${TrapezeClient.PATH}/servers`);
  }
}

export default TrapezeClient;
