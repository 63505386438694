import Request from "../Request";

const PATH = "/api/trapeze/admin/config/income";

const IncomeAnalyzerConfigApi = {
  getDefaultConfig: async () => {
    return await Request.get(`${PATH}/default`);
  },
  getCustomConfig: async () => {
    return await Request.get(`${PATH}/custom`);
  },
  postCustomConfig: async (config) => {
    return await Request.post(`${PATH}/custom`, config);
  },
};

export default IncomeAnalyzerConfigApi;
