import { FormControlLabel, Switch } from "@mui/material";
import { useCallback, useContext, useState } from "react";
import ExpandableTable from "../../../components/Tables/ExpandableTable";
import StandardTable from "../../../components/Tables/StandardTable";
import { AppContext } from "../../../Contexts/Contexts";
import TableConfig from "./TableConfig";
import Viewer from "../../../components/Viewers/Viewer";
import BrowserUtils from "../../../utils/browser.utils";
import DomUtils from "../../../utils/dom.utils";
import { isEmpty } from "../../../utils/general.utils";
import IdUtils from "../../../utils/id.utils";

const GlobalsTable = ({ verifications, hidden, loan }) => {
  const { leftPopOut } = useContext(AppContext);
  const [filters, setFilters] = useState(new Set().add("mismatch"));

  //Helper Functions
  const updateFilters = (e, filterValue) => {
    e.target.checked ? filters.add(filterValue) : filters.delete(filterValue);
    setFilters(new Set(filters));
  };
  // Get Functions
  const getFilteredGlobalData = useCallback(
    (verifications) => {
      let filteredData = verifications;
      if (!filters.has("emptyGlobal")) {
        filteredData = verifications?.filter(
          (verification) => !isEmpty(verification.Value)
        );
      }
      return filteredData?.filter((verification) => {
        if (filters.has("mismatch") && verification.NumberOfMismatches > 0)
          return true;
        if (filters.has("warning") && verification.NumberOfWarnings > 0)
          return true;
        if (filters.has("match") && verification.NumberOfMatches > 0)
          return true;
        return false;
      });
    },
    [filters]
  );

  const getFilteredLocalData = useCallback(
    (localData) => {
      return localData.filter(
        (local) =>
          filters.has(local.Result.toLowerCase()) &&
          !(
            local.Result.toLowerCase() === "match" &&
            local.Version.toLowerCase() === "duplicate"
          )
      );
    },
    [filters]
  );

  const onElementSelected = useCallback(
    (element) => {
      const document = loan.documents[element.DocumentId];
      const pages = Object.values(document.pages).map((page) => {
        return {
          ...page,
          src: BrowserUtils.getPageUrl(loan.name, page.fileId),
        };
      });
      const page = document.pages[element.PageId];
      const field = page ? document.fields[element.FieldId] : null;
      leftPopOut.open(
        DomUtils.getOrangeBlackHeader("Document", document.type),
        <Viewer
          images={pages}
          index={page?.number - 1}
          coordinates={field?.coordinates}
          dimensions={{ width: page?.width, height: page?.height }}
          className={"w-half-screen"}
        />
      );
    },
    [loan, leftPopOut]
  );

  const getData = (verifications) => {
    const filteredVerifications = getFilteredGlobalData(verifications);
    filteredVerifications?.map((verification) => {
      return (verification.expand = (
        <StandardTable
          key={IdUtils.generateUuid()}
          onIconClicks={{ view: onElementSelected }}
          tableConfig={TableConfig.locals}
          rowData={getFilteredLocalData(verification.LocalData)}
          containerOffset="550px"
          className="box-shadow"
        />
      ));
    });
    return filteredVerifications;
  };
  return (
    <div className="overflow-hidden w-full h-full flex-col" hidden={hidden}>
      <div className="flex pad-0-5">
        <FormControlLabel
          control={<Switch checked={filters.has("match")} />}
          label="Show Matches"
          onChange={(e) => updateFilters(e, "match")}
        />
        <FormControlLabel
          control={<Switch checked={filters.has("mismatch")} />}
          label="Show Mismatches"
          onChange={(e) => updateFilters(e, "mismatch")}
        />
        <FormControlLabel
          control={<Switch checked={filters.has("warning")} />}
          label="Show Warnings"
          onChange={(e) => updateFilters(e, "warning")}
        />
        <FormControlLabel
          control={<Switch checked={filters.has("emptyGlobal")} />}
          label="Show Empty Globals"
          onChange={(e) => updateFilters(e, "emptyGlobal")}
        />
      </div>
      <ExpandableTable
        tableConfig={TableConfig.globals}
        rowData={getData(verifications)}
        className={"box-shadow pad-5"}
      />
    </div>
  );
};

export default GlobalsTable;
