import { useContext, useState } from "react";
import ClassificationConfigApi from "../../../../api/config-api/classification-config-api";
import ThresholdInput from "../../../../components/Inputs/ThresholdInput";
import { AppContext } from "../../../../Contexts/Contexts";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const AddClassificationRule = ({ sx, defaultRule, documentTypes, refresh }) => {
  const { popup } = useContext(AppContext);
  const [documentTypeName, setDocumentTypeName] = useState("");
  const [threshold, setThreshold] = useState(defaultRule[0].threshold);
  const deleteDoc = false;

  const AutoCompleteContainer = () => {
    return (
      <div className="autocomplete" style={{ padding: "20px 10px 0px 10px" }}>
        <Autocomplete
          sx={{
            "& .css-2iz2x6": {
              top: "calc(50% - 20px)",
            },
            "&  .MuiAutocomplete-endAdornment": {
              marginTop: "-2px",
            },
          }}
          options={documentTypes}
          autoComplete
          autoHighlight
          renderInput={(params) => (
            <TextField
              {...params}
              label="Document Types"
              placeholder="Search Document Types..."
              InputLabelProps={{ shrink: true }}
            />
          )}
          onChange={(e) => {
            setDocumentTypeName(e.target.innerText);
          }}
        />
      </div>
    );
  };

  const InputContainer = () => {
    return (
      <ThresholdInput
        style={{ margin: "auto" }}
        threshold={threshold}
        setThreshold={setThreshold}
      />
    );
  };
  const ButtonsContainer = () => {
    return (
      <div className="buttons-container">
        <button
          className="pill-button"
          onClick={async (e) => {
            e.preventDefault();
            await ClassificationConfigApi.addClassificationRule({
              documentTypeName: documentTypeName,
              threshold: threshold,
              deleteDoc: deleteDoc,
            });
            popup.close();
            refresh();
          }}
        >
          Add
        </button>
      </div>
    );
  };
  return (
    <div className="popup-content" style={sx}>
      {AutoCompleteContainer()}
      {InputContainer()}
      {ButtonsContainer()}
    </div>
  );
};

export default AddClassificationRule;
