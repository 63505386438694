import Request from "../Request";

const PATH = "/api/trapeze/admin/config/versioning";

const VersioningConfigApi = {
  getVersioningRules: async (documentTypeName = "") => {
    return await Request.get(`${PATH}/documentTypes/${documentTypeName}`);
  },
  modifyVersioningRule: async (documentTypeName, data) => {
    return await Request.put(`${PATH}/documentTypes/${documentTypeName}`, data);
  },
  addVersioningRule: async (data) => {
    return await Request.post(`${PATH}/documentTypes/`, data);
  },
  deleteVersioningRule: async (documentTypeName) => {
    return await Request.delete(`${PATH}/documentTypes/${documentTypeName}`);
  },
};

export default VersioningConfigApi;
