import { useState, useCallback, useEffect, useContext, useMemo } from "react";
import ClassificationPanel from "./Panels/classification.panel";
import ClassificationApi from "../../api/classification-api";
import { AppContext, ServiceContext } from "../../Contexts/Contexts";
import SaveIcon from "../../components/Icons/SaveIcon";
import ApproveIcon from "../../components/Icons/ApproveIcon";
import { useLocation, useNavigate } from "react-router-dom";
import BrowserUtils from "../../utils/browser.utils";
import DomUtils from "../../utils/dom.utils";
import { copy } from "../../utils/general.utils";
import IdUtils from "../../utils/id.utils";
import { useQuery } from "@tanstack/react-query";

const ClassificationService = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useMemo(
    () => BrowserUtils.getServiceParams(location.search),
    [location.search]
  );
  const { confirm, alert, blocker, onUnload, loader, showCheckmark, user } =
    useContext(AppContext);
  const { dispatch, state } = useContext(ServiceContext);
  const [documents, setDocuments] = useState([]);
  const [firstRun, setFirstRun] = useState(true);

  // getSetters
  const getSetDocuments = useCallback(async (loanName) => {
    const documents = await ClassificationApi.getDocs(loanName);
    documents.forEach((doc, index) => {
      doc._id = IdUtils.generateUuid();
      doc.index = index;
      doc.threshold = doc.pageList[0].confThresh;
      doc.confidence = doc.docConf;
      const warning = doc.confidence < doc.threshold ? "! " : "";
      doc.type = doc.docType;
      doc.explorer = {
        type: "document",
        name: `(${index + 1}) ${warning} ${doc.docType}`,
      };
      doc.pages = doc.pageList;
      doc.pages.forEach(
        (page) => (page.src = BrowserUtils.getPageUrl(loanName, page.fileId))
      );
      delete doc.docType;
      delete doc.docConf;
      delete doc.pageList;
    });
    return documents;
  }, []);

  // actions
  const save = useCallback(async () => {
    const docs = copy(documents);
    docs.forEach((doc) => {
      doc.pages.forEach((page, idx) => {
        page.pageNumber = idx + 1;
        page.split = idx === 0;
        delete page.src;
      });
      doc.pageList = doc.pages;
      doc.docConf = doc.confidence;
      doc.docType = doc.type;
      delete doc.pages;
      delete doc.confidence;
      delete doc.type;
      delete doc._id;
      delete doc.index;
      delete doc.threshold;
      delete doc.explorer;
    });
    await ClassificationApi.saveBatch(params.id, docs);
    await showCheckmark();
  }, [documents, showCheckmark, params]);

  const approve = useCallback(async () => {
    loader.open();
    const pages = [];
    documents.forEach((doc) => {
      doc.pages.forEach((page, idx) => {
        delete page.src;
        page.docType = doc.type;
        page.docConf = doc.confidence;
        page.pageNumber = idx + 1;
        page.split = idx === 0;
        pages.push(page);
      });
    });
    try {
      await ClassificationApi.approveBatch(params.id, pages);
    } finally {
      loader.close();
    }
    onUnload.clear();
    await showCheckmark();
    if (params.isStandalone) {
      blocker.open(
        <div>
          Batch Approved
          <br />
          You can safely close the window.
        </div>
      );
    } else {
      navigate(params.dashboardPath);
    }
  }, [documents, onUnload, showCheckmark, loader, params, navigate, blocker]);

  // onClicks
  const onSave = useCallback(() => {
    save();
  }, [save]);

  const onApprove = useCallback(() => {
    confirm.open(
      <div>
        Are you sure you want to approve?
        <br />
        This action cannot be undone.
      </div>,
      () => approve()
    );
  }, [confirm, approve]);

  const Buttons = useMemo(
    () => [
      <SaveIcon size={50} className="pad-10 button" onClick={onSave} />,
      <ApproveIcon
        size={50}
        className="pad-10 button bg-orange"
        onClick={onApprove}
      />,
    ],
    [onSave, onApprove]
  );

  const hotkeys = useCallback(
    (e) => {
      if (DomUtils.hasPopup()) return;
      if (e.ctrlKey) {
        switch (e.key) {
          case "s":
            onSave();
            break;
          case "Enter":
            onApprove();
            break;
          default:
            break;
        }
      }
    },
    [onSave, onApprove]
  );

  const { data: assignedTo } = useQuery({
    queryKey: ["getClassificationBatch", params.id],
    queryFn: async () =>
      (await ClassificationApi.getBatch(params.id)).assignedTo,
    refetchInterval: 60000,
  });

  useEffect(() => {
    const driver = async () => {
      loader.open(
        <div className="bg-white pad-10 br-5">Reserving Batch...</div>
      );
      let loan;
      try {
        loan = await ClassificationApi.getBatch(params.id);
      } catch (error) {
        console.error(error);
        const msg = (
          <div>The batch is not currently in classification review.</div>
        );
        if (params.isStandalone) {
          blocker.open(msg);
        } else {
          alert.open(msg, () => navigate(params.dashboardPath));
        }
        loader.close();
        return;
      }
      try {
        await ClassificationApi.reserveBatch(params.id);
      } catch (error) {
        console.error(error);
        const msg = <div>The batch could not be reserved.</div>;
        if (params.isStandalone) {
          blocker.open(msg);
        } else {
          alert.open(msg, () => navigate(params.dashboardPath));
        }
        loader.close();
        return;
      }
      onUnload.set(
        () => ClassificationApi.releaseBatch(params.id),
        location.pathname
      );
      dispatch({ type: "setLoan", reserved: loan });
      loader.open(
        <div className="bg-white pad-10 br-5">Getting Documents...</div>
      );
      const documents = await getSetDocuments(params.id);
      setDocuments(documents);
      loader.close();
    };
    if (firstRun) {
      setFirstRun(false);
      console.log("Classification Driver");
      driver();
    }
  }, [
    getSetDocuments,
    alert,
    params,
    navigate,
    blocker,
    firstRun,
    onUnload,
    dispatch,
    loader,
    location,
  ]);

  useEffect(() => {
    if (!assignedTo || !user) return;
    if (assignedTo !== "unassigned" && assignedTo !== user.Username) {
      onUnload.clear();
      const msg = <div>The batch has been reserved by another user.</div>;
      if (params.isStandalone) {
        blocker.open(msg);
      } else {
        alert.open(msg, () => navigate(params.dashboardPath));
      }
    }
  }, [assignedTo, user, alert, blocker, navigate, params, onUnload]);

  useEffect(() => {
    dispatch({ type: "setButtons", buttons: Buttons });
  }, [dispatch, Buttons]);

  useEffect(() => {
    dispatch({ type: "setRepo", repo: documents });
  }, [dispatch, documents]);

  useEffect(() => {
    window.addEventListener("keydown", hotkeys);
    return () => window.removeEventListener("keydown", hotkeys);
  }, [hotkeys]);

  return (
    <ClassificationPanel
      documents={documents}
      setDocuments={setDocuments}
      index={state.item?.index}
      onClick={(document) => {
        if (state.item !== document) {
          dispatch({ type: "setItem", item: document });
        }
      }}
    />
  );
};

export default ClassificationService;
