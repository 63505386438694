import Page from "./Page";
import Split from "./Split";
import DropZone from "./DropZone";
import { Autocomplete, TextField } from "@mui/material";
import { useContext, useCallback, useEffect, useRef, useMemo } from "react";
import { AppContext } from "../../Contexts/Contexts";
import textFieldProps from "../../store/textfield.props";
import Confidence from "../Fields/Confidence";
import Viewer from "../Viewers/Viewer";
import DomUtils from "../../utils/dom.utils";
import DocumentDragger from "./DocumentDragger";

const splitWidth = 15;

const Document = ({
  _this,
  onPageDrop,
  onSplit,
  onClassify,
  onClick,
  disabled,
}) => {
  const { documentTypes, leftPopOut } = useContext(AppContext);
  const autocompleteRef = useRef(null);
  const pages = useMemo(() => {
    return _this.pages.map((page, index) => ({
      ...page,
      type: "page",
      index,
      documentIndex: _this.index,
    }));
  }, [_this]);

  const backgroundColor =
    _this.threshold > _this.confidence
      ? "var(--tertiary-bg-color-2)"
      : "var(--secondary-bg-color)";

  const hotkeys = useCallback(
    (e) => {
      if (DomUtils.hasPopup()) return;
      if (e.ctrlKey) {
        switch (e.key) {
          case " ": // space
            autocompleteRef.current.blur();
            if (!disabled) {
              autocompleteRef.current.focus();
            }
            return;
          default:
            return;
        }
      }
    },
    [disabled]
  );

  useEffect(() => {
    window.addEventListener("keydown", hotkeys);
    return () => {
      window.removeEventListener("keydown", hotkeys);
    };
  }, [hotkeys]);

  return (
    <div className="box-shadow overflow-hidden s-full">
      <DocumentDragger
        _this={pages}
        className="w-full"
        style={{ height: "15px", backgroundColor }}
      />
      <div
        className="bg-lg br-5 pad-5-10 serif bold flex align-center box-shadow-c absolute"
        style={{ top: "5px", left: "5px" }}
      >
        {_this.index + 1}
      </div>
      <div className=" bg-white pad-10 flex-col gap-5 s-full" onClick={onClick}>
        <div />
        <div className="flex-center gap-10">
          <Autocomplete
            className="box-shadow-c"
            size="small"
            openOnFocus
            options={documentTypes}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label="Document Type"
                inputRef={(input) => (autocompleteRef.current = input)}
                InputLabelProps={{
                  style: {
                    color: "black",
                    fontWeight: "bold",
                    fontFamily: "var(--true-font-family-sans-serif)",
                    fontSize: "18px",
                  },
                  shrink: true,
                }}
                inputProps={{
                  ...params.inputProps,
                  style: {
                    fontFamily: "var(--true-font-family-serif)",
                    WebkitTextFillColor: "black",
                  },
                }}
              />
            )}
            autoComplete
            autoHighlight
            style={{ ...textFieldProps, width: "315px" }}
            value={_this.type}
            onChange={(e, value) => onClassify(_this, value)}
            disabled={disabled}
            onFocus={() => autocompleteRef.current.select()}
          />
          <Confidence
            confidence={_this.confidence}
            threshold={_this.threshold}
            className={"bg-lg box-shadow-c"}
          />
        </div>
        <div className="flex-wrap">
          {pages.map((page, index) => (
            <div className="flex" key={index}>
              <Page
                _this={page}
                onDrop={onPageDrop}
                onClick={() => {
                  leftPopOut.open(
                    DomUtils.getOrangeBlackHeader(
                      _this.type,
                      `Pages: ${pages.length}`
                    ),
                    <Viewer
                      images={pages}
                      index={index}
                      className={"w-half-screen"}
                    />
                  );
                }}
              />
              {index < pages.length - 1 ? (
                <Split
                  onClick={onSplit}
                  _this={{
                    pageIndex: index,
                    documentIndex: _this.index,
                  }}
                />
              ) : (
                <div style={{ width: `${splitWidth}px` }} />
              )}
            </div>
          ))}
          <DropZone
            style={{
              flexGrow: "1",
              margin: "5px 0",
            }}
            onDrop={onPageDrop}
            item={{
              type: "page",
              documentIndex: _this.index,
              index: pages.length - 1,
              position: "right",
            }}
          />
        </div>
        <DropZone
          style={{
            flexGrow: "1",
            margin: "5px 0",
            width: "100%",
          }}
          onDrop={onPageDrop}
          item={{
            type: "page",
            documentIndex: _this.index,
            index: pages.length - 1,
            position: "right",
          }}
        />
      </div>
    </div>
  );
};

Document.defaultProps = {
  _this: {
    pages: [],
  },
  onPageDrop: () => {},
  onSplit: () => {},
  onClassify: () => {},
  disabled: false,
};

export default Document;
