const ThresholdInput = ({ disabled, style, threshold, setThreshold }) => {
  return (
    <div className="integer-input" style={style}>
      <label style={{ textAlign: "right" }}>Threshold:</label>
      <input
        disabled={disabled}
        type="number"
        min="0"
        max="100"
        value={threshold}
        onChange={(e) => setThreshold(e.target.value)}
      ></input>
    </div>
  );
};

ThresholdInput.defaultProps = {
  disabled: false,
};
export default ThresholdInput;
