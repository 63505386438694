import DateTimeUtils from "../../../utils/datetime.utils";

const BatchTableConfig = [
  {
    id: "label",
    label: "Batch Name",
    type: "string",
    action: "view",
  },
  {
    id: "stage",
    label: "Stage",
    type: "string",
    action: "edit",
  },
  {
    id: "status",
    label: "Status",
    type: "string",
  },
  {
    id: "priority",
    label: "Priority",
    type: "string",
  },
  {
    id: "scannedDate",
    label: "Scanned Date",
    type: "date",
    function: (date) => DateTimeUtils.format(date),
  },
  {
    id: "modifiedDate",
    label: "Modified Date",
    type: "date",
    function: (date) => DateTimeUtils.format(date),
  },
  {
    id: "errorMessage",
    label: "Error",
    type: "string",
  },
];

export default BatchTableConfig;
