import { FaPlus } from "react-icons/fa";

const SquareAddButton = ({ text, onClick, hidden, sx }) => {
  return (
    <button
      className="square-add-button"
      onClick={onClick}
      hidden={hidden}
      style={sx}
    >
      <FaPlus size={10} style={{ marginRight: "10px" }} />
      {text}
    </button>
  );
};

SquareAddButton.defaultProps = {
  hidden: false,
};

export default SquareAddButton;
