import { useCallback, useContext, useMemo } from "react";
import AddIcon from "../../components/Icons/AddIcon";
import { AppContext } from "../../Contexts/Contexts";
import BatchIcon from "../../components/Icons/BatchIcon";
import StandardDataGrid from "../../components/DataGrids/StandardDataGrid";
import Upload from "../../pages/Dashboard/components/Upload";
import BrowserUtils from "../../utils/browser.utils";
import ClassificationApi from "../../api/classification-api";
import DomUtils from "../../utils/dom.utils";
import LinkedCell from "../../components/Cells/LinkedCell";
import VerifAiApi from "../../api/verifai-api";
import { getIdFromTrapezeId } from "../../store/services";
import { useNavigate } from "react-router";
import DocumentIcon from "../../components/Icons/DocumentIcon";
import BatchMetadata from "../../components/Panels/BatchMetadata";
import TagIcon from "../../components/Icons/TagIcon";
import BatchInfo from "../../components/Panels/BatchInfo";
import DocumentTable from "../../pages/Dashboard/components/DocumentTable";

const validationStages = new Set([
  "ClassificationQueue",
  "VersionQueue",
  "Final",
  "Validation",
  "Services",
  "DataVerification",
]);
const validationStatuses = new Set(["updated", "processing", "migrated"]);

const canReserveBatch = (stage, status, assignedTo, user) => {
  if (
    !user ||
    !validationStages.has(stage) ||
    !validationStatuses.has(status) ||
    assignedTo.toLowerCase() === "system" ||
    (stage === "DataVerification" && status === "processing")
  ) {
    return false;
  }

  if (
    status.toLowerCase() === "updated" ||
    status.toLowerCase() === "migrated" ||
    stage.toLowerCase() !== "classificationqueue" ||
    user.IsAdministrator
  ) {
    return true;
  }

  // Reserved by a user in a Classification Queue
  return assignedTo.toLowerCase() === user.Username.toLowerCase();
};

const Dashboard = ({ batches: _batches, tableConfig: _tableConfig }) => {
  const navigate = useNavigate();
  const { leftPopOut, user, confirm } = useContext(AppContext);

  const initialState = {
    sorting: {
      sortModel: [{ field: "scannedDate", sort: "desc" }],
    },
  };

  const onBatchMetadataSelected = useCallback(
    (batch) => {
      leftPopOut.open(
        DomUtils.getOrangeBlackHeader("Batch", "Information"),
        <BatchMetadata loan={batch} />
      );
    },
    [leftPopOut]
  );

  const onBatchSelected = useCallback(
    (batch) => {
      leftPopOut.open(
        DomUtils.getOrangeBlackHeader("Batch:", `${batch.name}`),
        <div className="flex-col gap-5 s-ful">
          <BatchInfo batchName={batch.name} />
          <DocumentTable batch={batch} />
        </div>
      );
    },
    [leftPopOut]
  );

  const stageSelected = useCallback(
    async (row) => {
      const { stage, name } = row;
      const id =
        stage.toLowerCase() === "dataverification"
          ? (await VerifAiApi.getLoanFromBatchName(name))._id
          : name;
      const serviceName = getIdFromTrapezeId(stage);
      const url = BrowserUtils.getServiceUrl(id, serviceName);
      navigate(url);
    },
    [navigate]
  );

  const onStageSelected = useCallback(
    async (row) => {
      const stage = row.stage.toLowerCase();
      const status = row.status.toLowerCase();
      const assignedTo = row.assignedTo;

      if (
        stage === "classificationqueue" &&
        status === "processing" &&
        assignedTo !== user.Username &&
        user.IsAdministrator
      ) {
        const message = `This batch is assigned to user ${assignedTo} who may be actively working on it. Are you sure you want to reassign it to yourself?`;
        confirm.open(<div>{message}</div>, async () => {
          await ClassificationApi.releaseBatch(row.name);
          stageSelected(row);
        });
      } else {
        stageSelected(row);
      }
    },
    [user, confirm, stageSelected]
  );

  const tableConfig = useMemo(() => {
    const stageColumn = _tableConfig.find((column) => column.field === "stage");
    stageColumn.valueGetter = (params) => {
      const { stage } = params.row;
      const value = stage === "DataVerification" ? "Services" : stage;
      return value;
    };
    stageColumn.renderCell = (params) => {
      const { stage, status, assignedTo } = params.row;
      const value = stage === "DataVerification" ? "Services" : stage;
      return (
        <LinkedCell
          data={value}
          disabled={!canReserveBatch(stage, status, assignedTo, user)}
          onClick={() => {
            onStageSelected(params.row);
          }}
        />
      );
    };

    const actionsColumn = {
      field: "Actions",
      type: "actions",
      width: 65,
      minWidth: 65,
      getActions: (params) => {
        const iconSize = 22;
        const className = "box-shadow-c br-10 pad-2 button";
        return [
          <DocumentIcon
            className={`${className} bg-green`}
            size={iconSize}
            title="Document Table"
            onClick={() => onBatchSelected(params.row)}
          />,
          <TagIcon
            className={`${className} bg-blue`}
            size={iconSize}
            title="Tags"
            onClick={() => onBatchMetadataSelected(params.row)}
          />,
        ];
      },
    };
    const tableConfig = [actionsColumn, ..._tableConfig];
    return tableConfig;
  }, [
    onBatchSelected,
    onStageSelected,
    onBatchMetadataSelected,
    user,
    _tableConfig,
  ]);

  const batches = useMemo(() => {
    for (const batch of _batches) {
      if (batch.batchTags) {
        for (const tag of batch.batchTags) {
          batch[tag.tagID] = tag.tagValue;
        }
      }
    }
    return _batches;
  }, [_batches]);

  return (
    <div className="pad-10 s-full">
      <div className="pad-5 s-full box-shadow flex-col bg-white border-top-blue gap-5">
        <div className="flex gap-5">
          <div className="flex w-full bg-green pad-5-10 align-center gap-5 h-50">
            <BatchIcon size={30} />
            <h1 className="font-24">Batches</h1>
          </div>
          <div>
            <AddIcon
              title="Add Batch"
              className="button bg-orange s-full pad-10"
              onClick={() =>
                leftPopOut.open(
                  DomUtils.getOrangeBlackHeader("Add", "Batch"),
                  <Upload batches={_batches} />
                )
              }
            />
          </div>
        </div>
        <StandardDataGrid
          columns={tableConfig ?? _tableConfig}
          initialState={initialState}
          name="dashboard"
          rows={batches}
        />
      </div>
    </div>
  );
};

export default Dashboard;
