import { Autocomplete, TextField } from "@mui/material";
import { memo } from "react";
import DomUtils from "../../../utils/dom.utils";

const AutocompleteField = memo(
  ({ field, options, onChange, onFocus, width }) => {
    // console.log("autocomplete field");

    return (
      <div style={{ position: "relative" }}>
        <Autocomplete
          size="small"
          options={options}
          sx={{ margin: "5px", width: width }}
          autoComplete
          autoHighlight
          openOnFocus
          value={field.data ?? field.value ?? ""}
          onChange={(e, value) => onChange(value, field)}
          onOpen={() => onFocus(field)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label={field.config.label}
              InputLabelProps={{
                shrink: true,
                style: { color: "#4A4A4A", fontSize: "16px" },
              }}
              sx={{
                "& .MuiInputBase-input": {
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
              }}
              InputProps={{
                ...params.InputProps,
                style: {
                  fontSize: "14px",
                  color: "black",
                  backgroundColor: "white",
                },
              }}
              inputRef={(input) => (field.ref = input)}
              // inputProps={{ ...params.inputProps, title: fieldConfig.displayName }}
            />
          )}
        />
        {DomUtils.getValidatedIcon(field)}
      </div>
    );
  }
);

AutocompleteField.defaultProps = {
  field: {},
};

export default AutocompleteField;
