const StandardDivider = ({ className }) => {
  return (
    <hr
      className={className}
      style={{
        height: "1px",
        border: "none",
      }}
    />
  );
};
export default StandardDivider;
