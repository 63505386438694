import { CgClose } from "react-icons/cg";

const SearchTag = ({ col, label, query, onClose }) => {
  return (
    <div className="search-tag-container">
      <div>{`${label}: ${query.operator}${query.value}`}</div>
      <div
        style={{ cursor: "pointer", verticalAlign: "middle" }}
        onClick={() => onClose(col)}
      >
        <CgClose />
      </div>
    </div>
  );
};

export default SearchTag;
