import { MenuItem, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import SearchBar from "../SearchBars/SearchBar";
import StandardTable from "./StandardTable";
import { DesktopDatePicker as DatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import SearchTag from "../Tags/SearchTag";
import Search from "../../utils/search";
import TableMetadata from "../Footers/TableMetadata";
import RectangleButton from "../Buttons/RectangleButton";
import BrowserUtils from "../../utils/browser.utils";
import { copy } from "../../utils/general.utils";

const componentSX = {
  margin: "10px 5px",
  width: "150px",
  backgroundColor: "white",
};
const searchBarSX = {
  margin: "10px 5px",
  width: "300px",
  backgroundColor: "white",
};

const AdvancedSearchTable = ({
  tableConfig,
  data,
  onRowClick,
  highlightRow,
  rowButton,
  containerOffset,
  dateSearch,
  onIconClicks,
  type,
  showFooter,
  metadata,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [colFilter, setColFilter] = useState("all");
  const [regexFilter, setRegexFilter] = useState("~");
  const [dateFilter, setDateFilter] = useState("none");
  const [startDate, setStartDate] = useState(new Date("01/01/2020"));
  const [endDate, setEndDate] = useState(new Date());
  const [tags, setTags] = useState({});
  const [filteredData, setFilteredData] = useState([]);

  const getColFilterLabel = (col) => {
    if (col === "all") return "All";
    return tableConfig.find((cell) => cell.id === col).label;
  };

  // actions
  const search = useCallback(
    (tags, data, tableConfig, startDate, endDate, dateFilter, dateSearch) => {
      const filteredData = dateSearch
        ? [Search.date(startDate, endDate, data, tableConfig, dateFilter)]
        : [data];
      for (const colFilter in tags) {
        let type = tableConfig.find((cell) => cell.id === colFilter)?.type;
        type = type ? type : "string";
        switch (type) {
          case "string":
            filteredData.push(
              Search.string(tags[colFilter], data, tableConfig, colFilter)
            );
            break;
          case "number":
            filteredData.push(
              Search.number(tags[colFilter], data, tableConfig, colFilter)
            );
            break;
          default:
            break;
        }
      }
      return filteredData.reduce((a, b) => a.filter((c) => b.includes(c)));
    },
    []
  );

  const addTag = (col, op, query) => {
    setTags((tags) => {
      tags[col] = {
        operator: op,
        value: query,
      };
      BrowserUtils.localStorage.set(`${type}-tags`, tags);
      return copy(tags);
    });
  };

  const removeTag = (col) => {
    setTags((tags) => {
      delete tags[col];
      BrowserUtils.localStorage.set(`${type}-tags`, tags);
      return copy(tags);
    });
  };

  const clearDateFilter = () => {
    onStartDateChange(new Date("01/01/2020"));
    onEndDateChange(new Date());
  };

  // clicks
  const onSearch = (e) => {
    if (e.key === "Enter") {
      setSearchValue("");
      const query = e.target.value.trim().toLowerCase();
      if (query === "") return;
      // jumpToFirstPageOfTable();
      addTag(colFilter, regexFilter, query);
    }
  };

  const onStartDateChange = (date) => {
    setStartDate(date);
    BrowserUtils.localStorage.set(`${type}-startDate`, date);
  };

  const onEndDateChange = (date) => {
    setEndDate(date);
    BrowserUtils.localStorage.set(`${type}-endDate`, date);
  };

  const onDateFilterChange = (dateFilterType) => {
    setDateFilter(dateFilterType);
    BrowserUtils.localStorage.set(`${type}-dateFilterType`, dateFilterType);
    if (dateFilterType === "none") clearDateFilter();
  };

  const onClearFilters = () => {
    setTags({});
    BrowserUtils.localStorage.delete(`${type}-tags`);
    onDateFilterChange("none");
  };

  // useEffects
  useEffect(() => {
    setFilteredData(
      search(
        tags,
        data,
        tableConfig,
        startDate,
        endDate,
        dateFilter,
        dateSearch
      )
    );
  }, [
    search,
    tags,
    data,
    tableConfig,
    startDate,
    endDate,
    dateFilter,
    dateSearch,
  ]);

  useEffect(() => {
    if (type) {
      const tags = BrowserUtils.localStorage.get(`${type}-tags`);
      const dateFilterType = BrowserUtils.localStorage.get(
        `${type}-dateFilterType`
      );
      const startDate = BrowserUtils.localStorage.get(`${type}-startDate`);
      const endDate = BrowserUtils.localStorage.get(`${type}-endDate`);
      if (tags) setTags(tags);
      if (dateFilterType) setDateFilter(dateFilterType);
      if (startDate) setStartDate(new Date(startDate));
      if (endDate) setEndDate(new Date(endDate));
    }
  }, [type]);

  return (
    <>
      <div className="w-full pad-5 bg-lg flex-col">
        <div className="flex">
          <div>
            <TextField
              select
              size="small"
              label="Column Filter"
              value={colFilter}
              onChange={(e) => setColFilter(e.target.value)}
              sx={componentSX}
            >
              <MenuItem value="all">All</MenuItem>
              {tableConfig.map(
                (cell, idx) =>
                  cell.type !== "date" && (
                    <MenuItem key={idx} value={cell.id}>
                      {cell.label}
                    </MenuItem>
                  )
              )}
            </TextField>
            <TextField
              select
              size="small"
              label="Operator"
              value={regexFilter}
              onChange={(e) => setRegexFilter(e.target.value)}
              sx={componentSX}
            >
              <MenuItem value="~">Contains</MenuItem>
              <MenuItem value="=">Equals</MenuItem>
            </TextField>
            <SearchBar
              placeholder={`Search...`}
              sx={searchBarSX}
              value={searchValue}
              onChange={setSearchValue}
              onKeyDown={onSearch}
            />
          </div>
          {dateSearch && (
            <div className="date-container">
              <TextField
                select
                size="small"
                label="Date Filter"
                value={dateFilter}
                onChange={(e) => {
                  onDateFilterChange(e.target.value);
                }}
                sx={componentSX}
              >
                <MenuItem value="none">None</MenuItem>
                {tableConfig.map(
                  (cell, idx) =>
                    cell.type === "date" && (
                      <MenuItem key={idx} value={cell.id}>
                        {cell.label}
                      </MenuItem>
                    )
                )}
              </TextField>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  sx={componentSX}
                  label="Start Date"
                  format="MM/dd/yy"
                  value={startDate}
                  onChange={onStartDateChange}
                  disabled={dateFilter === "none"}
                  slotProps={{
                    actionBar: {
                      actions: ["today"],
                    },
                  }}
                />
                <DatePicker
                  sx={componentSX}
                  label="End Date"
                  format="MM/dd/yy"
                  value={endDate}
                  onChange={onEndDateChange}
                  disabled={dateFilter === "none"}
                  slotProps={{
                    actionBar: {
                      actions: ["today"],
                    },
                  }}
                />
              </LocalizationProvider>
            </div>
          )}
        </div>
        <div className="tags-container">
          <RectangleButton
            className="primary-color-scheme"
            element={<div>Clear Filters</div>}
            sx={{ margin: "1px" }}
            onClick={() => onClearFilters()}
          ></RectangleButton>
          {Object.keys(tags).map((col, idx) => (
            <SearchTag
              key={idx}
              col={col}
              label={getColFilterLabel(col)}
              query={tags[col]}
              onClose={(col) => removeTag(col)}
            />
          ))}
        </div>
      </div>
      <StandardTable
        tableConfig={tableConfig}
        rowData={filteredData}
        originalData={data}
        onRowClick={onRowClick}
        highlightRow={highlightRow}
        rowButton={rowButton}
        containerOffset={containerOffset}
        onIconClicks={onIconClicks}
        showFooter={showFooter}
        type={type}
      />
      {metadata.length > 0 && (
        <TableMetadata
          metadata={metadata}
          filteredData={filteredData}
          originalData={data}
        />
      )}
    </>
  );
};

AdvancedSearchTable.defaultProps = {
  onRowClick: () => {},
  onIconClicks: {},
  highlightRow: null,
  dateSearch: false,
  type: null,
  showFooter: false,
  metadata: [],
  tableConfig: [],
};

export default AdvancedSearchTable;
