import { useState, useEffect, useContext, useCallback } from "react";
import ClassificationConfigApi from "../../../api/config-api/classification-config-api";
import SearchTable from "../../../components/Tables/SearchTable";
import { AppContext } from "../../../Contexts/Contexts";
import ModifyClassificationRule from "./components/ModifyClassificationRule";
import AddClassificationRule from "./components/AddClassificationRule";
import { HiOutlineRefresh } from "react-icons/hi";
import SquareAddButton from "../../../components/Buttons/SquareAddButton";
import DefaultRuleTable from "../../../components/Tables/DefaultRuleTable";
import { FiEdit } from "react-icons/fi";
import TableConfig from "../config/TableConfig";

const ClassificationConfig = () => {
  const { documentTypes, popup } = useContext(AppContext);
  const [rules, setRules] = useState([]);
  const [defaultRule, setDefaultRule] = useState([]);

  //Helper functions
  const getEditDocTypeName = (e) => {
    return e.target.parentNode.firstChild.innerText;
  };

  const filteredDocTypes = (documentTypes, rules) => {
    return documentTypes.filter((row) => {
      return !rules
        .map((row) => row.documentTypeName.toLowerCase())
        .includes(row.toLowerCase());
    });
  };

  //Get-setters
  const getSetClassificationRules = useCallback(async () => {
    const rules = await ClassificationConfigApi.getClassificationRules();
    setDefaultRule(
      rules.ClassificationRules.filter((row) => {
        return row.documentTypeName.toLowerCase() === "default";
      })
    );
    setRules(
      rules.ClassificationRules.filter((row) => {
        return row.documentTypeName.toLowerCase() !== "default";
      })
    );
    return rules;
  }, []);

  //Actions
  const openModify = (rule) => {
    popup.open(
      `Edit Rule`,
      <ModifyClassificationRule
        sx={{
          width: "400px",
          height: "300px",
        }}
        currentRule={rule}
        refresh={() => getSetClassificationRules()}
      />
    );
  };

  const openAdd = (documentTypes, rules) => {
    return popup.open(
      "Add Rule",
      <AddClassificationRule
        sx={{
          width: "400px",
          height: "300px",
        }}
        documentTypes={filteredDocTypes(documentTypes, rules)}
        defaultRule={defaultRule}
        refresh={() => getSetClassificationRules()}
      />
    );
  };
  //Use-effects
  //driver
  useEffect(() => {
    getSetClassificationRules();
  }, [getSetClassificationRules]);

  //Sub-components
  const DefaultTable = () => {
    return (
      <div>
        <h1 className="table-header">Default Classification Rule</h1>
        <DefaultRuleTable
          tableConfig={TableConfig.classificationConfig}
          rowData={defaultRule}
          onRowClick={(e) => openModify(defaultRule)}
          rowButton={RowButton}
        />
      </div>
    );
  };

  const RulesTable = () => {
    return (
      <>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "10fr 2fr 1fr",
          }}
        >
          <h1 className="table-header">Classification Rules</h1>
          {AddRuleButton()}
          {RefreshButton()}
        </div>
        <SearchTable
          placeholder="Search Document Type..."
          tableConfig={TableConfig.classificationConfig}
          rowData={rules}
          onRowClick={(e) =>
            openModify(
              rules.filter((row) => {
                return row.documentTypeName === getEditDocTypeName(e);
              })
            )
          }
          rowButton={RowButton}
        />
      </>
    );
  };

  const AddRuleButton = () => {
    return (
      <SquareAddButton
        text="Add Rule"
        onClick={() => openAdd(documentTypes, rules)}
      />
    );
  };

  const RefreshButton = () => {
    return (
      <div
        className="refresh-icon"
        style={{ marginRight: "0", marginBottom: "0", gridColumn: "3" }}
      >
        <HiOutlineRefresh
          size={20}
          onClick={() => getSetClassificationRules()}
        />
      </div>
    );
  };

  const RowButton = (style) => {
    return (
      <FiEdit
        className="edit-button"
        style={style}
        onClick={(e) => openModify(e)}
      />
    );
  };

  return (
    <div
      className="config-content"
      style={{ width: "600px", marginTop: "50px" }}
    >
      <h1 className="config-header" style={{ marginBottom: "20px" }}>
        Classification Configuration Rules
      </h1>
      {DefaultTable()}
      {RulesTable()}
    </div>
  );
};

export default ClassificationConfig;
