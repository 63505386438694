import Request from "./Request";

const AdminApi = {
  fetchUserDetails: async (username) => {
    return await Request.get(`/api/trapeze/admin/users/${username}`);
  },
  fetchUser: async () => {
    return await Request.get("/api/trapeze/auth/user");
  },
  completeBatch: async (batchName) => {
    return await Request.put(
      `/api/trapeze/approved/batches/${batchName}/complete`,
      {}
    );
  },
};

export default AdminApi;
