const TableConfig = {
  batch: [
    {
      field: "name",
      headerName: "Batch Name",
      type: "string",
      hidden: false,
    },
    {
      field: "stage",
      headerName: "Stage",
      type: "string",
      hidden: false,
    },
    {
      field: "status",
      headerName: "Status",
      type: "string",
      hidden: false,
    },
    {
      field: "priority",
      headerName: "Priority",
      type: "string",
      hidden: false,
    },
    {
      field: "assignedTo",
      headerName: "Assigned To",
      type: "string",
      hidden: false,
    },
    {
      field: "scannedDate",
      headerName: "Scanned Date",
      type: "date",
      hidden: false,
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      type: "date",
      hidden: false,
    },
    {
      field: "errorMessage",
      headerName: "Error",
      type: "string",
      hidden: true,
    },
  ],
  doc: [
    {
      field: "id",
      headerName: "Id",
      type: "number",
    },
    {
      field: "docType",
      headerName: "Name",
      type: "string",
    },
    {
      field: "stage",
      headerName: "Stage",
      type: "string",
    },
    {
      field: "status",
      headerName: "Status",
      type: "string",
    },
    {
      field: "priority",
      headerName: "Priority",
      type: "string",
    },
    {
      field: "conf",
      headerName: "Conf.",
      type: "number",
    },
    {
      field: "pages",
      headerName: "Pages",
      type: "number",
    },
    {
      field: "assignedTo",
      headerName: "Assigned To",
      type: "string",
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      type: "date",
    },
    {
      field: "error",
      headerName: "Error",
      type: "string",
    },
  ],
};

export default TableConfig;
