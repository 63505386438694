import { useContext, useEffect } from "react";
import { AppContext } from "../../Contexts/Contexts";
import TrapezeClient from "../../api/trapeze.client";
import WorkflowApi from "../../api/workflow-api";
import Vars from "../../store/vars";
import Dashboard from "../../components/DataGrids/Dashboard";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../components/Loaders/Loader";
import Center from "../../components/Center";
import { useParams } from "react-router-dom";

const RestrictedBatchDashboard = () => {
  const { appSettings, alert, blocker } = useContext(AppContext);
  const { loanId } = useParams();
  const {
    data: batches,
    isPending: batchesPending,
    error: batchesError,
  } = useQuery({
    queryKey: ["getBatchesOfLoan"],
    queryFn: async () => await WorkflowApi.getBatchesInLoan(loanId),
    refetchInterval:
      appSettings.refreshRateInSeconds * 1000 ?? Vars.refreshInterval,
  });
  const {
    data: tableConfig,
    isPending: tableConfigPending,
    error: tableConfigError,
  } = useQuery({
    queryKey: ["getBatchTableColumns"],
    queryFn: TrapezeClient.getBatchTableColumns,
  });

  useEffect(() => {
    if (tableConfigError) {
      console.error(tableConfigError);
      return alert.open(<div>{tableConfigError.toString()}</div>);
    }

    if (batchesError) {
      if (batchesError.status === 403) {
        return blocker.open(<div>{`User Forbidden`}</div>);
      }
      console.error(batchesError);
      return alert.open(<div>{batchesError.toString()}</div>);
    }
  }, [batchesError, tableConfigError, alert, blocker]);

  if (batchesPending || tableConfigPending) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (!batches || !tableConfig) {
    return null;
  }

  return <Dashboard batches={batches} tableConfig={tableConfig} />;
};

export default RestrictedBatchDashboard;
