import { useContext } from "react";
import { AppContext } from "../../Contexts/Contexts";
import CircleCloseIcon from "../Icons/CircleCloseIcon";

const Popup = ({ popupProps }) => {
  const { popup } = useContext(AppContext);

  return (
    <div className="popup-container">
      <div className="popup-header">
        <h1>{popupProps.header}</h1>
        <CircleCloseIcon onClick={() => popup.close()} className={"button"} />
      </div>
      <div className="standard-content">{popupProps.content}</div>
    </div>
  );
};

export default Popup;
