import PropTypes from "prop-types";
import { AiOutlineClose } from "react-icons/ai";

const XCloseIcon = ({ onClick, size, sx, className, title }) => {
  return (
    <AiOutlineClose
      className={`close-icon ${className}`}
      onClick={onClick}
      size={size}
      style={sx}
      title={title}
    />
  );
};

XCloseIcon.defaultProps = {
  size: 30,
  onClick: () => {},
  sx: {},
  className: "",
  title: "Close",
};

XCloseIcon.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
  sx: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.string,
};

export default XCloseIcon;
