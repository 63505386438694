import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import StandardDataGrid from "../../../components/DataGrids/StandardDataGrid";
import TrapezeClient from "../../../api/trapeze.client";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { AdminContext, AppContext } from "../../../Contexts/Contexts";
import SaveIcon from "../../../components/Icons/SaveIcon";
import Edit from "../../../components/Edit/Edit";
import { useQuery } from "@tanstack/react-query";

const DashboardConfig = () => {
  const [dashboardConfig, setDashboardConfig] = useState([]);
  const { showCheckmark } = useContext(AppContext);
  const apiRef = useGridApiRef();
  const { dispatch } = useContext(AdminContext);
  const { data: tags } = useQuery({
    queryKey: ["getTags"],
    queryFn: async () => {
      const tags = await TrapezeClient.getTags();
      return tags.map((tag) => {
        return {
          id: tag.tagID.toString(),
          name: tag.tagName,
          editPermissions: tag.editPermission,
        };
      });
    },
  });

  // Get-Setters
  const getSetDashboardConfig = useCallback(async () => {
    const _dashboardConfig = await TrapezeClient.getBatchTableColumns();
    const dashboardConfig = _dashboardConfig.map((column, idx) => {
      return {
        ...column,
        id: idx,
        __reorder__: column.field,
      };
    });
    setDashboardConfig(dashboardConfig);
    return dashboardConfig;
  }, []);

  const columns = useMemo(() => {
    return [
      {
        field: "field",
        headerName: "Name",
        flex: 1,
        renderCell: (params) => {
          const value =
            tags?.find((tag) => tag.id === params.value)?.name ?? params.value;
          return <div>{value}</div>;
        },
      },
      {
        field: "headerName",
        headerName: "Display Name",
        flex: 1,
        editable: true,
        renderCell: (params) => (
          <Edit>
            <div>{params.value}</div>
          </Edit>
        ),
      },
      {
        field: "type",
        headerName: "Data Type",
        flex: 1,
        editable: true,
        type: "singleSelect",
        valueOptions: ["string", "number", "date"],
        renderCell: (params) => (
          <Edit>
            <div>{params.value}</div>
          </Edit>
        ),
      },
      {
        field: "hidden",
        headerName: "Hidden",
        flex: 1,
        editable: true,
        type: "singleSelect",
        valueOptions: [true, false],
        renderCell: (params) => (
          <Edit>
            <div>{params.value.toString()}</div>
          </Edit>
        ),
      },
    ];
  }, [tags]);

  const processRowUpdate = (updatedRow, originalRow) => {
    updatedRow.headerName = updatedRow.headerName.trim();
    if (updatedRow.headerName === "") {
      updatedRow.headerName = originalRow.headerName;
    }
    const column = dashboardConfig.find(
      (column) => column.id === updatedRow.id
    );

    column.headerName = updatedRow.headerName;
    column.type = updatedRow.type;
    column.hidden = updatedRow.hidden;
    return updatedRow;
  };

  // Clicks
  const onSave = useCallback(
    async (dashboardConfig) => {
      await TrapezeClient.modifyBatchTableColumns({ columns: dashboardConfig });
      showCheckmark();
      await getSetDashboardConfig();
    },
    [getSetDashboardConfig, showCheckmark]
  );

  const getButtons = useCallback(() => {
    return [
      <SaveIcon
        className="blue s-full pad-5 button"
        onClick={() => {
          onSave(dashboardConfig);
        }}
      />,
    ];
  }, [dashboardConfig, onSave]);

  useEffect(() => {
    const driver = async () => {
      await getSetDashboardConfig();
    };
    driver();
  }, [getSetDashboardConfig]);

  useEffect(() => {
    dispatch({ type: "setButtons", buttons: getButtons() });
  }, [getButtons, dispatch]);

  return (
    <div className="pad-5">
      <StandardDataGrid
        className="bg-white box-shadow"
        name="sysadmin-dashboard-config"
        apiRef={apiRef}
        columns={columns}
        rows={dashboardConfig}
        setRows={setDashboardConfig}
        processRowUpdate={processRowUpdate}
        hideColumnsToolbar
        hideSearchToolbar
      />
    </div>
  );
};

export default DashboardConfig;
