const BoxInfo = ({ name, className, icon, children }) => {
  return (
    <div className="flex-col box-shadow bg-white">
      <div className={`pad-5 ${className} flex gap-5 align-center`}>
        {icon}
        <div>{name}</div>
      </div>
      {children}
    </div>
  );
};

export default BoxInfo;
