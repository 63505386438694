import {
  useContext,
  useRef,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { AppContext, ServiceContext } from "../../Contexts/Contexts";
import ExtractionUtils from "../../utils/extraction.utils";
import SaveIcon from "../../components/Icons/SaveIcon";
import VerifAiApi from "../../api/verifai-api";
import ExtractionPanel from "./Panels/extraction.panel";
import ExtractionApi from "../../api/extraction-api";
import BrowserUtils from "../../utils/browser.utils";
import DomUtils from "../../utils/dom.utils";
import StringUtils from "../../utils/string.utils";
import ArrayUtils from "../../utils/array.utils";
import { copy, sleep } from "../../utils/general.utils";

const nonVerifaiFields = new Set([
  "DocumentType",
  "DocumentType_Confidence",
  "Version",
]);

const tempFieldProps = new Set(["config", "ref", "rowIdx", "colIdx", "index"]);

const VerifaiExtractionService = () => {
  const { showCheckmark, alert } = useContext(AppContext);
  const { state, dispatch } = useContext(ServiceContext);
  const [document, setDocument] = useState(null);
  const rowRef = useRef();
  const [currentField, setCurrentField] = useState(null);
  const page = useMemo(
    () => document?.pages.find((page) => page._id === currentField?.pageId),
    [document, currentField]
  );

  // GetSetters
  const getSetDocuments = useCallback(() => {
    if (!state.loan) return;
    const documents = state.loan.documents;
    documents.forEach((doc, idx) => {
      doc.explorer = {
        type: "document",
        name: `${doc.type} (${StringUtils.getShortHash(
          doc._id
        ).toUpperCase()})`,
        tag: DomUtils.getVersionTag(doc.version),
      };
      doc.index = idx;
      doc.pages.forEach((page) => {
        page.src = BrowserUtils.getPageUrl(state.loan.name, page.fileId);
      });
    });
    dispatch({ type: "setRepo", repo: documents });
    return documents;
  }, [dispatch, state.loan]);

  const getSetDocument = useCallback(async () => {
    const document = copy(state.item);
    document.rows = [];
    setDocument(document);
    const docLayout = (await ExtractionApi.getDocLayout(document.type)).doc;
    const lowThresh = docLayout.lowThresh;
    document.rows = copy(docLayout.rows);
    const indexedFields = ArrayUtils.createIndex(document.fields, "name");
    document.rows.forEach((row, rowIdx) => {
      if (row.type !== "fields") return;
      const fields = row.fields.map((fc, colIdx) => {
        const field = indexedFields[fc.name];
        if (!field) {
          if (!nonVerifaiFields.has(fc.name)) {
            console.warn(`Field: "${fc.name}" not found.`, fc);
            return null;
          }
          fc.visibility = "collapsed";
          return { config: fc, rowIdx, colIdx };
        }
        if (!fc.useThresh) fc.threshold = lowThresh;
        return {
          ...field,
          config: fc,
          rowIdx,
          colIdx,
        };
      });
      row.fields = fields.filter((field) => field !== null);
    });
    setDocument({ ...document });
  }, [state.item]);

  // Actions
  const approveField = useCallback(
    (value, originalField) => {
      document.rows.forEach((row) => {
        if (row.type !== "fields") return;
        row.fields.forEach((field) => {
          if (field.name !== originalField.name) return;
          field.value = value;
          field.confidence = 201;
        });
      });
      setDocument({ ...document });
    },
    [document]
  );

  // Clicks
  const onFocus = useCallback((field) => {
    setCurrentField(field);
  }, []);

  const onFieldChange = useCallback(
    (value, originalField) => approveField(value, originalField),
    [approveField]
  );

  const onSave = useCallback(async () => {
    const fields = ExtractionUtils.getFlattenedFields(document.rows)
      .filter((field) => !nonVerifaiFields.has(field.config.name))
      .map((_field) => {
        const field = {};
        Object.keys(_field).forEach((key) => {
          if (tempFieldProps.has(key)) return;
          field[key] = _field[key];
        });
        return field;
      });
    state.item.fields = fields;
    await VerifAiApi.putDocument(state.loan._id, copy(state.item));
    await showCheckmark();
  }, [state.loan, state.item, document, showCheckmark]);

  const Buttons = useMemo(() => {
    return [
      <SaveIcon size={50} className={"button pad-10"} onClick={onSave} />,
    ];
  }, [onSave]);

  const hotkeys = useCallback(
    async (e) => {
      if (!document || DomUtils.hasPopup()) return;
      if (e.ctrlKey) {
        switch (e.key) {
          case "s":
            onSave();
            break;
          default:
            break;
        }
      } else {
        switch (e.key) {
          case "Enter":
          case "Tab":
            if (e.key === "Enter") {
              approveField(currentField.value, currentField);
            }
            const lowConfFields = ExtractionUtils.getLowConfFields(
              document.rows
            );
            if (lowConfFields.length === 0) {
              alert.open(<div>No Fields to Review</div>);
              return;
            }
            const getField = (direction) => {
              const currentRowIdx = currentField?.rowIdx ?? -1;
              const currentColIdx = currentField?.colIdx ?? -1;

              const isNextValid = (field) =>
                (field.rowIdx === currentRowIdx &&
                  field.colIdx > currentColIdx) ||
                field.rowIdx > currentRowIdx;

              const isPrevValid = (field) =>
                (field.rowIdx === currentRowIdx &&
                  field.colIdx < currentColIdx) ||
                field.rowIdx < currentRowIdx;

              if (direction === "next") {
                return lowConfFields.find(isNextValid) || lowConfFields[0];
              }

              if (direction === "prev") {
                for (let i = lowConfFields.length - 1; i >= 0; i--) {
                  if (isPrevValid(lowConfFields[i])) return lowConfFields[i];
                }
                return lowConfFields[lowConfFields.length - 1];
              }

              return null;
            };

            const direction = e.shiftKey ? "prev" : "next";
            let field = getField(direction);
            rowRef.current.scrollToItem(field.rowIdx, "center");
            await sleep(0.1); // time for scrolled items to render
            field.ref.focus();
            break;
          default:
            break;
        }
      }
    },
    [onSave, approveField, currentField, alert, document]
  );

  useEffect(() => {
    const driver = () => {
      getSetDocuments();
    };
    driver();
  }, [getSetDocuments]);

  useEffect(() => {
    const itemDriver = () => {
      if (state.item && state.item._id !== document?._id) {
        getSetDocument();
        dispatch({ type: "setButtons", buttons: Buttons });
      }
    };
    itemDriver();
  }, [state.item, document, dispatch, Buttons, getSetDocument]);

  useEffect(() => {
    window.addEventListener("keydown", hotkeys);
    return () => window.removeEventListener("keydown", hotkeys);
  }, [hotkeys]);

  return (
    <ExtractionPanel
      document={document}
      page={page}
      onFieldChange={onFieldChange}
      onFocus={onFocus}
      rowRef={rowRef}
      field={currentField}
    />
  );
};

export default VerifaiExtractionService;
