import Request from "./Request";
import ArrayUtils from "../utils/array.utils";

const PATH = "/api/trapeze/workflow";

const WorkflowApi = {
  getBatches: async () => {
    return await Request.get(`/api/tdi/batches`);
  },
  getBatch: async (batchName) => {
    return await Request.get(`${PATH}/batches/${batchName}`);
  },
  getBatchesInLoan(loanId) {
    return Request.get(`${PATH}/loans/${loanId}/batches`);
  },
  getBatchFromStage: async (batchName, stages = []) => {
    // if (stages.length === 0) {
    //   return await Request.get(`${PATH}/batches/${batchName}`);
    // }
    return await Request.get(
      `${PATH}/batches/${batchName}?service=${stages.join()}`,
      { returnResponse: true }
    );
  },
  getDocs: async (batchName) => {
    return await Request.get(`${PATH}/batches/${batchName}/files`);
  },
  getSplitFile: async (batchName, fileId) => {
    const res = await Request.get(
      `${PATH}/batches/${batchName}/files/${fileId}`,
      {
        returnResponse: true,
      }
    );
    return await res.blob();
  },
  getResets: async (batchName) => {
    return await Request.get(`${PATH}/batches/${batchName}/retries`);
  },
  modifyDocType: async (docId, docType) => {
    return await Request.put(`${PATH}/docs/${docId}`, docType);
  },
  cancelBatch: async (batchName) => {
    return await Request.put(`${PATH}/batches/${batchName}/cancel`);
  },
  resetBatch: async (batchName) => {
    return await Request.post(`${PATH}/batches/${batchName}/retries`);
  },
  deleteBatch: async (batchName, forceDelete) => {
    return await Request.delete(
      `${PATH}/batches/${batchName}?forceDelete=${forceDelete}`
    );
  },
  getDocumentTypes: async () => {
    return await Request.get(`${PATH}/documentTypes`);
  },
  changeStageStatus: async (batchName, service, status) => {
    return await Request.put(`${PATH}/batches/${batchName}/stages`, {
      stageName: service,
      statusName: status,
    });
  },
  getPipeline: async () => await Request.get(`/api/trapeze/pipeline`),
  getTagConfig: async () => {
    const tags = await Request.get("/api/trapeze/tags");
    return ArrayUtils.createIndex(tags, "tagID");
  },
};

export default WorkflowApi;
