import { FormControl, TextField } from "@mui/material";
import React, { useContext, useMemo, useState } from "react";
import CandyButton from "../../../components/Buttons/CandyButton";
import VerifAiApi from "../../../api/verifai-api";
import { AppContext } from "../../../Contexts/Contexts";
import LoanTag from "../../../models/Loan/LoanTag";
import { copy } from "../../../utils/general.utils";

const emptyNameError = "No Name Supplied";
const nameExistsError = "Loan name already exists.";

const AddLoan = ({ loans }) => {
  const { loader, showCheckmark, alert, leftPopOut } = useContext(AppContext);
  const [loan, setLoan] = useState({ name: "" });
  const [tag, setTag] = useState(new LoanTag("", ""));
  const [userError, setUserError] = useState("");

  const loanNames = useMemo(() => {
    const loanNames = new Set();
    for (const loan of loans) {
      loanNames.add(loan.name.toLowerCase());
    }
    return loanNames;
  }, [loans]);

  // actions
  const submit = async (loan) => {
    loader.open();
    try {
      const loanId = (await VerifAiApi.postLoan(loan))._id;
      await VerifAiApi.addTag(loanId, tag);
    } catch (error) {
      alert.open(<p>{error.message}</p>);
      return;
    } finally {
      loader.close();
    }
    leftPopOut.close();
    showCheckmark();
  };

  // clicks
  const onSubmit = async () => {
    setUserError("");
    if (loan.name === "") {
      setUserError(emptyNameError);
      return;
    }
    if (loanNames.has(loan.name.toLowerCase())) {
      setUserError(nameExistsError);
      return;
    }
    await submit(loan);
  };
  return (
    <div className="standard-content">
      <FormControl style={{ display: "flex", gap: "20px" }}>
        <TextField
          style={{ minWidth: "300px" }}
          size="small"
          label="Name"
          value={loan.name}
          onChange={(e) => {
            loan.name = e.target.value;
            setLoan(copy(loan));
          }}
          InputLabelProps={{ shrink: true }}
        />
        <div
          style={{
            height: "30px",
            padding: "5px",
            textAlign: "center",
            color: "var(--tertiary-bg-color-2)",
          }}
        >
          {userError}
        </div>
        <TextField
          inputProps={{ maxLength: 12 }}
          size="small"
          label="Label"
          value={tag.label}
          onChange={(e) => {
            tag.label = e.target.value;
            setTag(copy(tag));
          }}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          multiline
          rows={4}
          size="small"
          label="Content"
          value={tag.content}
          onChange={(e) => {
            tag.content = e.target.value;
            setTag(copy(tag));
          }}
          InputLabelProps={{ shrink: true }}
        />
        <CandyButton element={<div>Submit</div>} onClick={onSubmit} />
      </FormControl>
    </div>
  );
};

export default AddLoan;
