const adminSettingsReducer = (state, action) => {
  switch (action.type) {
    case "setSetting":
      return {
        ...state,
        setting: action.setting,
        buttons: null,
      };
    case "setButtons":
      return {
        ...state,
        buttons: action.buttons,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export default adminSettingsReducer;
