const TableConfig = {
  classificationConfig: [
    {
      id: "documentTypeName",
      label: "Document Type",
      search: true,
    },
    {
      id: "threshold",
      label: "Threshold",
      search: false,
    },
  ],
  versioningConfig: [
    {
      id: "documentTypeName",
      label: "Document Type",
      search: true,
    },
    {
      id: "threshold",
      label: "Threshold",
      search: false,
    },
    {
      id: "maxFinal",
      label: "Max Final",
      search: false,
    },
  ],
  extractionConfig: [
    {
      id: "fieldName",
      label: "Field",
      search: true,
    },
    {
      id: "threshold",
      label: "Threshold",
      search: false,
    },
    {
      id: "hideField",
      label: "Hide Field",
      search: false,
    },
  ],
};

export default TableConfig;
