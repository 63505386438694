import React from "react";

const ErrorMessage = ({ message, sx }) => {
  return (
    <div style={sx} className="error-message">
      {message}
    </div>
  );
};

export default ErrorMessage;
