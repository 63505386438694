import Request from "./Request";

const PATH = "/api/trapeze/upload";

const UploadApi = {
  uploadBatch: async (batchName, priority, files, tags) => {
    const formData = new FormData();
    formData.set("name", batchName);
    formData.set("priority", priority);
    for (const file of files) {
      formData.append("files", file);
    }
    if (tags?.length > 0) {
      formData.set("BatchTags", JSON.stringify(tags));
    }
    return await Request.post(`${PATH}`, formData, { headers: {} });
  },
  uploadBatchThroughVerifai: async (loanId, batchName, priority, files) => {
    const formData = new FormData();
    formData.set("name", batchName);
    formData.set("priority", priority);
    for (const file of files) {
      formData.append("files", file);
    }
    return await Request.post(
      `/api/trapeze/loans/${loanId}/batches`,
      formData,
      { headers: {} }
    );
  },
};

export default UploadApi;
