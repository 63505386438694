import { useState } from "react";
import VersioningConfig from "./VersioningConfig/VersioningConfig";
import ClassificationConfig from "./ClassificationConfig/ClassificationConfig";
import ExtractionConfig from "./ExtractionConfig/ExtractionConfig";
import IAConfig from "./IAConfig/IAConfig";

const CustomConfig = () => {
  const [displayedSetting, setDisplayedSetting] = useState(
    <div>Nothing Selected</div>
  );
  const configTypes = [
    {
      name: "Classification",
      obj: <ClassificationConfig />,
    },
    {
      name: "Versioning",
      obj: <VersioningConfig />,
    },
    {
      name: "Extraction",
      obj: <ExtractionConfig />,
    },
    {
      name: "Income Analyzer",
      obj: <IAConfig />,
    },
    {
      name: "Analytics",
      obj: <div>Analytics Selected</div>,
    },
    {
      name: "Bookmarking",
      obj: <div>Bookmarking Selected</div>,
    },
  ];

  return (
    <div id="config-container">
      <div className="config-settings-container">
        <h1>Settings</h1>
        {configTypes.map((type, idx) => {
          return (
            <div
              key={idx}
              className="config-settings-link"
              onClick={() => setDisplayedSetting(type.obj)}
            >
              {type.name}
            </div>
          );
        })}
      </div>
      <div className="config-content-container" style={{ margin: "10px auto" }}>
        {displayedSetting}
      </div>
    </div>
  );
};

export default CustomConfig;
