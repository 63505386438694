import { BsCheck2All } from "react-icons/bs";
import PropTypes from "prop-types";

const ApproveAllIcon = ({ onClick, size, sx, className, title }) => {
  return (
    <BsCheck2All
      className={`${className} no-shrink`}
      size={size}
      onClick={onClick}
      style={sx}
      title={title}
    />
  );
};

ApproveAllIcon.defaultProps = {
  size: 30,
  onClick: () => {},
  sx: {},
  className: "",
  title: "Approve All",
};

ApproveAllIcon.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
};
export default ApproveAllIcon;
