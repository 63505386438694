import { useNavigate, Route, Routes, useLocation } from "react-router-dom";
import Login from "./pages/Login/Login";
import CustomConfig from "./pages/CustomConfig/CustomConfig";
import NavBar from "./components/NavBar/NavBar";
// import APIDocumentation from "./pages/APIDocumentation";
import AdminContainer from "./pages/Admin/AdminContainer";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  sendOneTimeToken,
  fetchAppSettings,
  refreshToken,
} from "./api/auth-api";
import AdminApi from "./api/admin-api";
import { AppContext } from "./Contexts/Contexts";
import Popup from "./components/Popups/Popup";
import Alert from "./components/Alerts/Alert";
import Blocker from "./components/Alerts/Blocker";
import Confirm from "./components/Alerts/Confirm";
import _default from "./store/default-props";
import VersioningApi from "./api/versioning-api";
import Footer from "./components/Footers/Footer";
import WorkflowApi from "./api/workflow-api";
import LeftPopOut from "./components/Popups/LeftPopOut";
import Slide from "./components/Animations/Slide";
import DiamondBg from "./img/backgrounds/diamond-background.svg";
import VerifAiApi from "./api/verifai-api";
import Sandbox from "./devtools/Sandbox";
import CircleLoader from "./components/Loaders/CircleLoader";
import LoanDetails from "./pages/Loan/LoanDetails";
import LoanDashboard from "./pages/Loans/LoanDashboard";
import LoginSso from "./pages/login-sso/LoginSso";
import ServiceContainer from "./pages/Services/ServiceContainer";
import Focus from "./pages/Focus/Focus";
import BrowserUtils from "./utils/browser.utils";
import { sleep } from "./utils/general.utils";
import { LicenseInfo } from "@mui/x-license-pro";
import BatchDashboard from "./pages/Dashboard/BatchDashboard";
import CheckmarkIcon from "./components/Icons/CheckmarkIcon";
import RestrictedBatchDashboard from "./pages/Dashboard/RestrictedBatchDashboard";
import TrapezeClient from "./api/trapeze.client";

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [dim, setDim] = useState("");
  const [appSettings, setAppSettings] = useState({});
  const [loaderProps, setLoaderProps] = useState(_default.loader);
  const [checkmark, setCheckmark] = useState(false);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [versioningVersions, setVersioningVersions] = useState([]);
  const [versioningRules, setVersioningRules] = useState([]);
  const [popupProps, setPopupProps] = useState(_default.popup);
  const [leftPopOutProps, setLeftPopOutProps] = useState(_default.leftPopOut);
  const [alertProps, setAlertProps] = useState(_default.alert);
  const [blockerProps, setBlockerProps] = useState(_default.blocker);
  const [confirmProps, setConfirmProps] = useState(_default.confirm);
  const [onUnloadProps, setOnUnloadProps] = useState(_default.onUnload);
  const [tokenSetup, setTokenSetup] = useState(null);
  const [pipeline, setPipeline] = useState([]);
  const [idleTime, setIdleTime] = useState(0);
  const params = useMemo(
    () => BrowserUtils.getServiceParams(location.search),
    [location.search]
  );

  LicenseInfo.setLicenseKey(
    "c440ba374bde76d5d0c629aeec71f108Tz03OTczOCxFPTE3MzI5ODU2NzQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
  );
  // const [prompt, setPrompt] = useState(false);

  // usePrompt(
  //   "Are you sure you want to continue? Unsaved changes will be lost.",
  //   prompt
  // );

  // Global Functions
  const increaseIdleTime = useCallback(
    () => setIdleTime((idleTime) => idleTime + 1),
    []
  );
  const resetIdleTime = useCallback(() => setIdleTime(0), []);

  const onUnloadSet = useCallback((callback, path) => {
    setOnUnloadProps({ callback: callback, path: path });
  }, []);

  const onUnloadClear = useCallback(() => {
    setOnUnloadProps(_default.onUnload);
  }, []);

  const onUnloadRun = useCallback(async () => {
    if (!onUnloadProps.callback) return;
    await onUnloadProps.callback();
    onUnloadClear();
  }, [onUnloadProps, onUnloadClear]);

  const showCheckmark = useCallback(async () => {
    setCheckmark(true);
    await sleep(1.5);
    setCheckmark(false);
  }, []);

  const loader = useMemo(() => {
    return {
      open: (content = null) => {
        setLoaderProps({
          content: content,
          hide: false,
        });
      },
      close: () => setLoaderProps(_default.loader),
    };
  }, []);

  const popupOpen = useCallback(
    (header, content) =>
      setPopupProps({
        header: header,
        content: content,
        hide: false,
      }),
    []
  );

  const popupClose = useCallback(() => setPopupProps(_default.popup), []);

  const leftPopOutOpen = useCallback(
    (header, content) =>
      setLeftPopOutProps({
        header: header,
        content: content,
        hide: false,
      }),
    []
  );

  const leftPopOutClose = useCallback(
    () => setLeftPopOutProps(_default.popup),
    []
  );

  const blockerOpen = useCallback(
    (content) => setBlockerProps({ content: content, hide: false }),
    []
  );

  const blockerClose = useCallback(() => setBlockerProps(_default.blocker), []);

  const alertOpen = useCallback(
    (content, onAccept = () => {}) =>
      setAlertProps({ content: content, onAccept: onAccept, hide: false }),
    []
  );

  const alertClose = useCallback(() => setAlertProps(_default.alert), []);

  const confirmOpen = useCallback(
    (content, onAccept) =>
      setConfirmProps({
        content: content,
        onAccept: onAccept,
        hide: false,
      }),
    []
  );

  const confirmClose = useCallback(() => setConfirmProps(_default.confirm), []);

  // useEffects
  // driver
  useEffect(() => {
    const driver = async () => {
      const appSettings = await fetchAppSettings();
      setAppSettings(appSettings);
      sessionStorage.removeItem("timezone");
      if (appSettings.timezone) {
        sessionStorage.setItem("timezone", appSettings.timezone);
      }
    };
    driver();
  }, []);

  // getUser
  useEffect(() => {
    if (user || location.pathname.startsWith("/login")) return;
    const getUser = async () => {
      if (BrowserUtils.getSearchParams().has("token")) {
        const token = BrowserUtils.getSearchParams().get("token");
        try {
          await sendOneTimeToken(token);
        } finally {
          const url = new URL(window.location.href);
          url.searchParams.delete("token");
          window.location.replace(url);
          return;
        }
      }
      const user = await AdminApi.fetchUser();
      setUser(user);
    };
    getUser();
  }, [location, user]);

  // getGlobals once logged in
  useEffect(() => {
    if (!user) return;
    const getGlobals = async () => {
      setDocumentTypes(await WorkflowApi.getDocumentTypes());
      setVersioningVersions((await VersioningApi.getVersions()).versions);
      setVersioningRules((await VersioningApi.getRules()).versioningRules);
      setPipeline(await WorkflowApi.getPipeline());
    };
    getGlobals();
  }, [user]);

  // setup token refresh
  useEffect(() => {
    if (!user && tokenSetup) {
      clearInterval(tokenSetup);
      setTokenSetup(null);
      return;
    }
    if (!user || tokenSetup) return;
    const refreshRate = 15 * 60 * 1000;
    const id = setInterval(async () => {
      await refreshToken();
      if (appSettings.useVerifai) {
        try {
          await VerifAiApi.refresh();
        } catch (err) {
          alertOpen(<div>Login To VerifAI Failed</div>);
          return;
        }
      }
    }, refreshRate);
    setTokenSetup(id);
  }, [user, tokenSetup, navigate, appSettings, alertOpen]);

  // useEffect setup idle
  useEffect(() => {
    const events = ["mousemove", "keypress", "touchstart"];
    events.forEach((event) => window.addEventListener(event, resetIdleTime));
    const idleInterval = setInterval(increaseIdleTime, 60000);

    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, resetIdleTime)
      );
      clearInterval(idleInterval);
    };
  }, [resetIdleTime, increaseIdleTime]);

  useEffect(() => {
    const checkIdle = async () => {
      if (idleTime >= appSettings.idle && location.pathname !== "/login") {
        loader.open();
        resetIdleTime();
        BrowserUtils.localStorage.set(
          "redirect",
          location.pathname + location.search
        );
        await onUnloadRun();
        await TrapezeClient.logout();
        if (appSettings.useVerifai) await VerifAiApi.logout();
        setUser(null);
        navigate("/login");
        loader.close();
        await sleep(0.1);
        alertOpen(<div>Logged Out Due to Inactivity</div>);
      }
    };
    checkIdle();
  }, [
    idleTime,
    appSettings,
    params,
    navigate,
    loader,
    user,
    resetIdleTime,
    location,
    alertOpen,
    onUnloadRun,
  ]);

  // toggle dim
  useEffect(() => {
    !loaderProps.hide ||
    !popupProps.hide ||
    !leftPopOutProps.hide ||
    !blockerProps.hide ||
    !alertProps.hide ||
    checkmark ||
    !confirmProps.hide
      ? setDim("dim")
      : setDim("");
  }, [
    loaderProps,
    popupProps,
    leftPopOutProps,
    alertProps,
    checkmark,
    confirmProps,
    blockerProps,
  ]);

  // runOnUnload
  useEffect(() => {
    if (onUnloadProps.path === location.pathname) return;
    onUnloadRun();
  }, [onUnloadProps, location, onUnloadRun]);

  // cleanup
  useEffect(() => {
    const cleanup = async () => {
      loader.close();
      setPopupProps(_default.popup);
      setBlockerProps(_default.blocker);
      setAlertProps(_default.alert);
      setConfirmProps(_default.confirm);
      setLeftPopOutProps(_default.leftPopOut);
    };
    cleanup();
  }, [location.pathname, loader]);

  // stopFileSave on ctrl + s hotkey
  useEffect(() => {
    const stopFileSave = (e) => {
      if (e.ctrlKey && e.key === "s") e.preventDefault();
    };
    window.addEventListener("keydown", stopFileSave, false);
    return () => window.removeEventListener("keydown", stopFileSave, false);
  }, []);

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        appSettings,
        loader,
        popup: { open: popupOpen, close: popupClose },
        leftPopOut: { open: leftPopOutOpen, close: leftPopOutClose },
        blocker: { open: blockerOpen, close: blockerClose },
        alert: { open: alertOpen, close: alertClose },
        confirm: { open: confirmOpen, close: confirmClose },
        documentTypes,
        showCheckmark,
        onUnload: {
          set: onUnloadSet,
          clear: onUnloadClear,
          run: onUnloadRun,
        },
        versioning: { rules: versioningRules, versions: versioningVersions },
        pipeline,
      }}
    >
      <div id="viewport">
        {BrowserUtils.isFullScreenPage(location.pathname) || <NavBar />}
        <div
          id="main"
          className={`s-full overflow-hidden bg-white ${dim}`}
          style={{
            backgroundImage: `url(${DiamondBg})`,
            backgroundPosition: "bottom left",
            backgroundRepeat: "repeat-x",
            backgroundSize: "24px",
          }}
        >
          {
            <Routes>
              <Route path="" element={<BatchDashboard />} />
              <Route
                path="loans/:loanId/batches"
                element={<RestrictedBatchDashboard />}
              />
              <Route path="login" element={<Login />} />
              <Route path="login-sso" element={<LoginSso />} />
              <Route path="services" element={<ServiceContainer />} />
              <Route path="loan" element={<LoanDetails />} />
              <Route path="loans" element={<LoanDashboard />} />
              {/* <Route exact path="/docs/api" element={<APIDocumentation />} /> */}
              <Route path={"admin"} element={<AdminContainer />} />
              <Route path={"admin/config"} element={<CustomConfig />} />
              <Route path={"sandbox"} element={<Sandbox />} />
              <Route path={"focus"} element={<Focus />} />
            </Routes>
          }
        </div>
        {BrowserUtils.isFullScreenPage(location.pathname) || <Footer />}
      </div>
      <Slide
        className="left-pop-out-container"
        isVisible={!leftPopOutProps?.hide}
        children={
          !leftPopOutProps?.hide && (
            <LeftPopOut leftPopOutProps={leftPopOutProps} />
          )
        }
      />
      <div className="center-stage">
        <div style={{ backgroundColor: "white" }}></div>
        {!popupProps.hide && <Popup popupProps={popupProps} />}
        {!loaderProps.hide && <CircleLoader loaderProps={loaderProps} />}
        {!blockerProps.hide && <Blocker blockerProps={blockerProps} />}
        {!alertProps.hide && <Alert alertProps={alertProps} />}
        {!confirmProps.hide && <Confirm confirmProps={confirmProps} />}
        {checkmark && (
          <CheckmarkIcon className={"bg-white green br-5"} size={140} />
        )}
      </div>
    </AppContext.Provider>
  );
};

export default App;
