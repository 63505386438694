import { Switch } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useContext, useState } from "react";
import ExtractionConfigAPI from "../../../../api/config-api/extraction-config-api";
import ThresholdInput from "../../../../components/Inputs/ThresholdInput";
import { AppContext } from "../../../../Contexts/Contexts";

const ModifyExtractionFieldRule = ({
  documentTypeName,
  currentField,
  defaultRule,
  refresh,
  sx,
}) => {
  const { popup } = useContext(AppContext);
  const fieldName = currentField[0].fieldName;
  const [threshold, setThreshold] = useState(currentField[0].threshold);
  const [useDefaultThreshold, setUseDefaultThreshold] = useState(
    !currentField[0].useThreshold
  );
  const [hideField, setHideField] = useState(currentField[0].hideField);

  //Sub-components
  const FieldRuleTextField = () => {
    return (
      <div className="text-field" style={{ padding: "20px 10px 0px 10px" }}>
        <TextField
          sx={{
            width: "100%",
            WebkitTextFillColor: "black",
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "black",
              padding: "10px",
            },
          }}
          label="Fields"
          value={fieldName}
          disabled={true}
          InputLabelProps={{ shrink: true }}
        />
      </div>
    );
  };

  const InputContainer = () => {
    return (
      <ThresholdInput
        disabled={useDefaultThreshold}
        style={{ margin: "auto" }}
        threshold={threshold}
        setThreshold={setThreshold}
      />
    );
  };

  const SwitchContainer = () => {
    return (
      <>
        <Switch
          checked={useDefaultThreshold}
          onChange={(e) => setUseDefaultThreshold(e.target.checked)}
        />
        <label>Use Default Threshold</label>
        <Switch
          checked={hideField}
          onChange={(e) => setHideField(e.target.checked)}
        />
        <label>Hide Field</label>
      </>
    );
  };

  const SaveRuleButton = () => {
    return (
      <button
        className="pill-button"
        onClick={async (e) => {
          e.preventDefault();
          await ExtractionConfigAPI.modifyDocumentExtractionFieldRules(
            documentTypeName,
            fieldName,
            {
              threshold: useDefaultThreshold
                ? defaultRule[0].threshold
                : threshold,
              useThreshold: !useDefaultThreshold,
              hideField: hideField,
            }
          );
          popup.close();
          refresh();
        }}
      >
        Save
      </button>
    );
  };

  const DeleteRuleButton = () => {
    return (
      <button
        className="pill-button"
        onClick={async (e) => {
          e.preventDefault();
          await ExtractionConfigAPI.modifyDocumentExtractionFieldRules(
            documentTypeName,
            fieldName,
            {
              threshold: 100,
              useThreshold: false,
              hideField: false,
            }
          );
          popup.close();
          refresh();
        }}
      >
        Delete Rule
      </button>
    );
  };
  return (
    <div style={sx}>
      {FieldRuleTextField()}
      {InputContainer()}
      {fieldName !== "Default" && SwitchContainer()}
      <div className="buttons-container">
        {SaveRuleButton()}
        {DeleteRuleButton()}
      </div>
    </div>
  );
};

export default ModifyExtractionFieldRule;
