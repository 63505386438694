import { useContext, useState } from "react";
import ThresholdInput from "../../../../components/Inputs/ThresholdInput";
import { AppContext } from "../../../../Contexts/Contexts";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ExtractionConfigAPI from "../../../../api/config-api/extraction-config-api";
import { Switch } from "@mui/material";

const AddExtractionFieldRule = ({
  sx,
  defaultRule,
  documentTypeName,
  fieldRules,
  refresh,
}) => {
  const { popup } = useContext(AppContext);
  const [fieldName, setFieldName] = useState("");
  const [threshold, setThreshold] = useState(defaultRule[0].threshold);
  const [useDefaultThreshold, setUseDefaultThreshold] = useState(false);
  const [hideField, setHideField] = useState(false);

  //Sub-components
  const AutoCompleteContainer = () => {
    return (
      <div className="autocomplete" style={{ padding: "20px 10px 0px 10px" }}>
        <Autocomplete
          sx={{
            "& .css-2iz2x6": {
              top: "calc(50% - 20px)",
            },
            "&  .MuiAutocomplete-endAdornment": {
              marginTop: "-2px",
            },
          }}
          options={fieldRules}
          autoComplete
          autoHighlight
          renderInput={(params) => (
            <TextField
              {...params}
              label="Fields"
              placeholder="Search Fields..."
              InputLabelProps={{ shrink: true }}
            />
          )}
          onChange={(e) => {
            setFieldName(e.target.innerText);
          }}
        />
      </div>
    );
  };

  const InputContainer = () => {
    return (
      <ThresholdInput
        disabled={useDefaultThreshold}
        style={{ margin: "auto" }}
        threshold={threshold}
        setThreshold={setThreshold}
      />
    );
  };

  const SwitchContainer = () => {
    return (
      <>
        <Switch
          checked={useDefaultThreshold}
          onChange={(e) => setUseDefaultThreshold(e.target.checked)}
        />
        <label>Use Default Threshold</label>
        <Switch
          checked={hideField}
          onChange={(e) => setHideField(e.target.checked)}
        />
        <label>Hide Field</label>
      </>
    );
  };

  const ButtonsContainer = () => {
    return (
      <div className="buttons-container">
        <button
          className="pill-button"
          onClick={async (e) => {
            e.preventDefault();
            await ExtractionConfigAPI.modifyDocumentExtractionFieldRules(
              documentTypeName,
              fieldName,
              {
                threshold: useDefaultThreshold
                  ? defaultRule[0].threshold
                  : threshold,
                useThreshold: !useDefaultThreshold,
                hideField: hideField,
              }
            );
            popup.close();
            refresh();
          }}
        >
          Add
        </button>
      </div>
    );
  };

  return (
    <div style={sx}>
      {AutoCompleteContainer()}
      {InputContainer()}
      {SwitchContainer()}
      {ButtonsContainer()}
    </div>
  );
};

export default AddExtractionFieldRule;
