const Statuses = {
  ok: 200,
  created: 201,
  noContent: 204,
  badRequest: 400,
  unauthorized: 401,
  notFound: 404,
  methodNotAllowed: 405,
  locked: 423,
  internalServerError: 500,
};

export default Statuses;
