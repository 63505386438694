import Request from "./Request";

const PATH = "/api/trapeze/auth";

export const fetchAppSettings = async () => {
  return await Request.get(`${PATH}/appsettings`);
};

export const refreshToken = async () => {
  return await Request.get(`${PATH}/refresh`, { returnResponse: true });
};

export const sendOneTimeToken = async (token) => {
  return await Request.post(`${PATH}/login/token2`, { token });
};

export const requestToken = async (code) => {
  const res = await Request.post(`${PATH}/token`, {
    code: code,
    redirectUri: document.location.origin + "/login-sso",
  });
  return await res.json();
};
