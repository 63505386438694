import services from "../store/services";
import Request from "./Request";
import WorkflowApi from "./workflow-api";

const PATH = "/api/trapeze/versioning";

const VersioningApi = {
  getRules: async () => {
    return await Request.get(`${PATH}/rules`);
  },
  getVersions: async () => {
    return await Request.get(`${PATH}/statuses`);
  },
  getBatch: async (batchName) => {
    return await WorkflowApi.getBatchFromStage(batchName, [
      services.versioning.trapezeId,
    ]);
  },
  getDocTypes: async (batchName) => {
    return await Request.get(`${PATH}/batches/${batchName}/documentTypes`);
  },
  getDocs: async (batchName, docTypeName) => {
    return await Request.get(
      `${PATH}/batches/${batchName}/documentTypes/${docTypeName}/docs`
    );
  },
  reserveDocType: async (batchName, docTypeName) => {
    return await Request.put(
      `${PATH}/batches/${batchName}/documentTypes/${docTypeName}/reserve`,
      {}
    );
  },
  releaseDocType: async (batchName, docTypeName) => {
    return await Request.put(
      `${PATH}/batches/${batchName}/documentTypes/${docTypeName}/release`,
      {}
    );
  },
  saveDoc: async (batchName, docTypeName, docId, data) => {
    return await Request.put(
      `${PATH}/batches/${batchName}/documentTypes/${docTypeName}/docs/${docId}`,
      data
    );
  },
  approveDocType: async (batchName, docTypeName) => {
    return await Request.put(
      `${PATH}/batches/${batchName}/documentTypes/${docTypeName}/approve`,
      {}
    );
  },
  approveBatch: async (batchName) => {
    return await Request.put(`${PATH}/batches/${batchName}/approve`, {});
  },
  verifaiSaveDoc: async (loanId, doc) => {
    return await Request.put(`/api/loans/${loanId}/documents/${doc._id}`, doc);
  },
};

export default VersioningApi;
