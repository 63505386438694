import DateTimeUtils from "../../../utils/datetime.utils";

const LoanTableConfig = [
  {
    id: "name",
    label: "Name",
    type: "string",
    action: "view",
  },
  {
    id: "tags",
    label: "Tag",
    type: "string",
    function: (tags) => (tags[0]?.label ? tags[0]?.label : ""),
  },
  {
    id: "dateAdded",
    label: "Date Added",
    type: "date",
    function: (date) => DateTimeUtils.format(date),
  },
  {
    id: "dateModified",
    label: "Date Modified",
    type: "date",
    function: (date) => DateTimeUtils.format(date),
  },
];
export default LoanTableConfig;
