import { useState, useEffect, useContext, useCallback } from "react";
import SearchTable from "../../../components/Tables/SearchTable";
import { AppContext } from "../../../Contexts/Contexts";
import { HiOutlineRefresh } from "react-icons/hi";
import SquareAddButton from "../../../components/Buttons/SquareAddButton";
import DefaultRuleTable from "../../../components/Tables/DefaultRuleTable";
import { FiEdit } from "react-icons/fi";
import VersioningConfigApi from "../../../api/config-api/versioning-config-api";
import ModifyVersioningRule from "./components/ModifyVersioningRule";
import AddVersioningRule from "./components/AddVersioningRule";
import TableConfig from "../config/TableConfig";

const VersioningConfig = () => {
  const { documentTypes, popup } = useContext(AppContext);
  const [rules, setRules] = useState([]);
  const [defaultRule, setDefaultRule] = useState([]);

  //Helper functions
  const getEditDocTypeName = (e) => {
    return e.target.parentNode.firstChild.innerText;
  };

  const filteredDocTypes = (documentTypes, rules) => {
    return documentTypes.filter((row) => {
      return !rules
        .map((row) => row.documentTypeName.toLowerCase())
        .includes(row.toLowerCase());
    });
  };

  //Get-setters
  const getSetVersioningRules = useCallback(async () => {
    const rules = await VersioningConfigApi.getVersioningRules();
    setDefaultRule(
      rules.VersioningRules.filter((row) => {
        return row.documentTypeName.toLowerCase() === "default";
      })
    );
    setRules(
      rules.VersioningRules.filter((row) => {
        return row.documentTypeName.toLowerCase() !== "default";
      })
    );
    return rules;
  }, []);

  //Actions
  const openModify = (rule) => {
    popup.open(
      `Edit Rule`,
      <ModifyVersioningRule
        sx={{
          width: "400px",
          height: "300px",
        }}
        currentRule={rule}
        refresh={() => getSetVersioningRules()}
      />
    );
  };

  const openAdd = (documentTypes, rules) => {
    popup.open(
      "Add Rule",
      <AddVersioningRule
        sx={{
          width: "400px",
          height: "300px",
        }}
        documentTypes={filteredDocTypes(documentTypes, rules)}
        defaultRule={defaultRule}
        refresh={() => getSetVersioningRules()}
      />
    );
  };

  //UseEffects
  //driver
  useEffect(() => {
    getSetVersioningRules();
  }, [getSetVersioningRules]);

  //Sub-components
  const DefaultTable = () => {
    return (
      <div>
        <h1 className="table-header">Default Versioning Rule</h1>
        <DefaultRuleTable
          tableConfig={TableConfig.versioningConfig}
          rowData={defaultRule}
          onRowClick={(e) => openModify(defaultRule)}
          rowButton={RowButton}
        />
      </div>
    );
  };

  const RulesTable = () => {
    return (
      <>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "10fr 2fr 1fr",
          }}
        >
          <h1 className="table-header">Versioning Rules</h1>
          {AddRuleButton()}
          {RefreshButton()}
        </div>
        <SearchTable
          placeholder="Search Document Type..."
          tableConfig={TableConfig.versioningConfig}
          rowData={rules}
          onRowClick={(e) =>
            openModify(
              rules.filter((row) => {
                return row.documentTypeName === getEditDocTypeName(e);
              })
            )
          }
          rowButton={RowButton}
        />
      </>
    );
  };

  const AddRuleButton = () => {
    return (
      <SquareAddButton
        text="Add Rule"
        onClick={() => openAdd(documentTypes, rules)}
      />
    );
  };

  const RefreshButton = () => {
    return (
      <div
        className="refresh-icon"
        style={{ marginRight: "0", marginBottom: "0", gridColumn: "3" }}
      >
        <HiOutlineRefresh size={20} onClick={() => getSetVersioningRules()} />
      </div>
    );
  };

  const RowButton = (style) => {
    return (
      <FiEdit
        className="edit-button"
        style={style}
        onClick={(e) => openModify(e)}
      />
    );
  };

  return (
    <div
      className="config-content"
      style={{ width: "600px", marginTop: "50px" }}
    >
      <h1 className="config-header" style={{ marginBottom: "20px" }}>
        Versioning Configuration Rules
      </h1>
      {DefaultTable()}
      {RulesTable()}
    </div>
  );
};

export default VersioningConfig;
