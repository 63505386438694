class LoanTag {
  _id;
  label;
  content;

  constructor(label, content) {
    this.label = label;
    this.content = content;
  }
}

export default LoanTag;
