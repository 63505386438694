import StandardAttribute from "./StandardAttribute";

const StatusAttribute = ({ label, status }) => {
  const color = Boolean(status) ? "bg-green" : "bg-red";
  const statusLabel = Boolean(status) ? "Active" : "Unreachable";
  return (
    <StandardAttribute label={label}>
      <div className={`br-5 pad-0-5 ${color}`}>{statusLabel}</div>
    </StandardAttribute>
  );
};
export default StatusAttribute;
