import { useCallback, useEffect, useState } from "react";
import StandardAttribute from "../Attributes/StandardAttribute";
import WorkflowApi from "../../api/workflow-api";
import DateTimeUtils from "../../utils/datetime.utils";

const BatchInfo = ({ batchName }) => {
  const [numResets, setNumResets] = useState(0);
  const [lastResetDate, setLastResetDate] = useState("N/A");

  const getSetResetInfo = useCallback(async () => {
    const resets = await WorkflowApi.getResets(batchName);
    if (resets.length > 0) {
      setNumResets(resets.length);
      const resetDate = new Date(resets[resets.length - 1].retryDate);
      setLastResetDate(DateTimeUtils.toUtcAndFormat(resetDate));
    }
  }, [batchName]);

  //driver
  useEffect(() => {
    getSetResetInfo();
  }, [getSetResetInfo]);

  return (
    <div className="flex gap-20 bg-lg pad-5 w-full box-shadow-c">
      <StandardAttribute label="Number of Resets" data={numResets} />
      <StandardAttribute label="Last Reset Date" data={lastResetDate} />
    </div>
  );
};

export default BatchInfo;
