import { useContext, useMemo, useEffect, useCallback } from "react";
import { ServiceContext } from "../../Contexts/Contexts";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import IncomePanel from "./Panels/income.panel";
import DomUtils from "../../utils/dom.utils";
import StringUtils from "../../utils/string.utils";
import ArrayUtils from "../../utils/array.utils";
import VerifAiApi from "../../api/verifai-api";

const IncomeService = () => {
  const { state, dispatch } = useContext(ServiceContext);
  const report = useMemo(() => state.item, [state.item]);
  const indexedLoan = useMemo(
    () => ArrayUtils.getIndexedLoan(state.loan),
    [state.loan]
  );

  const getSetReports = useCallback(() => {
    state.loan.incomeReports.sort((a, b) => {
      const dateA = new Date(a.dateAdded);
      const dateB = new Date(b.dateAdded);
      return dateB - dateA;
    });
    state.loan.incomeReports.forEach((report, idx) => {
      report.explorer = {
        type: "document",
        name: `${StringUtils.toTitleCase(
          report.borrower
        )} - ${StringUtils.toTitleCase(report.employer)}`,
        tag: DomUtils.getDateTag(report.dateAdded),
      };
      report.index = idx;
    });
    dispatch({ type: "setRepo", repo: state.loan.incomeReports });
    return state.loan.incomeReports;
  }, [state.loan.incomeReports, dispatch]);

  const Buttons = useMemo(
    () => [
      <a
        href={VerifAiApi.getIncomeReportPdfUrl(state.loan?._id, report?._id)}
        download={`income-report-${report?._id}.pdf`}
      >
        <DownloadIcon size={50} className={"pad-10 button"} />
      </a>,
    ],
    [report, state.loan?._id]
  );

  useEffect(() => {
    getSetReports();
  }, [getSetReports]);

  useEffect(() => {
    if (report) {
      dispatch({ type: "setButtons", buttons: Buttons });
    }
  }, [Buttons, dispatch, report]);

  return <IncomePanel report={report} indexedLoan={indexedLoan} />;
};

export default IncomeService;
