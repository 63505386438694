import BrowserUtils from "../utils/browser.utils";

const defaultConfig = { headers: null, returnResponse: false, isLogin: false };

class FetchError extends Error {
  status;

  constructor(status, message) {
    super(message);
    this.status = status;
    Object.setPrototypeOf(this, FetchError.prototype);
  }
}

const request = async (method, url, data, config = defaultConfig) => {
  const options = {
    // Default options are marked with *
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  };
  options.method = method;

  if (config.headers) {
    options.headers = config.headers;
  }

  if (data) {
    options.body =
      options.headers["Content-Type"] === "application/json"
        ? JSON.stringify(data)
        : data;
  }
  const res = await fetch(url, options);
  if (config.isLogin) {
    return res;
  }
  if (
    res.status === 401 &&
    !document.location.pathname.startsWith("/login") &&
    !BrowserUtils.getSearchParams().has("token")
  ) {
    BrowserUtils.localStorage.set(
      "redirect",
      window.location.pathname + window.location.search
    );
    window.location.replace("/login");
    throw new Error("Unauthorized", url);
  }
  if (res.status >= 400) {
    let msg;
    try {
      const json = await res.json();
      msg = json.message || json.errorMessage || json.ErrorMessage;
    } catch (err) {
      msg = "No Error Message Provided";
    }
    console.error(msg);
    throw new FetchError(res.status, msg);
  }
  return method === "GET" && res.status !== 204 && !config.returnResponse
    ? await res.json()
    : res;
};

const Request = {
  get: async (url, config = defaultConfig) => {
    return await request("GET", url, null, config);
  },
  post: async (url, data, config = defaultConfig) => {
    return await request("POST", url, data, config);
  },
  put: async (url, data, config = defaultConfig) => {
    return await request("PUT", url, data, config);
  },
  delete: async (url, config = defaultConfig) => {
    return await request("DELETE", url, null, config);
  },
};

export default Request;
