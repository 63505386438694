import TextField from "@mui/material/TextField";
import { useContext, useState } from "react";
import ThresholdInput from "../../../../components/Inputs/ThresholdInput";
import { AppContext } from "../../../../Contexts/Contexts";
import VersioningConfigApi from "../../../../api/config-api/versioning-config-api";
import MaxFinalInput from "../../../../components/Inputs/MaxFinalInput";
import Statuses from "../../../../api/statuses";

const ModifyVersioningRule = ({ currentRule, refresh, sx }) => {
  const { popup, alert } = useContext(AppContext);
  const [threshold, setThreshold] = useState(currentRule[0].threshold);
  const [maxFinal, setMaxFinal] = useState(currentRule[0].maxFinal);
  const documentTypeName = currentRule[0].documentTypeName;
  // const getMaxFinal = () => {
  //   return maxFinal === "" ? curMaxFinal : maxFinal;
  // };
  const DocumentTypeField = () => {
    return (
      <div className="text-field" style={{ padding: "20px 10px 0px 10px" }}>
        <TextField
          sx={{
            width: "100%",
            WebkitTextFillColor: "black",
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "black",
              padding: "10px",
            },
          }}
          label="Document Type"
          value={documentTypeName}
          disabled={true}
          InputLabelProps={{ shrink: true }}
        />
      </div>
    );
  };

  const InputContainer = () => {
    return (
      <div className="input-container">
        <ThresholdInput threshold={threshold} setThreshold={setThreshold} />
        <div>
          <MaxFinalInput
            label="Max Final"
            positiveInt={maxFinal}
            setPositiveInt={setMaxFinal}
          />
        </div>
      </div>
    );
  };
  const SaveRuleButton = () => {
    return (
      <button
        className="pill-button"
        onClick={async (e) => {
          e.preventDefault();
          await VersioningConfigApi.modifyVersioningRule(documentTypeName, {
            threshold: threshold,
            maxFinal: maxFinal,
          });
          popup.close();
          refresh();
        }}
      >
        Save
      </button>
    );
  };

  const DeleteRuleButton = () => {
    return (
      <button
        className="pill-button"
        onClick={async (e) => {
          e.preventDefault();
          const res = await VersioningConfigApi.deleteVersioningRule(
            documentTypeName
          );
          if (res.status === Statuses.methodNotAllowed) {
            popup.close();
            alert.open(
              <p>The Default Classification Rule cannot be deleted.</p>
            );
            return;
          }
          popup.close();
          refresh();
        }}
      >
        Delete Rule
      </button>
    );
  };

  return (
    <div className="popup-content" style={sx}>
      {DocumentTypeField()}
      {InputContainer()}
      <div className="buttons-container">
        {SaveRuleButton()}
        {DeleteRuleButton()}
      </div>
    </div>
  );
};

export default ModifyVersioningRule;
