import { CgClose } from "react-icons/cg";
import { FaPlus } from "react-icons/fa";
import { useDropzone } from "react-dropzone";
// import { useCallback } from "react";

export const FileInput = ({ files, addFiles, removeFile }) => {
  const { getRootProps, isDragActive } = useDropzone({
    onDrop: addFiles,
    noClick: true,
  });

  // const onDragOver = useCallback(() => {
  //   if (!isDragActive) return;
  //   const target = document.getElementsByClassName("files-uploaded-window");
  //   target[0].addEventListener("dragover", (e) => {
  //     e.dataTransfer.dropEffect = "move";
  //   });
  // }, [isDragActive]);
  return (
    <div className="file-container">
      <label className="standard-label">Files</label>
      <label htmlFor="files" className="square-add-button">
        <FaPlus size={10} style={{ marginRight: "10px" }} />
        <span>Add Files</span>
      </label>
      <input
        hidden
        multiple
        type="file"
        id="files"
        name="files"
        onChange={(e) => addFiles(e.target.files)}
      />

      <div
        {...getRootProps()}
        className={`files-uploaded-window ${
          isDragActive ? "active" : ""
        } scrollbar`}
        // onDragOver={onDragOver}
      >
        {files.length === 0 && (
          <div style={{ textAlign: "center", padding: "20px" }}>
            Drag & Drop Files
          </div>
        )}
        {files.map((file, idx) => {
          return (
            <div key={idx} className="files-window-row">
              <div className="files-window-text" title={file.name}>
                {file.name}
              </div>
              <div className="button">
                <CgClose
                  color="black"
                  size={12}
                  onClick={() => removeFile(file)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
