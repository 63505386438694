import { useNavigate } from "react-router";
import AdvancedSearchTable from "../../components/Tables/AdvancedSearchTable";
import LoanTableConfig from "./config/loanTable.config";

const LoanTable = ({ loans }) => {
  const navigate = useNavigate();
  //clicks
  const onLoanSelected = (loan) => {
    navigate(`/loan?id=${loan._id}`);
  };
  return (
    <AdvancedSearchTable
      tableConfig={LoanTableConfig}
      data={loans}
      onIconClicks={{ view: onLoanSelected }}
      dateSearch
      type="loanTable"
    />
  );
};

export default LoanTable;
