const LinkedCell = ({ data, onClick, disabled, className }) => {
  return (
    <div
      className={`hyperlink ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {data}
    </div>
  );
};

LinkedCell.defaultProps = {
  onClick: () => {},
  disabled: false,
  className: "",
};

export default LinkedCell;
