const MaxFinalInput = ({ style, label, positiveInt, setPositiveInt }) => {
  return (
    <div className="integer-input" style={style}>
      <label style={{ textAlign: "right" }}>{label}:</label>
      <input
        type="number"
        min="1"
        value={positiveInt}
        onChange={(e) => setPositiveInt(e.target.value)}
      ></input>
    </div>
  );
};

export default MaxFinalInput;
