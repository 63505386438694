import { GoServer } from "react-icons/go";

const ServerIcon = ({ size, className, title, onClick }) => {
  return (
    <GoServer
      className={className}
      size={size}
      title={title}
      onClick={onClick}
    />
  );
};

ServerIcon.defaultProps = {
  className: "",
  title: "Server",
  onClick: () => {},
};

export default ServerIcon;
