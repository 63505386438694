import {
  useCallback,
  useState,
  useEffect,
  useRef,
  useContext,
  useMemo,
} from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Document from "../../../components/Dnd/Document";
import StandardAttribute from "../../../components/Attributes/StandardAttribute";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeGrid as Grid } from "react-window";
import { AppContext } from "../../../Contexts/Contexts";
import DomUtils from "../../../utils/dom.utils";
import IdUtils from "../../../utils/id.utils";

const ClassificationPanel = ({
  documents,
  setDocuments,
  index,
  onClick: __onClick,
}) => {
  // console.log("Classification Panel");
  const { alert } = useContext(AppContext);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [itemsRendered, setItemsRendered] = useState({});
  const columnCount = useMemo(() => 2, []);
  const gridRef = useRef(null);

  const getItemSize = useCallback((document, colWidth) => {
    const pageWidth = 135;
    const pageHeight = 139;
    const baseWidth = 44;
    const baseHeight = 115;
    const pagesPerRow = Math.floor((colWidth - baseWidth) / pageWidth) || 1;
    const numRows = Math.ceil(document.pages.length / pagesPerRow);
    const height = numRows * pageHeight + baseHeight;
    return height;
  }, []);

  const lowConfDocs = useMemo(() => {
    return documents.filter((doc) => doc.confidence < doc.threshold);
  }, [documents]);

  const onResize = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.resetAfterRowIndex(0, true);
      gridRef.current.resetAfterColumnIndex(0, true);
    }
  }, [gridRef]);

  const onClassify = useCallback(
    (document, type) => {
      if (!type) return;
      setCurrentIndex(document.index);
      setDocuments((documents) =>
        documents.map((_document) => {
          if (_document._id === document._id) {
            const doc = {
              ..._document,
              type,
              confidence: 100,
              explorer: {
                ..._document.explorer,
                name: `(${_document.index + 1}) ${type}`,
              },
            };
            __onClick(doc);
            return doc;
          }
          return _document;
        })
      );
    },
    [setDocuments, __onClick]
  );

  const onSplit = useCallback(
    ({ documentIndex, pageIndex }) => {
      const document = documents[documentIndex];
      const leftPages = document.pages.slice(0, pageIndex + 1);
      const rightPages = document.pages.slice(pageIndex + 1);

      const firstDocument = {
        ...document,
        pages: leftPages,
        _id: IdUtils.generateUuid(),
      };
      const secondDocument = {
        ...document,
        pages: rightPages,
        _id: IdUtils.generateUuid(),
      };

      setDocuments((documents) => {
        const updatedDocuments = [
          ...documents.slice(0, documentIndex),
          firstDocument,
          secondDocument,
          ...documents.slice(documentIndex + 1),
        ];
        updatedDocuments.forEach((document, index) => {
          document.index = index;
          const warning = document.confidence < document.threshold ? "! " : "";
          document.explorer = {
            ...document.explorer,
            name: `(${index + 1}) ${warning} ${document.type}`,
          };
        });
        return updatedDocuments;
      });
      onResize();
    },
    [documents, onResize, setDocuments]
  );

  const onPageDrop = useCallback(
    (thumbnails, dropZone) => {
      const sourceDocument = documents[thumbnails[0].documentIndex];
      const targetDocument = documents[dropZone.documentIndex];
      let toIndex = dropZone.index;

      if (targetDocument === sourceDocument) {
        const fromIndex = thumbnails[0].index;
        if (thumbnails[0].index === dropZone.index) {
          return;
        }

        if (
          thumbnails[0].index > dropZone.index &&
          dropZone.position === "right"
        ) {
          toIndex++;
        }
        if (
          thumbnails[0].index < dropZone.index &&
          dropZone.position === "left"
        ) {
          toIndex--;
        }

        const newPages = [...sourceDocument.pages];
        const movingPages = newPages.splice(fromIndex, thumbnails.length);
        newPages.splice(toIndex, 0, ...movingPages);
        setDocuments((documents) => {
          const newDocuments = [...documents];
          newDocuments[thumbnails[0].documentIndex].pages = newPages;
          return newDocuments;
        });
      } else {
        const movingPages = sourceDocument.pages.splice(
          thumbnails[0].index,
          thumbnails.length
        );
        if (dropZone.position === "right") {
          toIndex++;
        }
        targetDocument.pages.splice(toIndex, 0, ...movingPages);
        setDocuments((documents) => {
          const newDocuments = [...documents];
          newDocuments[thumbnails[0].documentIndex].pages =
            sourceDocument.pages;
          newDocuments[dropZone.documentIndex].pages = targetDocument.pages;
          if (sourceDocument.pages.length === 0) {
            newDocuments.splice(thumbnails[0].documentIndex, 1);
            if (thumbnails[0].documentIndex < dropZone.documentIndex) {
              dropZone.documentIndex--;
            }
          }
          newDocuments.forEach((document, index) => {
            document.index = index;
            const warning =
              document.confidence < document.threshold ? "! " : "";
            document.explorer.name = `(${index + 1}) ${warning} ${
              document.type
            }`;
          });
          return newDocuments;
        });
      }
      setCurrentIndex(dropZone.documentIndex);
      onResize();
    },
    [documents, onResize, setDocuments]
  );

  const hotkeys = useCallback(
    (e) => {
      if (DomUtils.hasPopup()) return;
      switch (e.key) {
        case "Enter":
        case "Tab":
          if (e.key === "Enter" && currentIndex !== -1) {
            const doc = documents[currentIndex];
            onClassify(doc, doc.type);
          }
          if (lowConfDocs.length === 0) {
            alert.open(<div>No Documents to Review</div>);
            return;
          }
          const getDoc = (direction) => {
            if (direction === "next") {
              for (let doc of lowConfDocs) {
                if (doc.index > currentIndex) {
                  return doc;
                }
              }
              return lowConfDocs[0];
            } else {
              // prev
              for (let i = lowConfDocs.length - 1; i >= 0; i--) {
                if (lowConfDocs[i].index < currentIndex) {
                  return lowConfDocs[i];
                }
              }
              return lowConfDocs[lowConfDocs.length - 1];
            }
          };
          const direction = e.shiftKey ? "prev" : "next";
          const nextDoc = getDoc(direction);
          setCurrentIndex(nextDoc.index);
          __onClick(documents[nextDoc.index]);
          const rowIndex = Math.floor(nextDoc.index / columnCount);
          const columnIndex = nextDoc.index % columnCount;
          gridRef.current.scrollToItem({
            align: "start",
            columnIndex,
            rowIndex,
          });
          return;
        default:
          return;
      }
    },
    [
      currentIndex,
      lowConfDocs,
      alert,
      documents,
      __onClick,
      columnCount,
      onClassify,
    ]
  );

  const Documents = ({ data, columnIndex, rowIndex, style }) => {
    const index = rowIndex * columnCount + columnIndex;
    if (index >= data.documents.length) return null;
    return (
      <div
        className={`pad-10 ${
          currentIndex === index ? "border-orange" : "border-lg"
        }`}
        style={style}
      >
        <Document
          key={index}
          _this={data.documents[index]}
          onSplit={onSplit}
          onPageDrop={onPageDrop}
          onClassify={onClassify}
          onClick={() => {
            setCurrentIndex(index);
            __onClick(documents[index]);
          }}
          disabled={currentIndex !== index}
        />
      </div>
    );
  };

  useEffect(() => {
    onResize();
    if (index === -1 || index === undefined) return;
    setCurrentIndex((currentIndex) => {
      if (currentIndex !== index) {
        const rowIndex = Math.floor(index / columnCount);
        const columnIndex = index % columnCount;
        if (
          rowIndex < itemsRendered.visibleRowStartIndex ||
          rowIndex > itemsRendered.visibleRowStopIndex
        ) {
          gridRef.current.scrollToItem({
            align: "start",
            columnIndex,
            rowIndex,
          });
        }
      }
      return index;
    });
  }, [index, columnCount, onResize, itemsRendered]);

  useEffect(() => {
    window.addEventListener("keydown", hotkeys);
    return () => {
      window.removeEventListener("keydown", hotkeys);
    };
  });

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex-col gap-5 w-full h-full">
        <div
          className="flex gap-30 bg-lg pad-5-10 align-center"
          style={{ minHeight: "50px" }}
        >
          <StandardAttribute label="Total Documents" data={documents.length} />
          <StandardAttribute
            label="Total Pages"
            data={documents.reduce(
              (sum, document) => sum + document.pages.length,
              0
            )}
          />
          <StandardAttribute
            label="Documents to Review"
            data={lowConfDocs.length}
          />
        </div>
        <div className="h-full">
          <AutoSizer onResize={onResize}>
            {({ width, height }) => {
              const scrollbarWidth = 6;
              const colWidth = width / columnCount - scrollbarWidth;
              return (
                <Grid
                  ref={gridRef}
                  className="scrollbar bg-lg"
                  width={width}
                  height={height}
                  columnCount={columnCount}
                  rowCount={Math.ceil(documents.length / columnCount)}
                  columnWidth={() => colWidth}
                  rowHeight={(rowIndex) => {
                    const leftDocIndex = rowIndex * columnCount;
                    const rightDocIndex = leftDocIndex + 1;
                    const leftDocHeight = getItemSize(
                      documents[leftDocIndex],
                      colWidth
                    );
                    const rightDocHeight =
                      rightDocIndex >= documents.length
                        ? leftDocHeight
                        : getItemSize(documents[rightDocIndex], colWidth);
                    return Math.max(leftDocHeight, rightDocHeight);
                  }}
                  itemData={{ documents }}
                  onItemsRendered={setItemsRendered}
                  children={Documents}
                />
              );
            }}
          </AutoSizer>
        </div>
      </div>
    </DndProvider>
  );
};

ClassificationPanel.defaultProps = {
  documents: [],
  setDocuments: () => {},
  onClick: () => {},
  index: -1,
};

export default ClassificationPanel;
