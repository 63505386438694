import { useEffect, useState } from "react";
import StandardTable from "./StandardTable";
import PropTypes from "prop-types";
import SearchBar from "../SearchBars/SearchBar";

const SearchTable = ({
  placeholder,
  tableConfig,
  rowData,
  onRowClick,
  highlightRow,
  rowButton,
  tableContainerOffset,
  initialOrder,
}) => {
  const [searched, setSearched] = useState("");
  const [filteredRowData, setFilteredRowData] = useState([]);

  useEffect(() => {
    const filteredData = rowData.filter((row) => {
      for (const headCell of tableConfig) {
        if (
          row[headCell.id]
            .toString()
            .toLowerCase()
            .includes(searched.trim().toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredRowData(filteredData);
  }, [searched, rowData, setFilteredRowData, tableConfig]);

  return (
    <div>
      <SearchBar
        placeholder={placeholder}
        value={searched}
        onChange={(searchVal) => setSearched(searchVal)}
        sx={{ margin: "10px 0", width: "100%" }}
      />
      <StandardTable
        tableConfig={tableConfig}
        rowData={filteredRowData}
        onRowClick={onRowClick}
        highlightRow={highlightRow}
        rowButton={rowButton}
        containerOffset={tableContainerOffset}
        initialOrder={initialOrder}
      />
    </div>
  );
};

SearchTable.propTypes = {
  placeholder: PropTypes.string,
  tableConfig: PropTypes.array,
  rowData: PropTypes.array,
};

SearchTable.defaultProps = {
  onRowClick: null,
  highlightRow: null,
  initialOrder: "asc",
};

export default SearchTable;
