import { TextField } from "@mui/material";
import textFieldProps from "../../store/textfield.props";
import SubmitInput from "../Inputs/SubmitInput";
import { useContext, useState, useMemo } from "react";
import { AppContext } from "../../Contexts/Contexts";
import TrapezeClient from "../../api/trapeze.client";
import VerifAiApi from "../../api/verifai-api";
import BrowserUtils from "../../utils/browser.utils";
import { useLocation } from "react-router-dom";
import DateTimeUtils from "../../utils/datetime.utils";
import { copy } from "../../utils/general.utils";
import { useQuery } from "@tanstack/react-query";
import WorkflowApi from "../../api/workflow-api";
import Loader from "../Loaders/Loader";
import Center from "../Center";

const BatchMetadata = ({ loan }) => {
  const { loader, showCheckmark, leftPopOut, user } = useContext(AppContext);
  const [tags, setTags] = useState([]);
  const location = useLocation();
  const params = useMemo(
    () => BrowserUtils.getServiceParams(location.search),
    [location.search]
  );
  const { isPending: tagsPending } = useQuery({
    queryKey: ["getBatchTags", loan.name],
    queryFn: async () => {
      const tags = !!params.verifai
        ? loan.tags
        : await TrapezeClient.getBatchTags(loan.name);
      setTags(tags);
      return tags;
    },
  });

  const { data: tagConfig, isPending: tagConfigIsPending } = useQuery({
    queryKey: ["getTags"],
    queryFn: WorkflowApi.getTagConfig,
  });

  const canEdit = (role) => {
    switch (role.toLowerCase()) {
      case "sysadmin":
        return user?.IsSystemAdministrator;
      case "admin":
        return user?.IsAdministrator;
      case "user":
        return true;
      default:
        throw new Error(`Invalid role: ${role}`);
    }
  };

  // onClicks
  const onChange = (tagId, value) => {
    setTags((tags) =>
      tags.map((tag) => {
        const id = tag._id ?? tag.tagID;
        if (id === tagId) {
          !!params.verifai ? (tag.content = value) : (tag.tagValue = value);
          return copy(tag);
        }
        return tag;
      })
    );
  };

  const onSave = async (e) => {
    e.preventDefault();
    loader.open();
    try {
      if (!!params.verifai) {
        for (const tag of tags) {
          await VerifAiApi.modifyTag(loan._id, tag);
        }
      } else {
        for (const tag of tags) {
          const canEditTag = canEdit(tagConfig[tag.tagID].editPermission);
          if (canEditTag) {
            await TrapezeClient.modifyBatchTag(loan.name, tag);
          }
        }
      }
    } finally {
      loader.close();
    }
    leftPopOut.close();
    await showCheckmark();
    console.log("save");
  };

  if (tagsPending || tagConfigIsPending) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    loan && (
      <form className="batch-info standard-content" onSubmit={onSave}>
        <div className="batch-info-data scrollbar">
          <TextField
            variant="filled"
            label="Name"
            value={loan.name}
            size="small"
            InputLabelProps={{ style: { color: "black" }, shrink: true }}
            inputProps={{
              style: textFieldProps,
              readOnly: true,
            }}
          />
          <TextField
            variant="filled"
            label="Date Added"
            value={
              DateTimeUtils.format(DateTimeUtils.toUtc(loan.scannedDate)) ||
              DateTimeUtils.format(DateTimeUtils.toUtc(loan.dateAdded))
            }
            size="small"
            InputLabelProps={{ style: { color: "black" }, shrink: true }}
            inputProps={{
              style: textFieldProps,
              readOnly: true,
            }}
          />
          {tags.map((tag, idx) => {
            const label = tag.label ?? tag.tagName;
            const value = tag.content ?? tag.tagValue ?? "";
            const tagId = tag._id ?? tag.tagID;
            const canEditTag =
              !!params.verifai || canEdit(tagConfig[tag.tagID].editPermission);
            return (
              <TextField
                variant={canEditTag ? "outlined" : "filled"}
                key={idx}
                label={label}
                value={value}
                size="small"
                onChange={(e) => onChange(tagId, e.target.value)}
                InputLabelProps={{ style: { color: "black" }, shrink: true }}
                inputProps={{
                  style: textFieldProps,
                  readOnly: !canEditTag,
                }}
              />
            );
          })}
        </div>
        <div className="batch-info-submit-container">
          <SubmitInput value="Save" />
        </div>
      </form>
    )
  );
};

export default BatchMetadata;
