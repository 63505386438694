import { useNavigate } from "react-router-dom";
import { requestToken } from "../../api/auth-api";
import BrowserUtils from "../../utils/browser.utils";
import { useQuery } from "@tanstack/react-query";

const loadingMessage = "Logging in... This should only take a few seconds.";

const LoginSso = () => {
  const navigate = useNavigate();
  const { error } = useQuery({
    queryKey: ["loginSso"],
    queryFn: async () => {
      const searchParams = BrowserUtils.getSearchParams();
      await requestToken(searchParams.get("code"));
      const path = BrowserUtils.localStorage.get("redirect") || "/";
      BrowserUtils.localStorage.delete("redirect");
      navigate(path);
      return true;
    },
  });

  return <div className="login-sso">{error?.toString() ?? loadingMessage}</div>;
};

export default LoginSso;
