import DashboardIcon from "../components/Icons/DashboardIcon";
import InfoIcon from "../components/Icons/InfoIcon";
import TagIcon from "../components/Icons/TagIcon";
import DashboardConfig from "../pages/Admin/Settings/DashboardConfig";
import MetadataTags from "../pages/Admin/Settings/MetadataTags";
import SystemInfo from "../pages/Admin/Settings/SystemInfo";

const iconSize = 20;

const AdminSettings = {
  systemInfo: {
    id: "systemInfo",
    label: "System Information",
    component: <SystemInfo />,
    explorer: {
      type: "document",
      name: "System Information",
      icon: <InfoIcon size={iconSize} />,
    },
  },
  tags: {
    id: "tags",
    label: "Tags",
    component: <MetadataTags />,
    explorer: {
      type: "document",
      name: "Tags",
      icon: <TagIcon size={iconSize} />,
    },
  },
  dashboard: {
    id: "dashboard",
    label: "Dashboard",
    component: <DashboardConfig />,
    explorer: {
      type: "document",
      name: "Dashboard",
      icon: <DashboardIcon size={iconSize} />,
    },
  },
  // users: {
  //   id: "users",
  //   label: "Users",
  //   component: <div>Users</div>,
  //   explorer: {
  //     type: "document",
  //     name: "Users",
  //     icon: <UserIcon size={iconSize} />,
  //   },
  // },
  // systemStatus: {
  //   id: "systemStatus",
  //   label: "System Status",
  //   component: <div>System Status</div>,
  //   explorer: {
  //     type: "document",
  //     name: "System Status",
  //     icon: <StatusIcon size={iconSize} />,
  //   },
  // },
};

export default AdminSettings;
