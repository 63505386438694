import ObjectUtils from "../../utils/object.utils";
import StandardAttribute from "../Attributes/StandardAttribute";
import StatusAttribute from "../Attributes/StatusAttribute";

export default function BoxRow({ name, config, data }) {
  return (
    <div className="flex gap-20 align-center border pad-5">
      <div className="sans bold min-w-70">{name}</div>
      {config.map((column) => {
        if (column.headerName.includes("Status")) {
          let status = ObjectUtils.getNestedValue(data, column.field);
          if (typeof status !== "boolean") {
            status = status.toLowerCase() === "healthy";
          }
          return (
            <StatusAttribute
              key={column.headerName}
              label={column.headerName}
              status={status}
            />
          );
        }
        return (
          <StandardAttribute
            key={column.headerName}
            label={column.headerName}
            data={ObjectUtils.getNestedValue(data, column.field)}
          />
        );
      })}
    </div>
  );
}
