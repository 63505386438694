import Request from "./Request";

const VerifAiApi = {
  login: async (username, password) => {
    return await Request.post(`/api/auth/login`, {
      username: username,
      password: password,
    });
  },
  logout: async () => {
    return await Request.post(`/api/auth/logout`, {});
  },
  changePassword: async (credentials) => {
    return await Request.put(`api/password`, credentials);
  },
  refresh: async () => {
    return await Request.post(`/api/auth/refresh`, {});
  },
  getLoans: async (loanId = "") => {
    return await Request.get(`/api/loans/${loanId}`);
  },
  getBatches: async (loanId, batchId = "") => {
    return await Request.get(`api/loans/${loanId}/batches/${batchId}`);
  },
  deleteLoan: async (loanId) => {
    return await Request.delete(`api/loans/${loanId}`);
  },
  postLoan: async (loan) => {
    return await (await Request.post(`/api/loans`, loan)).json();
  },
  putLoan: async (loan) => {
    return await Request.put(`/api/loans/${loan._id}`, loan);
  },
  putDocument: async (loanId, doc) => {
    return await Request.put(`/api/loans/${loanId}/documents/${doc._id}`, doc);
  },
  getAuditReports: async (loanId, reportId = "") => {
    return await Request.get(`/api/loans/${loanId}/auditReports/${reportId}`);
  },
  getIncomeReport: async (loanId, reportId = "") => {
    return await Request.get(`/api/loans/${loanId}/incomeReports/${reportId}`);
  },
  migrateBatch: async (loanId, batchName) => {
    return await Request.post(`/api/services/migrate/jobs`, {
      type: "batch",
      loanId: loanId,
      batchId: batchName,
    });
  },
  migrateDoc: async (loanId, batchName, docId) => {
    return await Request.post(`/api/services/migrate/jobs`, {
      type: "doc",
      loanId: loanId,
      batchId: batchName,
      docId: docId,
    });
  },
  postLos: async (loanId, eFileType, fileContent) => {
    return await Request.post(`/api/loans/${loanId}/los`, {
      eFileType: eFileType,
      fileContent: fileContent,
    });
  },
  runAudit: async (loanId) => {
    return await Request.post(`/api/services/audit/jobs`, {
      loanId: loanId,
    });
  },
  runIncome: async (loanId) => {
    return await Request.post(`/api/services/income/jobs`, {
      loanId: loanId,
    });
  },
  addTag: async (loanId, tag) => {
    return await Request.post(`/api/loans/${loanId}/tags`, {
      label: tag.label,
      content: tag.content,
    });
  },
  modifyTag: async (loanId, tag) => {
    return await Request.put(`/api/loans/${loanId}/tags/${tag._id}`, tag);
  },
  getLoanFromBatchName: async (batchName) => {
    const loans = await VerifAiApi.getLoans();
    const loan = loans.find((loan) => loan.name === batchName);
    return loan;
  },
  getIncomeReportPdfUrl(loanId, reportId) {
    return `/api/loans/${loanId}/incomeReports/${reportId}?format=pdf`;
  },
};

export default VerifAiApi;
