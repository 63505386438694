import { MdDashboard } from "react-icons/md";

const DashboardIcon = ({ size, className, title, onClick }) => {
  return (
    <MdDashboard
      className={`no-shrink ${className}`}
      size={size}
      title={title}
      onClick={onClick}
    />
  );
};

DashboardIcon.defaultProps = {
  className: "",
  title: "Dashboard",
  onClick: () => {},
};

export default DashboardIcon;
