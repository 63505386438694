import Request from "../Request";

const PATH = "/api/trapeze/admin/config/extraction";

const ExtractionConfigApi = {
  getExtractionSupportedDocs: async () => {
    return await Request.get(`/api/trapeze/v3/extraction/documentTypes/`);
  },
  modifyExtractionSupportedDocs: async (documentTypeName, data) => {
    return await Request.put(
      `/api/trapeze/admin/config/v3/extraction/documentTypes/${documentTypeName}`,
      data
    );
  },
  getDocumentExtractionFields: async (documentTypeName) => {
    return await Request.get(
      `/api/trapeze/v3/extraction/documentTypes/${documentTypeName}/fields`
    );
  },
  getDocumentExtractionFieldRules: async (documentTypeName) => {
    return await Request.get(
      `${PATH}/documentTypes/${documentTypeName}/fields`
    );
  },
  modifyDocumentExtractionFieldRules: async (
    documentTypeName,
    fieldName,
    data
  ) => {
    return await Request.put(
      `${PATH}/documentTypes/${documentTypeName}/fields/${fieldName}`,
      data
    );
  },
};

export default ExtractionConfigApi;
