import { useCallback, useContext, useEffect, useRef, useState } from "react";
import WorkflowApi from "../../../api/workflow-api";
import AdvancedSearchTable from "../../../components/Tables/AdvancedSearchTable";
import TableConfig from "../config/TableConfig";
import AdminApi from "../../../api/admin-api";
import { AppContext } from "../../../Contexts/Contexts";
import UploadLos from "../../../components/Upload/UploadLos";
import VerifAiApi from "../../../api/verifai-api";
import DocumentIcon from "../../../components/Icons/DocumentIcon";
import ApproveIcon from "../../../components/Icons/ApproveIcon";
import AddIcon from "../../../components/Icons/AddIcon";
import ResetIcon from "../../../components/Icons/ResetIcon";
import DeleteIcon from "../../../components/Icons/DeleteIcon";
import DomUtils from "../../../utils/dom.utils";
import Vars from "../../../store/vars";

const canUploadLos = (batch) => {
  if (!batch) return false;
  if (batch.stage === "DataVerification" && batch.status === "migrated")
    return true;
  return false;
};

const isCompletable = (batch) => {
  if (!batch) {
    return false;
  }
  return (
    (batch.stage === "Validation" || batch.stage === "DataVerification") &&
    (batch.status === "approved" ||
      batch.status === "autoapproved" ||
      batch.status === "migrated")
  );
};

const DocTable = ({ batch }) => {
  const { showCheckmark, leftPopOut, alert, confirm, user, appSettings } =
    useContext(AppContext);
  const [docs, setDocs] = useState([]);
  const fetching = useRef(false);

  // GetSetters
  const getSetDocs = useCallback(async (batchName) => {
    if (fetching.current) return;
    try {
      fetching.current = true;
      const docs = (await WorkflowApi.getDocs(batchName)).filter(
        (doc) => doc.status !== "deleted"
      );
      setDocs(docs);
      return docs;
    } finally {
      fetching.current = false;
    }
  }, []);

  const onDeleteBatch = useCallback(
    async (batchName) => {
      const processingMessage = `This batch is currently in a "processing" state and may still have active processes associated with it.`;
      const deleteMessage = `Are you sure you would like to delete batch: ${batchName}?`;
      const forceDelete = batch.status === "processing";
      const message = forceDelete
        ? `${processingMessage} ${deleteMessage}`
        : deleteMessage;
      leftPopOut.close();
      confirm.open(message, async () => {
        try {
          await WorkflowApi.deleteBatch(batchName, forceDelete);
          if (appSettings.useVerifai) {
            const loan = await VerifAiApi.getLoanFromBatchName(batchName);
            if (loan) {
              await VerifAiApi.deleteLoan(loan._id);
            }
          }
          showCheckmark();
        } catch (error) {
          console.error(error);
          alert.open(<p>{`${error.message}`}</p>);
          return;
        }
      });
    },
    [confirm, alert, leftPopOut, batch, showCheckmark, appSettings]
  );

  const onResetBatch = useCallback(
    async (batchName) => {
      const processingMessage = `This batch is currently in a "processing" state and may still have active processes associated with it.`;
      const resetMessage = `Are you sure you would like to reset batch: ${batchName}?`;
      const message =
        batch.status === "processing"
          ? `${processingMessage} ${resetMessage}`
          : resetMessage;
      leftPopOut.close();
      confirm.open(message, async () => {
        try {
          await WorkflowApi.resetBatch(batchName);
          showCheckmark();
        } catch (error) {
          console.log(error);
          alert.open(<p>{`${error.message}`}</p>);
          return;
        }
      });
    },
    [confirm, alert, leftPopOut, showCheckmark, batch]
  );
  const onCompleteBatch = useCallback(
    async (batchName) => {
      const message = `Are you sure you would like to complete batch: ${batchName}?`;
      leftPopOut.close();
      confirm.open(message, async () => {
        try {
          await AdminApi.completeBatch(batchName);
          showCheckmark();
        } catch (error) {
          console.log(error);
          alert.open(<p>{`${error.toString()}`}</p>);
          return;
        }
      });
    },
    [confirm, alert, leftPopOut, showCheckmark]
  );
  // useEffects
  // driver
  useEffect(() => {
    console.log("DocTable Driver");
    const driver = async () => {
      await getSetDocs(batch.name);
    };
    driver();
    const id = setInterval(
      () => getSetDocs(batch.name),
      appSettings.refreshRateInSeconds * 1000 ?? Vars.refreshInterval
    );
    return () => clearInterval(id);
  }, [getSetDocs, batch, appSettings]);

  return (
    <div className="border-top-orange box-shadow s-full overflow-hidden pad-5 flex-col gap-5">
      <div className="flex gap-5">
        <div className="flex bg-green pad-5 align-center w-full gap-5">
          <DocumentIcon size={30} />
          <h1 className="font-24">Documents</h1>
        </div>
        <div className="flex gap-5">
          <AddIcon
            className={"bg-blue pad-10  button"}
            title={"Upload LOS"}
            size={42}
            hidden={!canUploadLos(batch)}
            onClick={async () =>
              leftPopOut.open(
                DomUtils.getOrangeBlackHeader("Upload", "LOS"),
                <UploadLos
                  loan={await VerifAiApi.getLoanFromBatchName(batch.name)}
                />
              )
            }
          />
          <ApproveIcon
            className={"tertiary-color-scheme-1 pad-5 button"}
            title={"Complete"}
            hidden={!user?.IsAdministrator || !isCompletable(batch)}
            onClick={async () => onCompleteBatch(batch.name)}
            size={42}
          />
          {user.IsAdministrator && batch.stage !== "DataVerification" && (
            <ResetIcon
              className={"bg-orange pad-5 button"}
              size={42}
              title="Reset"
              onClick={async () => onResetBatch(batch.name)}
            />
          )}
          {user.IsAdministrator && (
            <DeleteIcon
              className={"bg-red pad-5 button"}
              title={"Delete"}
              onClick={async () => onDeleteBatch(batch.name)}
              size={42}
            />
          )}
        </div>
      </div>
      <AdvancedSearchTable
        tableConfig={TableConfig.doc}
        data={docs}
        dateSearch
      />
    </div>
  );
};

DocTable.defaultPropTypes = {
  loan: null,
};

export default DocTable;
