const TableMetadata = ({ metadata, filteredData, originalData }) => {
  return (
    <div className="flex space-between pad-5-10">
      {metadata.map((md, idx) => (
        <div key={idx}>{md.function(filteredData, originalData)}</div>
      ))}
    </div>
  );
};

TableMetadata.defaultProps = {
  metadata: [],
};

export default TableMetadata;
