import { useCallback, useEffect, useState } from "react";
import IncomeAnalyzerConfigApi from "../../../api/config-api/ia.config.api";
import CandyButton from "../../../components/Buttons/CandyButton"

const IAConfig = () => {
  const [defaultConfig, setDefaultConfig] = useState(null);
  const [customConfig, setCustomConfig] = useState(null);

  // GetSetters
  const getSetDefaultConfig = useCallback(async () => {
    const defaultConfig = await IncomeAnalyzerConfigApi.getDefaultConfig();
    setDefaultConfig(defaultConfig);
    return defaultConfig;
  }, []);

  const getSetCustomConfig = useCallback(async () => {
    const customConfig = await IncomeAnalyzerConfigApi.getCustomConfig();
    setCustomConfig(customConfig);
    return customConfig;
  }, []);

  // actions
  const saveCustomConfig = useCallback(
    async (customConfig) => {
      await IncomeAnalyzerConfigApi.postCustomConfig(customConfig);
      await getSetCustomConfig();
    },
    [getSetCustomConfig]
  );

  // clicks
  const onSave = async () => {
    await saveCustomConfig(customConfig);
  };

  // useEffects
  // driver
  useEffect(() => {
    const driver = async () => {
      await getSetDefaultConfig();
      await getSetCustomConfig();
    };
    driver();
  }, [getSetDefaultConfig, getSetCustomConfig]);

  return (
    <div>
      <CandyButton onClick={onSave} />
      <div>{JSON.stringify(defaultConfig)}</div>
      <div>{JSON.stringify(customConfig)}</div>
    </div>
  );
};

export default IAConfig;
