import BatchIcon from "../Icons/BatchIcon";
import { useContext } from "react";
import { AppContext, ServiceContext } from "../../Contexts/Contexts";
import BatchMetadata from "../Panels/BatchMetadata";
import GearIcon from "../Icons/GearIcon";
import { AiOutlineSearch } from "react-icons/ai";
import LockIcon from "../Icons/LockIcon";
import StandardAttribute from "../Attributes/StandardAttribute";
import DomUtils from "../../utils/dom.utils";
import TagIcon from "../Icons/TagIcon";

const ServiceBanner = ({ className }) => {
  const { state } = useContext(ServiceContext);
  const { leftPopOut } = useContext(AppContext);
  return (
    <div className={`w-full pad-5 bg-green flex gap-30 h-50 ${className}`}>
      <div className="flex gap-5 align-center">
        <BatchIcon size={32} />
        <StandardAttribute label="Batch Name" data={state.loan?.name}>
          <TagIcon
            className={"button br-10 pad-2 box-shadow-c bg-white"}
            size={17}
            onClick={() =>
              leftPopOut.open(
                DomUtils.getOrangeBlackHeader("Batch", "Information"),
                <BatchMetadata loan={state.loan} />
              )
            }
          />
          {state.service?.id === "classification" &&
            state.loan?.status === "processing" && (
              <LockIcon size={15} title="Reserved" />
            )}
        </StandardAttribute>
      </div>

      <div className="flex gap-5 align-center">
        <GearIcon size={32} />
        <StandardAttribute
          label="Service"
          data={state.service?.label || "No Service Selected"}
        />
      </div>
      <div className="flex gap-5 align-center">
        <AiOutlineSearch size={32} />
        <StandardAttribute
          label="Reviewing"
          data={state.item?.explorer.name || "No Item Selected"}
        />
      </div>
    </div>
  );
};

ServiceBanner.defaultProps = {
  className: "",
};
export default ServiceBanner;
