import { TextField } from "@mui/material";
import { memo } from "react";
import DomUtils from "../../../utils/dom.utils";

const StandardField = memo(({ field, onChange, onFocus, width }) => {
  // console.log("standard field");

  return (
    <div style={{ position: "relative" }}>
      <TextField
        variant="filled"
        label={field.config.label}
        value={field.data ?? field.value ?? ""}
        onChange={(e) => onChange(e.target.value, field)}
        sx={{
          margin: "5px",
          width: width,
          "& .MuiInputBase-input": {
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }}
        size="small"
        InputLabelProps={{
          shrink: true,
          style: { fontSize: "16px", color: "#4A4A4A" },
        }}
        InputProps={{
          style: {
            fontSize: "14px",
            color: "black",
            backgroundColor: "white",
          },
        }}
        inputProps={{
          // title: field.config.label,
          onFocus: () => onFocus(field),
        }}
        inputRef={(input) => (field.ref = input)}
      />
      {DomUtils.getValidatedIcon(field)}
    </div>
  );
});

StandardField.defaultProps = {
  field: {},
};

export default StandardField;
