import Request from "../Request";

const PATH = "/api/trapeze/admin/config/classification";

const ClassificationConfigApi = {
  getClassificationRules: async (documentTypeName = "") => {
    return await Request.get(`${PATH}/documentTypes/${documentTypeName}`);
  },
  modifyClassificationRule: async (documentTypeName, data) => {
    return await Request.put(`${PATH}/documentTypes/${documentTypeName}`, data);
  },
  addClassificationRule: async (data) => {
    return await Request.post(`${PATH}/documentTypes`, data);
  },
  deleteClassificationRule: async (documentTypeName) => {
    return await Request.delete(`${PATH}/documentTypes/${documentTypeName}`);
  },
};

export default ClassificationConfigApi;
